// React-related imports
import React, { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

// Material UI components
import Alert from "@mui/material/Alert";
import { Button, Grid, Paper, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";

// Material icons
import ArrowBack from "@mui/icons-material/ArrowBack";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HistoryIcon from "@mui/icons-material/History";
import InventoryIcon from "@mui/icons-material/Inventory";

// Hooks and services
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import UserService from "../../services/UserService";

// Types
import { UserProfileWithStatsResponse } from "../../types/User";

const UserProfilePublicPage: React.FC = () => {
  const { userGuid } = useParams<{ userGuid: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { data, isLoading, isError, error } = useQuery<
    UserProfileWithStatsResponse,
    Error
  >(
    ["UserProfile", userGuid],
    () => UserService.getUserProfileByUserGuid(String(userGuid)),
    {
      enabled: !!userGuid,
    }
  );

  useEffect(() => {
    if (!userGuid) {
      navigate("/"); // Redirect to home page if userGuid is not provided
    }
  }, [userGuid, navigate]);

  if (isLoading) return <CircularProgress />;

  if (isError || !data) {
    return (
      <Alert severity="error">{error?.message || "An error occurred"}</Alert>
    );
  }

  const handleBackClick = () => {
    if (location.key === "default") {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button
          startIcon={<ArrowBack />}
          onClick={handleBackClick}
          variant="outlined"
          color="primary"
        >
          {t("SharedTranslations.Back")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Avatar sx={{ width: 60, height: 60, mb: 1 }}>
                {data.userProfile.name.charAt(0)}
              </Avatar>
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                {data.userProfile.username}
              </Typography>
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                {data.userProfile.name}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item xs={12} lg={6} display="flex">
            <Paper
              elevation={0}
              sx={{ padding: 3, textAlign: "center", flex: 1 }}
            >
              <EventAvailableIcon
                sx={{ fontSize: 40, color: "primary.main" }}
              />
              <Typography
                variant="h2"
                sx={{ fontWeight: 600, color: "secondary.main" }}
              >
                {data.userProfileStats.totalAmountOfDaysRegisteredOnPlatform}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {t("UserProfilePublicPage.DaysOnPlatformTitle")}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {t("UserProfilePublicPage.DaysOnPlatformDesc")}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6} display="flex">
            <Paper
              elevation={0}
              sx={{ padding: 3, textAlign: "center", flex: 1 }}
            >
              <InventoryIcon sx={{ fontSize: 40, color: "primary.main" }} />
              <Typography
                variant="h2"
                sx={{ fontWeight: 600, color: "secondary.main" }}
              >
                {data.userProfileStats.totalCountValidProductUserAssociations}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {t("UserProfilePublicPage.ProductsOwnedTitle")}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {t("UserProfilePublicPage.ProductsOwnedDesc")}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6} display="flex">
            <Paper
              elevation={0}
              sx={{ padding: 3, textAlign: "center", flex: 1 }}
            >
              <HistoryIcon sx={{ fontSize: 40, color: "primary.main" }} />
              <Typography
                variant="h2"
                sx={{ fontWeight: 600, color: "secondary.main" }}
              >
                {data.userProfileStats.totalCountProductUserAssociations}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {t("UserProfilePublicPage.HistoricalProductOwnershipsTitle")}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {t("UserProfilePublicPage.HistoricalProductOwnershipsDesc")}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6} display="flex">
            <Paper
              elevation={0}
              sx={{ padding: 3, textAlign: "center", flex: 1 }}
            >
              <AvTimerIcon sx={{ fontSize: 40, color: "primary.main" }} />
              <Typography
                variant="h2"
                sx={{ fontWeight: 600, color: "secondary.main" }}
              >
                {
                  data.userProfileStats
                    .averageProductUserAssociationDurationInDays
                }
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                {t("UserProfilePublicPage.AverageOwnershipDurationTitle")}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {t("UserProfilePublicPage.AverageOwnershipDurationDesc")}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserProfilePublicPage;
