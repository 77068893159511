import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import OrganizationUserAssociationService from "../services/OrganizationUserAssociationService";
import {
  CreateOrganizationUserAssociationRequest,
  OrganizationUserAssociationResponse,
  OrganizationUserAssociationRoleEnum,
} from "../types/OrganizationUserAssociation";
import UserSearch from "../../../components/User/UserSearch";

// Schema validation
const schema = yup.object().shape({
  organizationGuid: yup.string().required("Organization is required"),
  userGuid: yup.string().required("User is required"),
  role: yup.number().required("Role is required"),
});

interface OrganizationUserAssociationCreationModalProps {
  organizationGuid: string;
  open: boolean;
  onClose: (id?: number) => void;
}

const OrganizationUserAssociationCreationModal: React.FC<
  OrganizationUserAssociationCreationModalProps
> = ({ organizationGuid, open, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  // Form setup
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateOrganizationUserAssociationRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      organizationGuid: organizationGuid,
      userGuid: "",
      role: OrganizationUserAssociationRoleEnum.Member,
    },
  });

  // Mutation setup
  const mutation = useMutation<
    OrganizationUserAssociationResponse,
    Error,
    CreateOrganizationUserAssociationRequest
  >(
    (newAssociation) =>
      OrganizationUserAssociationService.create(newAssociation),
    {
      onSuccess: (data) => {
        toast.success("User added successfully.", {
          position: "bottom-right",
        });
        setIsLoading(false);
        queryClient.invalidateQueries([
          "OrganizationUserAssociations",
          data.organization.organizationGuid,
        ]);
        onClose(data.organizationUserAssociationId);
      },
      onError: (error: any) => {
        toast.error(error.message || "An error occurred.", {
          position: "bottom-right",
        });
        setIsLoading(false);
      },
    }
  );

  // Form submit handler
  const onSubmit = (data: CreateOrganizationUserAssociationRequest) => {
    setIsLoading(true);
    mutation.mutate(data);
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("OrganizationUserAssociationCreationModal.Title")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {t("OrganizationUserAssociationCreationModal.Description")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="userGuid"
                    control={control}
                    render={({ field }) => (
                      <UserSearch
                        selectedUserGuid={field.value}
                        onSelect={(value) => field.onChange(value)}
                        error={!!errors.userGuid}
                        helperText={errors.userGuid?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label={t("SharedTranslations.Role") as string}
                        fullWidth
                        error={!!errors.role}
                        helperText={errors.role?.message}
                      >
                        {Object.entries(OrganizationUserAssociationRoleEnum)
                          .filter(([key, value]) => typeof value === "number")
                          .map(([key, value]) => (
                            <MenuItem key={value} value={value}>
                              {key}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                  >
                    {isLoading
                      ? t("SharedTranslations.Loading")
                      : t(
                          "OrganizationUserAssociationCreationModal.ButtonAddText"
                        )}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    disabled={isLoading}
                    onClick={() => onClose()}
                  >
                    {t("SharedTranslations.Cancel")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default OrganizationUserAssociationCreationModal;
