import React from "react";
import { useUser } from "../contexts/UserContext";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

interface ProtectedRoutesProps {
  layout: React.ComponentType<any>;
}

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({
  layout: Layout,
}) => {
  const { user, isLoading } = useUser();
  const theme = useTheme();
  const location = useLocation();

  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          We are logging you in...
        </Typography>
      </Box>
    );
  }

  if (!user) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" state={{ returnUrl: location }} />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default ProtectedRoutes;
