import React, { useState, ReactNode } from "react";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import TheftyAppBar from "../components/Navigation/TheftyAppBar";
import TheftyNavDrawer from "../components/Navigation/TheftyNavDrawer";
interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <TheftyAppBar handleDrawerToggle={handleDrawerToggle} />
      <TheftyNavDrawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
