import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PathNotFound: React.FC = () => {
  const navigation = useNavigate();

  return (
    <Container maxWidth="sm" style={{ marginTop: '5em', textAlign: 'center' }}>
      <Typography variant="h3" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" gutterBottom>
        Oops! Page not found.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We couldn't find the page you're looking for. Maybe you took a wrong turn?
      </Typography>
      <Box mt={3}>
        <Button variant="contained" color="primary" onClick={() => navigation('/')}>
          Go Home
        </Button>
      </Box>
    </Container>
  );
}

export default PathNotFound;
