import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import { useLoading } from '../../contexts/LoadingContext';

const LoadingComponent: React.FC = () => {
    const { isLoading } = useLoading();

    if (!isLoading) {
        return null;
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1500, // ensure it's on top of everything
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default LoadingComponent;
