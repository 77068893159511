// React imports
import React, { useState } from "react";

// Router imports
import { useNavigate } from "react-router-dom";

// Form handling imports
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// React Query imports
import { useMutation, useQueryClient } from "react-query";

// Material UI imports
import { Button, Grid, TextField, Typography } from "@mui/material";

// Material UI icon imports
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Internationalization imports
import { useTranslation } from "react-i18next";

// Toast notifications
import { toast } from "react-toastify";

import {
  CreateOrganizationRequest,
  OrganizationResponse,
} from "../types/Organization";
import OrganizationService from "../services/OrganizationService";
import CountryAutocomplete from "../../../components/Country/CountryAutocomplete";

const schema = yup.object({
  name: yup
    .string()
    .required("Name is required.")
    .min(2, "Name must be at least 2 characters long.")
    .max(255, "Name must not exceed 255 characters."),
  description: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .max(2000, "Description must not exceed 2000 characters."),
  websiteUrl: yup.string().nullable().url("Website URL must be a valid URL."),
  registrationNumber: yup
    .string()
    .required("Registration Number is required.")
    .min(2, "Registration Number must be at least 2 characters long.")
    .max(255, "Registration Number must not exceed 255 characters."),
  valueAddedTaxNumber: yup.string().nullable(),
  address: yup
    .string()
    .required("Address is required.")
    .min(2, "Address must be at least 2 characters long.")
    .max(255, "Address must not exceed 255 characters."),
  postalCode: yup
    .string()
    .required("Postal Code is required.")
    .min(2, "Postal Code must be at least 2 characters long.")
    .max(255, "Postal Code must not exceed 255 characters."),
  city: yup
    .string()
    .required("City is required.")
    .min(2, "City must be at least 2 characters long.")
    .max(255, "City must not exceed 255 characters."),
  contactEmail: yup
    .string()
    .required("Contact Email is required.")
    .email("Contact Email must be a valid email address."),
  contactPhoneNumber: yup
    .string()
    .required("Contact Phone Number is required.")
    .matches(
      /^(\+?\d{1,4}[\s-]?)?\(?\d{1,4}[\s-]?\)?[\d\s-]{1,13}$/,
      "Contact Phone Number must be a valid phone number."
    ),
  countryId: yup.number().required("Country is required."),
});

const OrganizationCreationForm: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateOrganizationRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      websiteUrl: "",
      registrationNumber: "",
      valueAddedTaxNumber: "",
      address: "",
      postalCode: "",
      city: "",
      contactEmail: "",
      contactPhoneNumber: "",
      countryId: undefined,
    },
  });

  const mutation = useMutation<
    OrganizationResponse,
    Error,
    CreateOrganizationRequest
  >((data) => OrganizationService.createOrganization(data), {
    onSuccess: (response) => {
      toast.success("Organization created successfully.");
      setIsLoading(false);
      queryClient.invalidateQueries("organizations");
      navigate(`/organizations/${response.organizationGuid}`);
    },
    onError: (error) => {
      toast.error(error.message || "An error occurred.");
      setIsLoading(false);
    },
  });

  const onSubmit = (data: CreateOrganizationRequest) => {
    setIsLoading(true);
    mutation.mutate(data);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("OrganizationCreationForm.Title")}
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {t("OrganizationCreationForm.Description")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("OrganizationCreationForm.TextFieldNameLabel")}
                      placeholder={t(
                        "OrganizationCreationForm.TextFieldNamePlaceholder"
                      )}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "OrganizationCreationForm.TextFieldDescriptionLabel"
                      )}
                      placeholder={t(
                        "OrganizationCreationForm.TextFieldDescriptionPlaceholder"
                      )}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                      fullWidth
                      multiline
                      rows={4}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="websiteUrl"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "OrganizationCreationForm.TextFieldWebsiteUrlLabel"
                      )}
                      placeholder={t(
                        "OrganizationCreationForm.TextFieldWebsiteUrlPlaceholder"
                      )}
                      error={!!errors.websiteUrl}
                      helperText={errors.websiteUrl?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="registrationNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "OrganizationCreationForm.TextFieldRegistrationNumberLabel"
                      )}
                      placeholder={t(
                        "OrganizationCreationForm.TextFieldRegistrationNumberPlaceholder"
                      )}
                      error={!!errors.registrationNumber}
                      helperText={errors.registrationNumber?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="valueAddedTaxNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "OrganizationCreationForm.TextFieldValueAddedTaxNumberLabel"
                      )}
                      placeholder={t(
                        "OrganizationCreationForm.TextFieldValueAddedTaxNumberPlaceholder"
                      )}
                      error={!!errors.valueAddedTaxNumber}
                      helperText={errors.valueAddedTaxNumber?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "OrganizationCreationForm.TextFieldAddressLabel"
                      )}
                      placeholder={t(
                        "OrganizationCreationForm.TextFieldAddressPlaceholder"
                      )}
                      error={!!errors.address}
                      helperText={errors.address?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="postalCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "OrganizationCreationForm.TextFieldPostalCodeLabel"
                      )}
                      placeholder={t(
                        "OrganizationCreationForm.TextFieldPostalCodePlaceholder"
                      )}
                      error={!!errors.postalCode}
                      helperText={errors.postalCode?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("OrganizationCreationForm.TextFieldCityLabel")}
                      placeholder={t(
                        "OrganizationCreationForm.TextFieldCityPlaceholder"
                      )}
                      error={!!errors.city}
                      helperText={errors.city?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="countryId"
                  control={control}
                  render={({ field }) => (
                    <CountryAutocomplete
                      selectedCountryId={field.value}
                      onCountrySelect={(value) => field.onChange(value)}
                      error={Boolean(errors.countryId)}
                      helperText={errors.countryId?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="contactEmail"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "OrganizationCreationForm.TextFieldContactEmailLabel"
                      )}
                      placeholder={t(
                        "OrganizationCreationForm.TextFieldContactEmailPlaceholder"
                      )}
                      error={!!errors.contactEmail}
                      helperText={errors.contactEmail?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="contactPhoneNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "OrganizationCreationForm.TextFieldContactPhoneNumberLabel"
                      )}
                      placeholder={t(
                        "OrganizationCreationForm.TextFieldContactPhoneNumberPlaceholder"
                      )}
                      error={!!errors.contactPhoneNumber}
                      helperText={errors.contactPhoneNumber?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  disabled={isLoading}
                  fullWidth
                >
                  {t("OrganizationCreationForm.ButtonCreateText")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate("/organizations")}
                  fullWidth
                >
                  {t("OrganizationCreationForm.ButtonCancelText")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default OrganizationCreationForm;
