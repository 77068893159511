// React-related imports
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Third-party library imports
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import { Button, Grid, TextField, Typography, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

// Type imports
import {
  CreateProductModelRequest,
  ProductModelResponse,
} from "../../../types/Product/ProductModel";

// Utility, services, and other internal imports
import ProductModelService from "../../../services/Product/ProductModelService";
import ProductCategoryAutocomplete from "../ProductCategory/ProductCategoryAutocomplete";
import { toast } from "react-toastify";

// Constants
const schema = yup.object({
  name: yup
    .string()
    .required("Name is required.")
    .min(2, "Name must be at least 2 characters long.")
    .max(255, "Name must not exceed 255 characters."),
  description: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .max(2000, "Description must not exceed 2000 characters.")
    .notRequired(),
  productBrandId: yup
    .number()
    .positive()
    .required("Product Brand is required."),
  productCategoryId: yup.number().nullable().notRequired(),
});

const CreateProductModelForm: React.FC = () => {
  const { productBrandId } = useParams<{ productBrandId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!productBrandId) {
      toast.error("Product Brand ID is required to create a product model.", {
        position: "bottom-right",
      });
    }
  }, [productBrandId, navigate]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateProductModelRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      productBrandId: Number(productBrandId),
      productCategoryId: null,
    },
  });

  const mutation = useMutation<
    ProductModelResponse,
    AxiosError,
    CreateProductModelRequest
  >(ProductModelService.create, {
    onSuccess: (response) => {
      setIsLoading(false);

      toast.success(t("CreateProductModelForm.SuccessMessage"), {
        position: "bottom-right",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });

      queryClient.invalidateQueries(["ProductModels", productBrandId]);
      queryClient.invalidateQueries(["ProductBrand", productBrandId]);

      const returnUrl = location.state?.returnUrl;
      if (returnUrl) {
        navigate(returnUrl, {
          state: {
            productBrandId: productBrandId,
            productModelId: response.productModelId,
          },
        });
      } else {
        navigate(`/productbrands/${productBrandId}`);
      }
    },
    onError: (error: AxiosError) => {
      setIsLoading(false);
      const errorMessage =
        error.message || "An error occurred while creating the product Model";

      toast.error(errorMessage, {
        position: "bottom-right",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },
  });

  const onSubmit = (data: CreateProductModelRequest) => {
    setIsLoading(true);
    mutation.mutate(data);
  };

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("CreateProductModelForm.Title")}
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {t("CreateProductModelForm.Description")}
          </Typography>
        </Grid>

        {/* Form Fields */}
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {/* Name Field */}
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("CreateProductModelForm.TextFieldNameLabel")}
                      placeholder={t(
                        "CreateProductModelForm.TextFieldNamePlaceholder"
                      )}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* Description Field */}
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "CreateProductModelForm.TextFieldDescriptionLabel"
                      )}
                      placeholder={t(
                        "CreateProductModelForm.TextFieldDescriptionPlaceholder"
                      )}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                      fullWidth
                      multiline
                      rows={4}
                    />
                  )}
                />
              </Grid>
              {/* Product Category Autocomplete */}
              <Grid item xs={12}>
                <Controller
                  name="productCategoryId"
                  control={control}
                  render={({ field }) => (
                    <ProductCategoryAutocomplete
                      selectedProductCategoryId={field.value || null}
                      onProductCategorySelect={(value) => field.onChange(value)}
                      error={Boolean(errors.productCategoryId)}
                      helperText={errors.productCategoryId?.message}
                    />
                  )}
                />
              </Grid>
              {/* Action Buttons */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<AddIcon />}
                  disabled={isLoading}
                >
                  {t("CreateProductModelForm.ButtonCreateText")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  disabled={isLoading}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    const returnUrl = location.state?.returnUrl;
                    if (returnUrl) {
                      navigate(returnUrl);
                    } else {
                      navigate(`/productbrands/${productBrandId}`);
                    }
                  }}
                >
                  {t("CreateProductModelForm.ButtonCancelText")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CreateProductModelForm;
