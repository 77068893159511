// React-related imports
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Third-party library imports
import { useQuery } from "react-query";
import {
  CircularProgress,
  Typography,
  Paper,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  TextField,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useTranslation } from "react-i18next";

// Utility, services, and other internal imports
import ProductModelService from "../../../services/Product/ProductModelService";
import { useLoading } from "../../../contexts/LoadingContext";
import { toast } from "react-toastify";

interface Props {
  productBrandId: number;
}

const ProductModelsList: React.FC<Props> = ({ productBrandId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoading, hideLoading } = useLoading();

  const [filter, setFilter] = useState("");

  const { isLoading, error, data } = useQuery(
    ["productModels", productBrandId],
    async () => {
      showLoading();
      try {
        const data = await ProductModelService.getAllByProductBrandId(
          productBrandId
        );
        return data;
      } catch (error) {
        throw error;
      } finally {
        hideLoading();
      }
    },
    {
      onError: (error: any) => {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An unexpected error occurred";
        toast.error(errorMessage, {
          position: "bottom-right",
        });
        navigate("/productbrands");
      },
    }
  );

  if (isLoading) return <CircularProgress />;

  if (error) return <Typography color="error">An error occurred.</Typography>;

  const filteredData = data?.filter((item) =>
    item.name.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("ProductModelsList.Title")}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() =>
              navigate(`/productbrands/${productBrandId}/productmodels/create`)
            }
          >
            {t("ProductModelsList.ButtonCreateText")}
          </Button>
        </Grid>
      </Grid>

      <Typography variant="body1" sx={{ mt: 2 }}>
        {t("ProductModelsList.Description")}
      </Typography>

      <TextField
        variant="outlined"
        name="filterProductModels"
        label={t("ProductModelsList.TextFieldFilterLabel")}
        placeholder={t("ProductModelsList.TextFieldFilterPlaceholder")}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        sx={{ mt: 2 }}
        autoComplete="off"
        fullWidth
      />

      {(!filteredData || filteredData.length === 0) && (
        <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
          {t("ProductModelsList.NoProductModelsFound")}
        </Typography>
      )}

      {filteredData && filteredData.length > 0 && (
        <List sx={{ mt: 2 }}>
          {filteredData.map((model, index) => (
            <React.Fragment key={model.productModelId}>
              <ListItemButton
                component={Link}
                to={`/productmodels/${model.productModelId}/edit`}
              >
                <ListItemIcon>
                  <InventoryIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={model.name}
                  primaryTypographyProps={{ fontWeight: "bold" }}
                  secondary={model.description || ""}
                  secondaryTypographyProps={{ color: "text.secondary" }}
                />
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
              </ListItemButton>
              {index < filteredData.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      )}
    </Paper>
  );
};

export default ProductModelsList;
