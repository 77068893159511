import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { CircularProgress, Box, Typography, useTheme } from "@mui/material";
import ProductReferenceTagService from "../services/ProductReferenceTagService";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

const ProductReferenceTagChecker: React.FC = () => {
  const { productReferenceTagGuid } = useParams<{
    productReferenceTagGuid: string;
  }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const { isError, data, error, isLoading } = useQuery(
    ["ProductReferenceTag", productReferenceTagGuid],
    () => {
      if (!productReferenceTagGuid) {
        return null;
      }
      return ProductReferenceTagService.getByGuid(productReferenceTagGuid);
    },
    {
      enabled: !!productReferenceTagGuid,
      retry: false,
    }
  );

  useEffect(() => {
    if (isError && error) {
      const err = error as AxiosError; // Cast the error to AxiosError type

      // Check if the error has a response and if the status code is 404
      if (err.response && err.response.status === 404) {
        navigate(`/productreferencetag/${productReferenceTagGuid}/create`);
      } else {
        // Handle other types of errors
        toast.error(t("ProductReferenceTagChecker.FetchingDataErrorMessage"), {
          position: "bottom-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    } else if (data) {
      navigate(`/Products/${data.productGuid}/Public`);
    }
  }, [isError, data, error, navigate, productReferenceTagGuid, t]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{ backgroundColor: theme.palette.background.default }}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          {t("ProductReferenceTagChecker.ReferenceTagCheckingMessage")}
        </Typography>
      </Box>
    );
  }

  return null;
};

export default ProductReferenceTagChecker;
