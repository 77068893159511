// External library imports
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import { toast } from "react-toastify";

// Absolute imports from the project
import ProductIdentifierTypeService from "../../../services/Product/ProductIdentifierTypeService";
import {
  ProductIdentifierTypeResponse,
  UpdateProductIdentifierTypeRequest,
} from "../../../types/Product/ProductIdentifierType";

const schema = yup.object({
  name: yup
    .string()
    .required("Name is required.")
    .min(2, "Name must be at least 2 characters long.")
    .max(255, "Name must not exceed 255 characters."),
  description: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .max(2000, "Description must not exceed 2000 characters."),
});

const ProductIdentifierTypeEditForm: React.FC = () => {
  const { productIdentifierTypeId } = useParams<{
    productIdentifierTypeId: string;
  }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: identifierType, isLoading } = useQuery(
    ["productIdentifierType", productIdentifierTypeId],
    () => ProductIdentifierTypeService.getById(Number(productIdentifierTypeId)),
    { enabled: !!productIdentifierTypeId }
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateProductIdentifierTypeRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
    },
  });

  React.useEffect(() => {
    if (identifierType) {
      reset({
        name: identifierType.name,
        description: identifierType.description || "",
      });
    }
  }, [identifierType, reset]);

  const mutation = useMutation<
    ProductIdentifierTypeResponse,
    Error,
    UpdateProductIdentifierTypeRequest
  >(
    (data) =>
      ProductIdentifierTypeService.update(
        Number(productIdentifierTypeId),
        data
      ),
    {
      onSuccess: () => {
        toast.success(t("ProductIdentifierTypeEditForm.SuccessMessage"), {
          position: "bottom-right",
        });
        queryClient.invalidateQueries([
          "productIdentifierType",
          productIdentifierTypeId,
        ]);
        queryClient.invalidateQueries(["productIdentifierTypes"]);
        navigate("/productidentifiertypes");
      },
      onError: (error) => {
        toast.error(
          t("ProductIdentifierTypeEditForm.ErrorMessage") + error.message,
          {
            position: "bottom-right",
          }
        );
      },
    }
  );

  const onSubmit = (data: UpdateProductIdentifierTypeRequest) => {
    mutation.mutate(data);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("ProductIdentifierTypeEditForm.Title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {t("ProductIdentifierTypeEditForm.Description")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "ProductIdentifierTypeEditForm.TextFieldNameLabel"
                      )}
                      placeholder={t(
                        "ProductIdentifierTypeEditForm.TextFieldNamePlaceholder"
                      )}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "ProductIdentifierTypeEditForm.TextFieldDescriptionLabel"
                      )}
                      placeholder={t(
                        "ProductIdentifierTypeEditForm.TextFieldDescriptionPlaceholder"
                      )}
                      multiline
                      rows={4}
                      fullWidth
                      error={!!errors.description}
                      helperText={errors.description?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset" disabled>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={identifierType?.isGloballyUnique || false}
                      />
                    } // Directly use the value from data, not controlled by form
                    label={t(
                      "ProductIdentifierTypeEditForm.SwitchIsGloballyUniqueLabel"
                    )}
                  />
                  <FormHelperText>
                    {t(
                      "ProductIdentifierTypeEditForm.SwitchIsGloballyUniqueHelperText"
                    )}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<SaveIcon />}
                >
                  {t("ProductIdentifierTypeEditForm.ButtonSaveText")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  fullWidth
                  onClick={() => navigate(-1)}
                >
                  {t("ProductIdentifierTypeEditForm.ButtonCancelText")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductIdentifierTypeEditForm;
