import { createContext, useContext } from "react";
import { useMutation } from "react-query";
import { useUser } from "./UserContext";
import { LoginRequest, RegisterRequest } from "../types/AuthTypes";
import AuthService from "../services/AuthService";

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthContextProps {
  login: (credentials: LoginRequest) => Promise<void>;
  logout: () => void;
  register: (userDetails: RegisterRequest) => Promise<void>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { refetch: refetchUser, clearUserData } = useUser();

  const mutationLogin = useMutation(AuthService.login, {
    onSuccess: async (response) => {
      localStorage.setItem("token", response.jwtToken);
      await refetchUser();
    },
  });

  const mutationRegister = useMutation(AuthService.register, {
    onSuccess: async () => {
      await refetchUser();
    },
  });

  const login = async (credentials: LoginRequest) => {
    try {
      await mutationLogin.mutateAsync(credentials);
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    clearUserData();
  };

  const register = async (userDetails: RegisterRequest) => {
    try {
      await mutationRegister.mutateAsync(userDetails);
    } catch (error) {
      console.error("Registration error:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};
