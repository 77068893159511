import { ReactNode } from "react";
import { UserProvider } from "./UserContext";
import { LoadingProvider } from "./LoadingContext";
import { AuthProvider } from "../contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// React Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type Props = {
  children: ReactNode;
};

const ProvidersWrapper: React.FC<Props> = ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#264653",
      },
      secondary: {
        main: "#2A9D8F",
      },
      error: {
        main: "#E76F51",
      },
      warning: {
        main: "#E9C46A",
      },
      info: {
        main: "#2874A6",
      },
      success: {
        main: "#2ECC71",
      },
      background: {
        default: "#F0F2F5",
        paper: "#FFFFFF",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <AuthProvider>
            <LoadingProvider>
              <ToastContainer />
              {children}
            </LoadingProvider>
          </AuthProvider>
        </UserProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default ProvidersWrapper;
