import axiosInstance from "../AxiosConfig";
import {
  CreateProductIdentifierTypeRequest,
  ProductIdentifierTypeResponse,
  UpdateProductIdentifierTypeRequest,
} from "../../types/Product/ProductIdentifierType";

const ProductIdentifierTypeService = {
  async create(
    request: CreateProductIdentifierTypeRequest
  ): Promise<ProductIdentifierTypeResponse> {
    const response = await axiosInstance.post<ProductIdentifierTypeResponse>(
      "api/productidentifiertypes/",
      request
    );
    return response.data;
  },

  async getAll(): Promise<ProductIdentifierTypeResponse[]> {
    const response = await axiosInstance.get<ProductIdentifierTypeResponse[]>(
      "api/productidentifiertypes/"
    );
    return response.data;
  },

  async getById(
    productIdentifierTypeId: number
  ): Promise<ProductIdentifierTypeResponse> {
    const response = await axiosInstance.get<ProductIdentifierTypeResponse>(
      `api/productidentifiertypes/${productIdentifierTypeId}`
    );
    return response.data;
  },

  async update(
    productIdentifierTypeId: number,
    request: UpdateProductIdentifierTypeRequest
  ): Promise<ProductIdentifierTypeResponse> {
    const response = await axiosInstance.patch<ProductIdentifierTypeResponse>(
      `api/productidentifiertypes/${productIdentifierTypeId}`,
      request
    );
    return response.data;
  },
};

export default ProductIdentifierTypeService;
