// React-related imports
import React from "react";

// Router-related imports
import { Link, useLocation, useNavigate } from "react-router-dom";

// MUI Core imports
import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// MUI Icons imports
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Dashboard from "@mui/icons-material/Dashboard";
import ListIcon from "@mui/icons-material/List";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";

// Translation and Context hooks
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import { useUser } from "../../contexts/UserContext";
import LanguageSelector from "../Shared/LanguageSelector";

interface NavDrawerProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

interface MenuItem {
  text: string;
  icon: React.ReactElement;
  path: string;
  children?: MenuItem[];
}

const drawerWidth = 240;

const TheftyNavDrawer: React.FC<NavDrawerProps> = ({
  mobileOpen,
  handleDrawerToggle,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { user } = useUser();
  const { logout } = useAuth();

  const menuItems: MenuItem[] = [
    {
      text: t("SharedTranslations.Dashboard"),
      icon: <Dashboard />,
      path: "/",
    },
    {
      text: t("SharedTranslations.Products"),
      icon: <ListIcon />,
      path: "/products",
    },
    {
      text: t("SharedTranslations.Finder"),
      icon: <SearchIcon />,
      path: "/products/search",
    },
    {
      text: t("SharedTranslations.Organization"),
      icon: <CorporateFareIcon />,
      path: "/organizations",
    },
    {
      text: t("SharedTranslations.Account"),
      icon: <AccountCircle />,
      path: "/account",
    },
    {
      text: t("SharedTranslations.Support"),
      icon: <HelpCenterIcon />,
      path: "/support",
    },
  ];

  let adminMenuItem: MenuItem[] = [];

  if (user?.isTheftyAdmin) {
    adminMenuItem = [
      {
        text: "Administration",
        icon: <SettingsIcon />,
        path: "/administration",
      },
    ];
  }

  const isPathActive = (path: string) => pathname === path;

  const EnvironmentChip = () => {
    const env = process.env.REACT_APP_ENVIRONMENT;

    switch (env) {
      case "local":
        return (
          <Chip label="Local Development" color="success" variant="filled" />
        );
      case "development":
        return <Chip label="Development" color="warning" variant="filled" />;
      case "production":
        return null;
      default:
        return (
          <Chip label="Unknown Environment" color="error" variant="filled" />
        );
    }
  };

  const renderMenuItems = (items: MenuItem[]) => {
    return items.map((item, index) => (
      <React.Fragment key={index}>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to={item.path}
            selected={isPathActive(item.path)}
            sx={{
              "&.Mui-selected": {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                "& .MuiListItemIcon-root": {
                  color: theme.palette.primary.main,
                },
                "&:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              },
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                "& .MuiListItemIcon-root": {
                  color: theme.palette.primary.light,
                },
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: isPathActive(item.path)
                  ? "inherit"
                  : theme.palette.primary.main,
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
            <ListItemIcon
              sx={{ justifyContent: "flex-end", minWidth: "auto", ml: 2 }}
            >
              <ArrowForwardIosIcon sx={{ fontSize: "0.8rem" }} />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        {item.children && (
          <List component="div" disablePadding>
            {renderMenuItems(item.children)}
          </List>
        )}
      </React.Fragment>
    ));
  };

  const drawerContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      onClick={isMobile ? handleDrawerToggle : undefined}
    >
      <Toolbar />
      <List>
        {renderMenuItems(menuItems)}
        {user?.isTheftyAdmin && (
          <>
            <Divider />
            {renderMenuItems(adminMenuItem)}
          </>
        )}
        <Divider />
      </List>

      <Box
        sx={{
          flexGrow: 1,
          p: 2,
        }}
      >
        <LanguageSelector />
      </Box>
      {/* User Info and Logout Section */}
      <Box
        sx={{
          p: 2,
          pb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <AccountCircle sx={{ fontSize: 60 }} />
        <Typography variant="body2">
          {`${user?.firstName || ""} ${user?.lastName || ""}`.trim() ||
            "User Name"}
          {/* Fallback in case user.firstName or user.lastName is undefined */}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
          gutterBottom
        >
          {user?.email || "user@example.com"}{" "}
          {/* Fallback in case user.email is undefined */}
        </Typography>
        <Button
          variant="outlined"
          fullWidth
          startIcon={<Logout />}
          onClick={() => {
            logout();
            navigate("/login");
          }}
        >
          {t("SharedTranslations.Logout")}
        </Button>
        <EnvironmentChip />
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawerContent}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawerContent}
        </Drawer>
      </Box>
    </>
  );
};

export default TheftyNavDrawer;
