import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OrganizationUserAssociationService from "../services/OrganizationUserAssociationService";
import { OrganizationUserAssociationResponse } from "../types/OrganizationUserAssociation";
import OrganizationUserAssociationEditModal from "./OrganizationUserAssociationEditModal";
import ConfirmationModal from "../../../components/Shared/ConfirmationModal";

interface OrganizationUserAssociationListProps {
  organizationGuid: string;
}

const OrganizationUserAssociationList: React.FC<
  OrganizationUserAssociationListProps
> = ({ organizationGuid }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAssociation, setSelectedAssociation] =
    useState<OrganizationUserAssociationResponse | null>(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  // Fetch organization user associations
  const { isLoading, isError, data } = useQuery<
    OrganizationUserAssociationResponse[],
    Error
  >(
    ["OrganizationUserAssociations", organizationGuid],
    () =>
      OrganizationUserAssociationService.getByOrganizationGuidDetailed(
        organizationGuid
      ),
    {
      onError: (err) => {
        toast.error(`Error: ${err.message}`, {
          position: "bottom-right",
        });
      },
    }
  );

  // Mutation for removing a user association
  const revokeMutation = useMutation(
    (associationGuid: string) =>
      OrganizationUserAssociationService.revoke(associationGuid),
    {
      onSuccess: () => {
        toast.success(
          t("OrganizationUserAssociationList.RevokedSuccessfully"),
          {
            position: "bottom-right",
          }
        );
        queryClient.invalidateQueries([
          "OrganizationUserAssociations",
          organizationGuid,
        ]);
      },
      onError: (error: Error) => {
        toast.error(`${error.message}`, { position: "bottom-right" });
      },
    }
  );

  // Handlers for menu actions
  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    association: OrganizationUserAssociationResponse
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedAssociation(association);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const handleEdit = () => {
    handleMenuClose();
    openEditModal();
  };

  const handleRevoke = () => {
    handleMenuClose();
    openConfirmationModal();
  };

  const handleEditModalClose = (guid?: string) => {
    setEditModalOpen(false);
    setSelectedAssociation(null);
  };

  const handleConfirmationModalClose = (confirmed: boolean) => {
    setConfirmationModalOpen(false);
    if (confirmed && selectedAssociation !== null) {
      revokeMutation.mutate(
        selectedAssociation.organizationUserAssociationGuid
      );
    }
    setSelectedAssociation(null);
  };

  // Render loading state
  if (isLoading) {
    return <CircularProgress />;
  }

  // Render error state
  if (isError) {
    return (
      <Typography variant="body1">
        {t("SharedTranslations.ErrorLoadingData")}
      </Typography>
    );
  }

  // Render empty state
  if (!data || data.length === 0) {
    return (
      <Typography variant="body1">
        {t("SharedTranslations.NoDataFound")}
      </Typography>
    );
  }

  // Render table with data
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("SharedTranslations.Username")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("SharedTranslations.FullName")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("SharedTranslations.Role")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("SharedTranslations.Actions")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((association) => (
            <TableRow key={association.organizationUserAssociationGuid}>
              <TableCell>{association.user.username}</TableCell>
              <TableCell>{`${association.user.fullName}`}</TableCell>
              <TableCell>{association.roleText}</TableCell>
              <TableCell>
                <IconButton
                  onClick={(event) => handleMenuOpen(event, association)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={
                    Boolean(anchorEl) &&
                    selectedAssociation?.organizationUserAssociationGuid ===
                      association.organizationUserAssociationGuid
                  }
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleEdit}>
                    {t("SharedTranslations.Edit")}
                  </MenuItem>
                  <MenuItem onClick={handleRevoke}>
                    {t("SharedTranslations.Remove")}
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {selectedAssociation && (
        <OrganizationUserAssociationEditModal
          organizationUserAssociation={selectedAssociation}
          open={editModalOpen}
          onClose={handleEditModalClose}
        />
      )}

      {selectedAssociation && (
        <ConfirmationModal
          open={confirmationModalOpen}
          onClose={handleConfirmationModalClose}
          title={t("OrganizationUserAssociationList.ConfirmationModalTitle")}
          description={t(
            "OrganizationUserAssociationList.ConfirmationModalDescription",
            {
              user: selectedAssociation.user.fullName,
            }
          )}
        />
      )}
    </>
  );
};

export default OrganizationUserAssociationList;
