import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import OrganizationService from "../services/OrganizationService";

const OrganizationPublicPage: React.FC = () => {
  const { organizationGuid } = useParams<{ organizationGuid: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLoading, error, data } = useQuery(
    ["organization", organizationGuid],
    async () => {
      if (!organizationGuid) throw new Error("No organization GUID provided");
      return await OrganizationService.getByGuid(organizationGuid);
    },
    {
      onError: (error: any) => {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An unexpected error occurred";
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
        });
        navigate("/");
      },
    }
  );

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 4 }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (error || !data) {
    return (
      <Typography color="error" sx={{ textAlign: "center", mt: 4 }}>
        {t("OrganizationDetailsPage.FailedToLoad")}
      </Typography>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  {data.name}
                </Typography>
              </Grid>
              {data.websiteUrl && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => window.open(data.websiteUrl ?? "", "_blank")}
                    fullWidth
                  >
                    {t("SharedTranslations.Homepage")}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                {data.description}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OrganizationPublicPage;
