import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid, Paper, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import OrganizationList from "../components/OrganizationList";

const OrganizationListPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  {t("OrganizationListPage.Title")}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => navigate(`/organizations/create`)}
                >
                  {t("OrganizationListPage.ButtonCreateOrganizationText")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                {t("OrganizationListPage.Description")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <OrganizationList />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OrganizationListPage;
