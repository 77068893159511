// React and hooks
import { useEffect, useState, useRef } from "react";

// External libraries
import * as yup from "yup";
import { AxiosError } from "axios";

// React UI libraries
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";

// React hooks and utilities from external libraries
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Contexts and services
import AuthService from "../../services/AuthService";
import { ConfirmEmailRequest } from "../../types/AuthTypes";
import { toast } from "react-toastify";

// Validation schema
const schema = yup.object({
  userGuid: yup.string().required("UserGuid is required"),
  token: yup.string().required("Token is required"),
});

const ConfirmEmailForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const userGuid = searchParams.get("userGuid");
  const token = searchParams.get("token");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfirmEmailRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      userGuid: userGuid ?? "",
      token: token ?? "",
    },
  });

  const { mutate } = useMutation<void, AxiosError, ConfirmEmailRequest>(
    (request) => AuthService.confirmEmail(request),
    {
      onSuccess: () => {
        toast.success(t("ConfirmEmailForm.SuccessMessage"), {
          position: "bottom-right",
        });

        navigate("/login");
      },
      onError: (error) => {
        toast.error(error.message, {
          position: "bottom-right",
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
    }
  );

  const onSubmit: SubmitHandler<ConfirmEmailRequest> = (request) => {
    setIsLoading(true);
    mutate(request);
  };

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      if (!userGuid || !token) {
        toast.error("Information was missing. Please try again.", {
          position: "bottom-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        handleSubmit(onSubmit)();
      }
    }
  }, [userGuid, token]);

  return (
    <>
      <Typography variant="h5" align="center" sx={{ mb: 2, fontWeight: 600 }}>
        {t("ConfirmEmailForm.Title")}
      </Typography>
      <Typography variant="body2" align="center" sx={{ mb: 2 }}>
        {t("ConfirmEmailForm.Description")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {(!userGuid || !token) && (
            <>
              <Grid item xs={12}>
                <Controller
                  name="userGuid"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("ConfirmEmailForm.TextFieldUserGuidText")}
                      error={!!errors.userGuid}
                      helperText={errors.userGuid?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="token"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("ConfirmEmailForm.TextFieldTokenText")}
                      error={!!errors.token}
                      helperText={errors.token?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
            >
              {t("ConfirmEmailForm.ButtonConfirmText")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="info"
              fullWidth
              disabled={isLoading}
              onClick={() => navigate("/login")}
            >
              {t("SharedTranslations.Cancel")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ConfirmEmailForm;
