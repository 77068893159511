import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid, Paper, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OrganizationUserAssociationList from "../components/OrganizationUserAssociationList";
import OrganizationUserAssociationCreationModal from "../components/OrganizationUserAssociationCreationModal";

const OrganizationUserListPage: React.FC = () => {
  const { organizationGuid } = useParams<{ organizationGuid: string }>();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = (id?: number) => {
    setModalOpen(false);
    // Handle any additional logic if needed after closing the modal
    // For example, you can refresh the list if a new user was added
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => window.history.back()}
          >
            {t("SharedTranslations.Back")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ padding: 3 }}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm="auto">
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {t("OrganizationUserListPage.Title")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleOpenModal}
                  fullWidth
                >
                  {t("OrganizationUserListPage.ButtonAddUserText")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  {t("OrganizationUserListPage.Description")}
                </Typography>
              </Grid>
              {organizationGuid && (
                <Grid item xs={12}>
                  <OrganizationUserAssociationList
                    organizationGuid={organizationGuid}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {organizationGuid && (
        <OrganizationUserAssociationCreationModal
          organizationGuid={organizationGuid}
          open={modalOpen}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default OrganizationUserListPage;
