// React and React libraries imports
import React from "react";

// Third-party libraries imports (not directly related to React)
import { useQuery, useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

// Internal components/services/hooks imports
import ProductUserAssociationInviteService from "../../../services/Product/ProductUserAssociationInviteService";
import { useUser } from "../../../contexts/UserContext";

// Styling and types imports
import {
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  CircularProgress,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { ProductUserAssociationInviteDetailedResponse } from "../../../types/Product/ProductUserAssociationInvite";
import { toast } from "react-toastify";

interface Props {
  productGuid: string;
}

const PendingProductUserAssociationInviteListByProduct: React.FC<Props> = ({
  productGuid,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { user } = useUser();

  const { isLoading, error, data } = useQuery<
    ProductUserAssociationInviteDetailedResponse[],
    AxiosError
  >(
    ["pendingProductUserAssociationInvitesByProductGuid", productGuid],
    () =>
      ProductUserAssociationInviteService.getAllPendingByProductGuid(
        productGuid
      ),
    {
      enabled: !!productGuid,
    }
  );

  // Accept Invitation Mutation
  const acceptMutation = useMutation(
    (productUserAssociationInviteGuid: string) =>
      ProductUserAssociationInviteService.AcceptProductUserAssociationInvite(
        productUserAssociationInviteGuid
      ),
    {
      onSuccess: () => {
        toast.success(
          t(
            "PendingProductUserAssociationInviteListByProduct.AcceptSuccessMessage"
          ),
          {
            position: "bottom-right",
          }
        );
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          error.message ||
          t(
            "PendingProductUserAssociationInviteListByProduct.AcceptErrorMessage"
          );
        toast.error(errorMessage, {
          position: "bottom-right",
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          "pendingProductUserAssociationInvitesByProductGuid",
          productGuid,
        ]);
        queryClient.invalidateQueries(["product", productGuid]);
      },
    }
  );

  // Cancel Invitation Mutation
  const cancelMutation = useMutation(
    (productUserAssociationInviteGuid: string) =>
      ProductUserAssociationInviteService.CancelProductUserAssociationInvite(
        productUserAssociationInviteGuid
      ),
    {
      onSuccess: () => {
        toast.success(
          t(
            "PendingProductUserAssociationInviteListByProduct.CancelSuccessMessage"
          ),
          {
            position: "bottom-right",
          }
        );
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          error.message ||
          t(
            "PendingProductUserAssociationInviteListByProduct.CancelErrorMessage"
          );
        toast.error(errorMessage, {
          position: "bottom-right",
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          "pendingProductUserAssociationInvitesByProductGuid",
          productGuid,
        ]);
        queryClient.invalidateQueries(["product", productGuid]);
      },
    }
  );

  // Decline Invitation Mutation
  const declineMutation = useMutation(
    (productUserAssociationInviteGuid: string) =>
      ProductUserAssociationInviteService.DeclineProductUserAssociationInvite(
        productUserAssociationInviteGuid
      ),
    {
      onSuccess: () => {
        toast.success(
          t(
            "PendingProductUserAssociationInviteListByProduct.DeclineSuccessMessage"
          ),
          {
            position: "bottom-right",
          }
        );
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          error.message ||
          t(
            "PendingProductUserAssociationInviteListByProduct.DeclineErrorMessage"
          );
        toast.error(errorMessage, {
          position: "bottom-right",
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          "pendingProductUserAssociationInvitesByProductGuid",
          productGuid,
        ]);
        queryClient.invalidateQueries(["product", productGuid]);
      },
    }
  );

  if (isLoading) return <CircularProgress />;

  if (error) return <Typography color="error">{error.message}</Typography>;

  if (!data || data.length === 0) return null;

  return (
    <Grid item xs={12}>
      {data.map((invite) => {
        const isRecipient = invite.recipientUserId === user?.userId;
        const backgroundColor = blue[50];
        const headerText = isRecipient
          ? t(
              "PendingProductUserAssociationInviteListByProduct.TitleRecievedInvitationText",
              {
                requestedByUser: invite.nameOfRequestedByUser,
              }
            )
          : t(
              "PendingProductUserAssociationInviteListByProduct.TitleInvitationSendText",
              {
                recipientUser: invite.nameOfRecipientUser,
              }
            );

        const messageText = isRecipient
          ? t(
              "PendingProductUserAssociationInviteListByProduct.DescriptionReceivedInvitationText",
              {
                requestedByUser: invite.nameOfRequestedByUser,
                message:
                  invite.message ||
                  t(
                    "PendingProductUserAssociationInviteListByProduct.DefaultMessageText"
                  ),
              }
            )
          : t(
              "PendingProductUserAssociationInviteListByProduct.DescriptionSentInvitationText",
              {
                RecipientUser: invite.nameOfRecipientUser,
              }
            );

        return (
          <Paper
            key={invite.productUserAssociationInviteGuid}
            variant="outlined"
            sx={{ backgroundColor, mb: 0, p: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                  {headerText}
                </Typography>
                <Typography sx={{ whiteSpace: "pre-wrap" }}>
                  {messageText}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex" }}>
                  {isRecipient ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ flexGrow: 1, mr: 1 }}
                        onClick={() =>
                          acceptMutation.mutate(
                            invite.productUserAssociationInviteGuid
                          )
                        }
                        disabled={
                          acceptMutation.isLoading || declineMutation.isLoading
                        }
                      >
                        {t(
                          "PendingProductUserAssociationInviteListByProduct.ButtonAcceptText"
                        )}
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ flexGrow: 1 }}
                        onClick={() =>
                          declineMutation.mutate(
                            invite.productUserAssociationInviteGuid
                          )
                        }
                        disabled={
                          acceptMutation.isLoading || declineMutation.isLoading
                        }
                      >
                        {t(
                          "PendingProductUserAssociationInviteListByProduct.ButtonDeclineText"
                        )}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() =>
                        cancelMutation.mutate(
                          invite.productUserAssociationInviteGuid
                        )
                      }
                      disabled={cancelMutation.isLoading}
                    >
                      {t(
                        "PendingProductUserAssociationInviteListByProduct.ButtonCancelText"
                      )}
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </Grid>
  );
};

export default PendingProductUserAssociationInviteListByProduct;
