import {
  CreateProductUserAssociationInviteRequest,
  ProductUserAssociationInviteBaseResponse,
  ProductUserAssociationInviteDetailedResponse,
} from "../../types/Product/ProductUserAssociationInvite";
import axiosInstance from "../AxiosConfig";

const ProductUserAssociationInviteService = {
  async AcceptProductUserAssociationInvite(
    productUserAssociationInviteGuid: string
  ): Promise<ProductUserAssociationInviteDetailedResponse> {
    const response =
      await axiosInstance.patch<ProductUserAssociationInviteDetailedResponse>(
        `api/ProductUserAssociationInvites/${productUserAssociationInviteGuid}/Accept`
      );
    return response.data;
  },

  async CancelProductUserAssociationInvite(
    productUserAssociationInviteGuid: string
  ): Promise<ProductUserAssociationInviteDetailedResponse> {
    const response =
      await axiosInstance.patch<ProductUserAssociationInviteDetailedResponse>(
        `api/ProductUserAssociationInvites/${productUserAssociationInviteGuid}/Cancel`
      );

    return response.data;
  },

  async Create(
    request: CreateProductUserAssociationInviteRequest
  ): Promise<ProductUserAssociationInviteBaseResponse> {
    const response =
      await axiosInstance.post<ProductUserAssociationInviteBaseResponse>(
        "api/ProductUserAssociationInvites",
        request
      );

    return response.data;
  },

  async DeclineProductUserAssociationInvite(
    productUserAssociationInviteGuid: string
  ): Promise<ProductUserAssociationInviteDetailedResponse> {
    const response =
      await axiosInstance.patch<ProductUserAssociationInviteDetailedResponse>(
        `api/ProductUserAssociationInvites/${productUserAssociationInviteGuid}/Decline`
      );
    return response.data;
  },

  async GetAllPendingAsRecipient(): Promise<
    ProductUserAssociationInviteDetailedResponse[]
  > {
    const response = await axiosInstance.get<
      ProductUserAssociationInviteDetailedResponse[]
    >("api/ProductUserAssociationInvites/Pending/Recipient");
    return response.data;
  },

  async GetAllPendingAsRequester(): Promise<
    ProductUserAssociationInviteDetailedResponse[]
  > {
    const response = await axiosInstance.get<
      ProductUserAssociationInviteDetailedResponse[]
    >("api/ProductUserAssociationInvites/Pending/Requester");
    return response.data;
  },

  async getAllPendingByProductGuid(
    productGuid: string
  ): Promise<ProductUserAssociationInviteDetailedResponse[]> {
    const response = await axiosInstance.get<
      ProductUserAssociationInviteDetailedResponse[]
    >(`api/Products/${productGuid}/ProductUserAssociationInvites/Pending`);
    return response.data;
  },
};

export default ProductUserAssociationInviteService;
