import axiosInstance from "./AxiosConfig";
import {
  ConfirmEmailRequest,
  AuthResponse,
  ForgotPasswordRequest,
  LoginRequest,
  RegisterRequest,
  ResendConfirmationEmailRequest,
  ResetPasswordRequest,
  User,
} from "../types/AuthTypes";

const AuthService = {
  async confirmEmail(request: ConfirmEmailRequest): Promise<void> {
    await axiosInstance.post("/api/auth/confirm-email", request);
  },

  async forgotPassword(request: ForgotPasswordRequest): Promise<void> {
    await axiosInstance.post("/api/auth/forgot-password", request);
  },

  async getAccountInfo(): Promise<User> {
    const response = await axiosInstance.get<User>("/api/Account");
    return response.data;
  },

  async login(credentials: LoginRequest): Promise<AuthResponse> {
    const response = await axiosInstance.post<AuthResponse>(
      "/api/auth/login",
      credentials
    );
    return response.data;
  },

  async register(userDetails: RegisterRequest): Promise<void> {
    await axiosInstance.post("/api/auth/register", userDetails);
  },

  async resendConfirmationEmail(
    request: ResendConfirmationEmailRequest
  ): Promise<void> {
    await axiosInstance.post("/api/auth/resend-confirmation-email", request);
  },

  async resetPassword(request: ResetPasswordRequest): Promise<void> {
    await axiosInstance.post("/api/auth/reset-password", request);
  },
};

export default AuthService;
