import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AnalyticsDashboard from "./AnalyticsDashboard";

function AdministrationDashboard() {
  const navigate = useNavigate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={6} xl={4}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Product Brands
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => navigate("/productbrands")}
              >
                Manage Product Brands
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} lg={6} xl={4}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Product Category
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => navigate("/productcategories")}
              >
                Manage Product Categories
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} lg={6} xl={4}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Product Identifier Types
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => navigate("/productidentifiertypes")}
              >
                Manage Product Identifier Types
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <AnalyticsDashboard />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AdministrationDashboard;
