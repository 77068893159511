import React from "react";
import { Button, Typography, Grid, Paper, Link, Box } from "@mui/material";

const SupportPage: React.FC = () => {
  const mailtoLink = "mailto:support@thefty.app?subject=Support Request";
  const phoneNumber = "tel:+4560525082";

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Support
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you're encountering issues or have ideas for how we can improve,
            we're here to listen and assist.
          </Typography>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Contact Us
            </Typography>
            <Typography variant="body1" gutterBottom>
              Feel free to reach out directly to our support team.
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link href={mailtoLink} sx={{ display: "block" }}>
                support@thefty.app
              </Link>
              <Link href={phoneNumber} sx={{ display: "block" }}>
                (+45) 60 52 50 82
              </Link>
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            href={mailtoLink}
            sx={{ mt: 1, mb: 3 }}
          >
            Email Support
          </Button>
          <Typography variant="h6" gutterBottom>
            Head Office
          </Typography>
          <Typography variant="body2">
            <b>Thefty</b> <br />
            DK-41046074 <br />
            (a part of Michael Johannessen ApS) <br />
            <br />
            Gammel Køge Landevej 55, 5. sal <br />
            2500 Valby <br />
            Denmark
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SupportPage;
