import { UserBaseResponse } from "../../../types/User";
import { OrganizationBaseResponse } from "./Organization";

export type CreateOrganizationUserAssociationRequest = {
  organizationGuid: string;
  userGuid: string;
  role: OrganizationUserAssociationRoleEnum;
};

export type OrganizationUserAssociationResponse = {
  organizationUserAssociationId: number;
  organizationUserAssociationGuid: string;
  organizationId: number;
  organization: OrganizationBaseResponse;
  userId: number;
  user: UserBaseResponse;
  role: number;
  roleText: string;
  createdAt: string;
};

export type UpdateOrganizationUserAssociationRequest = {
  role: OrganizationUserAssociationRoleEnum;
};

export enum OrganizationUserAssociationRoleEnum {
  Member = 0,
  Manager = 1,
  Administrator = 2,
}
