import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, Grid, TextField, Typography, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import ProductBrandService from "../../../services/Product/ProductBrandService";
import { toast } from "react-toastify";
import {
  ProductBrandResponse,
  UpdateProductBrandRequest,
} from "../../../types/Product/ProductBrand";

const schema = yup.object({
  name: yup.string().required().min(2).max(255),
  description: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .max(2000),
});

const ProductBrandEditForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { productBrandId } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  // Fetch ProductBrand data
  const { data: productBrand, isLoading: isFetching } = useQuery(
    ["ProductBrand", productBrandId],
    () => ProductBrandService.getById(Number(productBrandId)),
    {
      enabled: !!productBrandId,
      onSuccess: (data) => {
        reset({ name: data.name, description: data.description || "" });
      },
    }
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const mutation = useMutation<
    ProductBrandResponse,
    AxiosError,
    UpdateProductBrandRequest
  >((data) => ProductBrandService.update(Number(productBrandId), data), {
    onSuccess: () => {
      setIsLoading(false);
      toast.success(t("ProductBrandEditForm.SuccessMessage"), {
        position: "bottom-right",
        closeOnClick: true,
      });
      queryClient.invalidateQueries(["ProductBrand", productBrandId]);
      queryClient.invalidateQueries("ProductBrands");
      navigate(`/productbrands/${productBrandId}`);
    },
    onError: (error) => {
      setIsLoading(false);
      toast.error("Error updating product brand: " + error.message, {
        position: "bottom-right",
        closeOnClick: true,
      });
    },
  });

  const onSubmit = (data: UpdateProductBrandRequest) => {
    setIsLoading(true);
    mutation.mutate(data);
  };

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        {/* Heading and Description */}
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("ProductBrandEditForm.Title")}
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {t("ProductBrandEditForm.Description")}
          </Typography>
        </Grid>

        {/* Form Fields */}
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {/* Name Field */}
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("ProductBrandEditForm.TextFieldNameLabel")}
                      placeholder={t(
                        "ProductBrandEditForm.TextFieldNamePlaceholder"
                      )}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* Description Field */}
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "ProductBrandEditForm.TextFieldDescriptionLabel"
                      )}
                      placeholder={t(
                        "ProductBrandEditForm.TextFieldDescriptionPlaceholder"
                      )}
                      multiline
                      rows={4}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* Action Buttons */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<AddIcon />}
                  disabled={isLoading}
                >
                  {t("ProductBrandEditForm.ButtonSaveText")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  disabled={isLoading}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    const returnUrl = location.state?.returnUrl;
                    if (returnUrl) {
                      navigate(returnUrl);
                    } else {
                      navigate(`/productbrands/${productBrandId}`);
                    }
                  }}
                >
                  {t("ProductBrandEditForm.ButtonCancelText")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductBrandEditForm;
