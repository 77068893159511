import axiosInstance from "./AxiosConfig";
import {
  SearchUserResponse,
  UserProfileWithStatsResponse,
  UserResponse,
} from "../types/User";

const baseURL = "api/Users";

const UserService = {
  async getUserByGuid(userGuid: string): Promise<UserResponse> {
    const response = await axiosInstance.get<UserResponse>(
      `${baseURL}/${userGuid}`
    );
    return response.data;
  },

  async getUserProfileByUserGuid(
    userGuid: string
  ): Promise<UserProfileWithStatsResponse> {
    const response = await axiosInstance.get<UserProfileWithStatsResponse>(
      `${baseURL}/${userGuid}/profile`
    );
    return response.data;
  },

  async search(searchTerm: string): Promise<UserResponse[]> {
    // Obsolete? Replace with SearchUsers
    const response = await axiosInstance.get<UserResponse[]>(
      `${baseURL}/search?searchTerm=${searchTerm}`
    );
    return response.data;
  },

  async SearchUsers(searchTerm: string): Promise<SearchUserResponse[]> {
    const response = await axiosInstance.get<SearchUserResponse[]>(
      `${baseURL}/search?searchTerm=${searchTerm}`
    );
    return response.data;
  },
};

export default UserService;
