import { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { jwtDecode } from "jwt-decode";
import { User } from "../types/AuthTypes";
import AuthService from "../services/AuthService";

interface UserContextProps {
  user: User | null;
  isLoading: boolean;
  refetch: () => Promise<void>;
  clearUserData: () => void;
}

interface UserProviderProps {
  children: React.ReactNode;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

interface TokenPayload {
  exp: number;
  // Add other properties of the token payload if needed
}

const isTokenValid = (token: string): boolean => {
  try {
    const decoded: TokenPayload = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decoded.exp > currentTime;
  } catch (error) {
    console.error("Token decoding error:", error);
    return false;
  }
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [userData, setUserData] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleClearUserData = () => {
    setUserData(null);
    setIsLoading(false);
  };

  const {
    data: user = null,
    refetch: refetchUser,
    isError,
    error,
  } = useQuery<User | null>("User", AuthService.getAccountInfo, {
    enabled: false,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
    onSuccess: (data) => {
      setIsLoading(false);
      setUserData(data);
    },
    onError: () => {
      setIsLoading(false);
      handleClearUserData();
    },
  });

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      if (token && isTokenValid(token)) {
        await refetchUser();
      } else {
        handleClearUserData();
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (isError) {
      console.error("Failed to fetch account info.", error);
      localStorage.removeItem("token");
      handleClearUserData();
    }
  }, [isError, error]);

  const handleRefetch = async () => {
    await refetchUser();
  };

  return (
    <UserContext.Provider
      value={{
        user: userData,
        isLoading,
        refetch: handleRefetch,
        clearUserData: handleClearUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
