// React imports
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Form and validation imports
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// MUI imports
import {
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
  Divider,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

// React Query imports
import { useMutation, useQueryClient } from "react-query";

// Axios imports
import { AxiosError } from "axios";

// Localization imports
import { useTranslation } from "react-i18next";

// Service imports
import ProductService from "../../services/Product/ProductService";

// Types imports
import {
  CreateProductRequest,
  ProductBaseResponse,
} from "../../types/Product/Product";

// Component imports
import ProductModelAutocomplete from "./ProductModel/ProductModelAutocomplete";
import ProductIdentifierTypeSelect from "./ProductIdentifierType/ProductIdentifierTypeSelect";
import ProductBrandAutocomplete from "./ProductBrand/ProductBrandAutocomplete";
import { toast } from "react-toastify";

type CreateProductFormInput = {
  name: string;
  productBrandId: number;
  productModelId: number;
  productIdentifiers: {
    identifier: string;
    productIdentifierTypeId: number;
  }[];
};

const CreateProductForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const passedProductBrandId = location.state?.productBrandId;
  const passedProductModelId = location.state?.productModelId;

  const productSchema = yup.object().shape({
    name: yup
      .string()
      .required(t("Errors.FieldNameRequired"))
      .min(2, t("Errors.FieldNameMinimumLength", { minLength: 2 })),
    productBrandId: yup
      .number()
      .typeError(t("Errors.FieldProductBrandRequired"))
      .required(t("Errors.FieldProductBrandRequired")),
    productModelId: yup
      .number()
      .typeError(t("Errors.FieldProductModelRequired"))
      .required(t("Errors.FieldProductModelRequired")),
    productIdentifiers: yup
      .array()
      .of(
        yup.object().shape({
          identifier: yup
            .string()
            .required(t("Errors.FieldProductIdentifierRequired"))
            .min(3, t("Errors.FieldProductIdentifierMinimumLength")),
          productIdentifierTypeId: yup
            .number()
            .typeError(t("Errors.FieldProductIdentifierTypeRequired"))
            .required(t("Errors.FieldProductIdentifierTypeRequired")),
        })
      )
      .required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<CreateProductFormInput>({
    resolver: yupResolver(productSchema),
    defaultValues: {
      name: "",
      productBrandId: passedProductBrandId ?? undefined,
      productModelId: passedProductModelId ?? undefined,
      productIdentifiers: [
        { identifier: "", productIdentifierTypeId: undefined },
      ],
    },
  });

  const selectedProductBrandId = watch("productBrandId");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "productIdentifiers",
  });

  const mutation = useMutation<
    ProductBaseResponse,
    AxiosError,
    CreateProductRequest
  >(ProductService.create, {
    onSuccess: (response) => {
      setIsLoading(false);

      toast.success(t("CreateProductForm.SuccessMessage"), {
        position: "bottom-right",
      });

      queryClient.invalidateQueries("products");
      navigate(`/products/${response.productGuid}`);
    },
    onError: (error: AxiosError) => {
      setIsLoading(false);
      const errorMessage =
        error.message || "An error occurred while creating the product Model";

      toast.error(errorMessage, {
        position: "bottom-right",
      });
    },
  });

  const onSubmit: SubmitHandler<CreateProductFormInput> = async (data) => {
    setIsLoading(true);
    const productToCreate: CreateProductRequest = {
      name: data.name,
      productModelId: data.productModelId,
      productIdentifiers: data.productIdentifiers.map((identifier) => ({
        identifier: identifier.identifier,
        productIdentifierTypeId: identifier.productIdentifierTypeId,
      })),
    };

    mutation.mutate(productToCreate);
  };

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("CreateProductForm.Title")}
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {t("CreateProductForm.Description")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="productBrandId"
                  control={control}
                  render={({ field }) => (
                    <ProductBrandAutocomplete
                      selectedProductBrandId={field.value ?? null}
                      onProductBrandSelect={(value) => field.onChange(value)}
                      error={Boolean(errors.productBrandId)}
                      helperText={errors.productBrandId?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  startIcon={<AddIcon />}
                  disabled={!!selectedProductBrandId || isLoading}
                  onClick={() =>
                    navigate("/productbrands/create", {
                      state: { returnUrl: "/products/create" },
                    })
                  }
                >
                  {t("CreateProductForm.CreateProductBrand")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="productModelId"
                  control={control}
                  render={({ field }) => (
                    <ProductModelAutocomplete
                      productBrandId={selectedProductBrandId || null}
                      selectedProductModelId={field.value ?? null}
                      onProductModelSelect={(value) => field.onChange(value)}
                      error={Boolean(errors.productModelId)}
                      helperText={errors.productModelId?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  fullWidth
                  disabled={!selectedProductBrandId || isLoading}
                  onClick={() =>
                    navigate(
                      `/productbrands/${selectedProductBrandId}/productmodels/create`,
                      {
                        state: { returnUrl: "/products/create" },
                      }
                    )
                  }
                >
                  {t("CreateProductForm.CreateProductModel")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label={t("CreateProductForm.TextFieldNameLabel")}
                  {...register("name")}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  variant="outlined"
                  placeholder={t("CreateProductForm.TextFieldNamePlaceholder")}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t("CreateProductForm.ProductIdentifiersTitle")}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {t("CreateProductForm.ProductIdentifiersDescription")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* Product Identifiers */}
              {fields.map((field, index) => (
                <React.Fragment key={field.id}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={t(
                        "CreateProductForm.TextFieldProductIdentifierLabel"
                      )}
                      placeholder={t(
                        "CreateProductForm.TextFieldProductIdentifierPlaceholder"
                      )}
                      autoComplete="off"
                      {...register(`productIdentifiers.${index}.identifier`)}
                      error={Boolean(
                        errors.productIdentifiers?.[index]?.identifier
                      )}
                      helperText={
                        errors.productIdentifiers?.[index]?.identifier?.message
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`productIdentifiers.${index}.productIdentifierTypeId`}
                      control={control}
                      defaultValue={undefined}
                      render={({ field }) => (
                        <ProductIdentifierTypeSelect
                          selectedId={field.value ?? null}
                          onSelectionChange={(value) => field.onChange(value)}
                          error={Boolean(
                            errors.productIdentifiers?.[index]
                              ?.productIdentifierTypeId
                          )}
                          helperText={
                            errors.productIdentifiers?.[index]
                              ?.productIdentifierTypeId?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => remove(index)}
                      disabled={isLoading || fields.length === 1}
                      startIcon={<RemoveCircleOutlineIcon />}
                      fullWidth
                    >
                      {t("CreateProductForm.ButtonRemoveIdentifier")}
                    </Button>
                  </Grid>
                </React.Fragment>
              ))}

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="info"
                  fullWidth
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() =>
                    append({
                      identifier: "",
                      productIdentifierTypeId: 0,
                    })
                  }
                  disabled={isLoading}
                >
                  {t("CreateProductForm.ButtonAddIdentifier")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<AddIcon />}
                  disabled={isLoading}
                >
                  {t("CreateProductForm.ButtonCreateText")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled={isLoading}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate("/products/")}
                >
                  {t("CreateProductForm.ButtonCancelText")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CreateProductForm;
