import React, { useEffect } from "react";
import { useUser } from "../contexts/UserContext";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { toast } from "react-toastify";

interface AdminOnlyRoutesProps {
  layout: React.ComponentType<any>;
}

const AdminOnlyRoutes: React.FC<AdminOnlyRoutesProps> = ({
  layout: Layout,
}) => {
  const { user, isLoading } = useUser();
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && user && !user.isTheftyAdmin) {
      toast.error("You are not authorized to access this page.", {
        position: "bottom-right",
        autoClose: 5000,
        closeOnClick: true,
        theme: "light",
      });
    }
  }, [user, isLoading]);

  // Show loading indicator while user info is being fetched
  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          We are logging you in...
        </Typography>
      </Box>
    );
  }

  if (!user) {
    return <Navigate to="/login" state={{ returnUrl: location }} />;
  }

  if (!user.isTheftyAdmin) {
    return <Navigate to="/" />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default AdminOnlyRoutes;
