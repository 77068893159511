// React and related hooks
import React from "react";
import { useQuery } from "react-query";

// Material-UI components
import { CircularProgress, Grid, Paper, Typography } from "@mui/material";

// Validation libraries

// Local imports
import AccountService from "../services/AccountService";
import UpdateUsernameForm from "../components/Account/UpdateUsernameForm";
import UpdateEmailForm from "../components/Account/UpdateEmailForm";

const AccountDetailsPage: React.FC = () => {
  // use react query to fetch account information
  const {
    isLoading,
    isError,
    data: account,
  } = useQuery("AccountDetails", AccountService.getAccountDetails);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <Typography variant="h6">Error fetching account details</Typography>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Manage Account Information
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Personal Information
                </Typography>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Your personal information is critical for establishing your
                  identity and building a trustworthy platform. This information
                  is essential for identifying you as an individual. Please
                  ensure it is accurate and up-to-date.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Username
                </Typography>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Your username is your unique identifier on the platform. If
                  you wish to change it, please enter a new username below and
                  apply the changes.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {account && <UpdateUsernameForm username={account.username} />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Email
                </Typography>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Your email is used for account recovery and notifications. If
                  you wish to change it, please enter a new email below and
                  apply the changes.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {account && (
                  <UpdateEmailForm
                    email={account.email}
                    isEmailConfirmed={account.isEmailConfirmed}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Phone
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Phone</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Address Information
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Address</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AccountDetailsPage;
