import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProductService from "../../../services/Product/ProductService";
import { ProductBaseResponse } from "../../../types/Product/Product";
import { toast } from "react-toastify";

interface ProductAutocompleteProps {
  selectedProductGuid: string | null;
  onProductSelect: (id: string | null) => void;
  error?: boolean;
  helperText?: string;
}

// Custom hook to fetch products
const useFetchProducts = () => {
  return useQuery<ProductBaseResponse[], Error>(
    "Products",
    ProductService.getAll
  );
};

const ProductAutocomplete: React.FC<ProductAutocompleteProps> = ({
  selectedProductGuid,
  onProductSelect,
  error = false,
  helperText = "",
}) => {
  const { t } = useTranslation();
  const { isLoading, error: queryError, data } = useFetchProducts();

  // Effect to handle errors
  useEffect(() => {
    if (queryError) {
      const errorMessage =
        queryError instanceof Error
          ? queryError.message
          : "An unexpected error occurred";

      toast.error(errorMessage, {
        position: "bottom-right",
      });
    }
  }, [queryError]);

  // Handle selection change
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: ProductBaseResponse | null
  ) => {
    onProductSelect(newValue?.productGuid || null);
  };

  // Ensure that the value is always defined
  const selectedProduct =
    data?.find((product) => product.productGuid === selectedProductGuid) ||
    null;

  return (
    <Autocomplete
      value={selectedProduct}
      noOptionsText={t("ProductAutocomplete.NoOptionsText")}
      options={data || []}
      getOptionLabel={(option) =>
        `${option.name} (${option.productModel.productBrand.name} ${option.productModel.name})`
      }
      isOptionEqualToValue={(option, value) =>
        option.productGuid === value.productGuid
      }
      onChange={handleChange}
      renderOption={(props, option) => (
        <li {...props} key={option.productGuid}>
          {option.name} ({option.productModel.productBrand.name}{" "}
          {option.productModel.name})
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t("ProductAutocomplete.TextFieldLabel")}
          placeholder={t("ProductAutocomplete.TextFieldPlaceholder")}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ProductAutocomplete;
