// React and hooks
import { useState } from "react";

// External libraries
import * as yup from "yup";

// React UI libraries
import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

// React hooks and utilities from external libraries
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

// Contexts and services
import { toast } from "react-toastify";
import { SearchUserResponse } from "../../types/User";
import UserService from "../../services/UserService";

const schema = yup.object({
  searchTerm: yup.string().required("Search term is required"),
});

const UserSearchPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchUserResponse[]>([]);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ searchTerm: string }>({
    resolver: yupResolver(schema),
    defaultValues: {
      searchTerm: "",
    },
  });

  const onSubmit: SubmitHandler<{ searchTerm: string }> = async ({
    searchTerm,
  }) => {
    try {
      setIsLoading(true);
      const results = await UserService.SearchUsers(searchTerm);

      console.log(results);

      setSearchResults(results); // Assuming your service method returns the data directly

      toast.success("Search successful", {
        position: "bottom-right",
      });
    } catch (error) {
      const err = error as Error;
      toast.error(err.message || "Something went wrong. Please try again.", {
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Paper elevation={1} sx={{ padding: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Search for a User
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{}}>
              On this page, you can search for a user by their name, email, or
              other username.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="searchTerm"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        label="Search Query"
                        placeholder="Enter the user's name, email, username or phone number"
                        error={!!errors.searchTerm}
                        helperText={errors.searchTerm?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isLoading}
                  >
                    {isLoading ? "Searching for user..." : "Search for user"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {searchResults.length > 0 ? (
              searchResults.map((user) => (
                <Typography
                  key={user.userId}
                  variant="body2"
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate(`/Users/${user.userGuid}`)}
                >
                  {user.userId} - {user.userGuid} - {user.firstName} -{" "}
                  {user.userName} - {user.firstName} - {user.lastName}
                </Typography>
              ))
            ) : (
              <Typography variant="body2">No Search Result</Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default UserSearchPage;
