import axios, { AxiosError, AxiosInstance } from "axios";
import { AxiosErrorResponse } from "../types/AxiosTypes";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

if (!REACT_APP_API_URL) {
  throw new Error(
    "REACT_APP_API_URL is not defined. Please set the environment variable."
  );
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: REACT_APP_API_URL,
});

const ERROR_MESSAGES = {
  NO_RESPONSE: "No response received from server.",
  SETUP_ERROR: "Error setting up request.",
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError<AxiosErrorResponse>) => {
    if (error.response) {
      const { data, status } = error.response;
      const errorMessage =
        data?.exceptionMessage || data?.message || "An error occurred";
      // Create a new error object to preserve status and message
      const customError = new AxiosError(errorMessage);
      customError.response = {
        ...error.response,
        status,
        data,
      };
      return Promise.reject(customError);
    } else if (error.request) {
      return Promise.reject(new Error(ERROR_MESSAGES.NO_RESPONSE));
    } else {
      return Promise.reject(new Error(ERROR_MESSAGES.SETUP_ERROR));
    }
  }
);

export default axiosInstance;
