import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Typography,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Paper,
  Grid,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { LuPackagePlus } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import ProductUserAssociationInviteService from "../../../services/Product/ProductUserAssociationInviteService";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

const PendingProductUserAssociationInviteAsRecipientList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Fetch invitations with react-query
  const { isLoading, error, data } = useQuery(
    "productUserAssociationsAsRecipient",
    ProductUserAssociationInviteService.GetAllPendingAsRecipient
  );

  // Accept invitation mutation
  const acceptMutation = useMutation(
    ProductUserAssociationInviteService.AcceptProductUserAssociationInvite,
    {
      onSuccess: () => {
        toast.success(
          t(
            "PendingProductUserAssociationInviteAsRecipientList.AcceptedSuccessMessage"
          ),
          {
            position: "bottom-right",
          }
        );
        queryClient.invalidateQueries("productUserAssociationsAsRecipient");
      },
      onError: (error: AxiosError) => {
        toast.error(error.message || t("Errors.GenericError"), {
          position: "bottom-right",
        });
      },
    }
  );

  // Decline invitation mutation
  const declineMutation = useMutation(
    ProductUserAssociationInviteService.DeclineProductUserAssociationInvite,
    {
      onSuccess: () => {
        toast.success(
          t(
            "PendingProductUserAssociationInviteAsRecipientList.DeclinedSuccessMessage"
          ),
          {
            position: "bottom-right",
          }
        );
        queryClient.invalidateQueries("productUserAssociationsAsRecipient");
      },
      onError: (error: AxiosError) => {
        toast.error(error.message || t("DeclineErrorMessage"), {
          position: "bottom-right",
        });
      },
    }
  );

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">{t("errorOccurred")}</Typography>;
  if (!data || data.length === 0) return null; // Return null if no data

  return (
    <Grid item xs={12}>
      <Paper elevation={0} sx={{ padding: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {t("PendingProductUserAssociationInviteAsRecipientList.Title")}
        </Typography>
        <Typography sx={{ color: "text.secondary", mb: 2 }}>
          {t("PendingProductUserAssociationInviteAsRecipientList.Description")}
        </Typography>
        <List sx={{ mt: 2 }}>
          {data.map((invitation) => (
            <Paper
              key={invitation.productUserAssociationInviteId}
              elevation={0}
              sx={{
                mb: 1,
                p: 2,
                border: 1,
                borderColor: "divider",
                borderRadius: 2,
              }}
            >
              {/* List item for each invitation */}
              <ListItemButton
                onClick={() => navigate(`/products/${invitation.productGuid}`)}
                sx={{ borderRadius: 2 }}
              >
                <ListItemIcon>
                  <LuPackagePlus fontSize="28" color="black" />
                </ListItemIcon>
                <ListItemText
                  primary={t(
                    "PendingProductUserAssociationInviteAsRecipientList.InvitationTitleText",
                    {
                      nameOfRequestedByUser: invitation.nameOfRequestedByUser,
                      productName: `${invitation.productBrandName} ${invitation.productModelName}`,
                    }
                  )}
                  secondary={
                    <>
                      <Typography variant="body2">
                        {t(
                          "PendingProductUserAssociationInviteAsRecipientList.InvitationContextAndRequest",
                          {
                            requestedByUser: invitation.nameOfRequestedByUser,
                            productName: `${invitation.productBrandName} ${invitation.productModelName}`,
                          }
                        )}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {t(
                          "PendingProductUserAssociationInviteAsRecipientList.InvitationMessage",
                          {
                            message:
                              invitation.message ||
                              t(
                                "PendingProductUserAssociationInviteAsRecipientList.NoMessage"
                              ),
                          }
                        )}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {t(
                          "PendingProductUserAssociationInviteAsRecipientList.InvitationActionPrompt"
                        )}
                      </Typography>
                    </>
                  }
                  primaryTypographyProps={{ fontWeight: "bold" }}
                />
                <ListItemIcon>
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItemIcon>
              </ListItemButton>
              {/* Actions to accept or decline the invitation */}
              <Box sx={{ pt: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        acceptMutation.mutate(
                          invitation.productUserAssociationInviteGuid
                        )
                      }
                      fullWidth
                      startIcon={<CheckIcon />}
                    >
                      {t("SharedTranslations.Accept")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        declineMutation.mutate(
                          invitation.productUserAssociationInviteGuid
                        )
                      }
                      fullWidth
                      startIcon={<CloseIcon />}
                    >
                      {t("SharedTranslations.Decline")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          ))}
        </List>
      </Paper>
    </Grid>
  );
};

export default PendingProductUserAssociationInviteAsRecipientList;
