// React
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI
import { Button, Grid, Paper, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SearchIcon from "@mui/icons-material/Search";

// Components
import PendingProductUserAssociationInviteAsRecipientList from "../../components/Product/ProductUserAssociationInvite/PendingProductUserAssociationInviteAsRecipientList";

// Assets
import Agreement from "../../assets/images/illustrations/Illustration Agreement.svg";
import IllustrationAnalytics from "../../assets/images/illustrations/Illustartion Analytics.svg";
import Ownership from "../../assets/images/illustrations/Illustration Ownership.svg";
import Search from "../../assets/images/illustrations/Illustration Search.svg";

const HomeDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <PendingProductUserAssociationInviteAsRecipientList />

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={0}
              sx={{
                padding: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <img
                src={Ownership}
                alt="Ownership"
                style={{ width: "100%", height: "auto" }}
              />
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  fontWeight: "bold",
                  marginTop: 2,
                  textAlign: "center",
                  lineHeight: 1.5,
                  maxWidth: "90%",
                  mx: "auto",
                }}
              >
                {t("HomeDashboard.RegisterProductSectionTitle")}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  flexGrow: 1,
                  marginBottom: 2,
                  textAlign: "center",
                  lineHeight: 1.6,
                  maxWidth: "90%",
                  mx: "auto",
                }}
              >
                {t("HomeDashboard.RegisterProductSectionDescription")}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => navigate("products/create")}
                fullWidth
              >
                {t("HomeDashboard.RegisterProductSectionButtonText")}
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={0}
              sx={{
                padding: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <img
                src={IllustrationAnalytics}
                alt="IllustrationAnalytics"
                style={{ width: "100%", height: "auto" }}
              />
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  fontWeight: "bold",
                  marginTop: 2,
                  textAlign: "center",
                  lineHeight: 1.5,
                  maxWidth: "90%",
                  mx: "auto",
                }}
              >
                {t("HomeDashboard.AssetManagementSectionTitle")}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  flexGrow: 1,
                  marginBottom: 2,
                  textAlign: "center",
                  lineHeight: 1.6,
                  maxWidth: "90%",
                  mx: "auto",
                }}
              >
                {t("HomeDashboard.AssetManagementSectionDescription")}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ListAltIcon />}
                onClick={() => navigate("products")}
                fullWidth
              >
                {t("HomeDashboard.AssetManagementSectionButtonText")}
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={0}
              sx={{
                padding: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <img
                src={Agreement}
                alt="Agreement"
                style={{ width: "100%", height: "auto" }}
              />
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  fontWeight: "bold",
                  marginTop: 2,
                  textAlign: "center",
                  lineHeight: 1.5,
                  maxWidth: "90%",
                  mx: "auto",
                }}
              >
                {t("HomeDashboard.OwnershipTransferingSectionTitle")}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  flexGrow: 1,
                  marginBottom: 2,
                  textAlign: "center",
                  lineHeight: 1.6,
                  maxWidth: "90%",
                  mx: "auto",
                }}
              >
                {t("HomeDashboard.OwnershipTransferingSectionDescription")}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={0}
              sx={{
                padding: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <img
                src={Search}
                alt="Search"
                style={{ width: "100%", height: "auto" }}
              />
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  fontWeight: "bold",
                  marginTop: 2,
                  textAlign: "center",
                  lineHeight: 1.5,
                  maxWidth: "90%",
                  mx: "auto",
                }}
              >
                {t("HomeDashboard.ProductSearchSectionTitle")}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  flexGrow: 1,
                  marginBottom: 2,
                  textAlign: "center",
                  lineHeight: 1.6,
                  maxWidth: "90%",
                  mx: "auto",
                }}
              >
                {t("HomeDashboard.ProductSearchSectionDescription")}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<SearchIcon />}
                onClick={() => navigate("products/search")}
              >
                {t("HomeDashboard.ProductSearchSectionButtonText")}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeDashboard;
