import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  CircularProgress,
  Typography,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import BusinessIcon from "@mui/icons-material/Business";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { toast } from "react-toastify";
import { useLoading } from "../../../contexts/LoadingContext";
import OrganizationService from "../services/OrganizationService";

const OrganizationList: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoading, hideLoading } = useLoading();

  const { isLoading, error, data } = useQuery(
    "AssociatedOrganizations",
    async () => {
      showLoading();
      try {
        const data = await OrganizationService.getAll();
        return data;
      } catch (error) {
        throw error;
      } finally {
        hideLoading();
      }
    },
    {
      onError: (error: any) => {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An unexpected error occurred";
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
        });
        navigate("/");
      },
    }
  );

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 4 }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (error || !data) {
    return (
      <Typography color="error" sx={{ textAlign: "center", mt: 4 }}>
        {t("OrganizationList.FailedToLoad")}
      </Typography>
    );
  }

  return (
    <>
      {data.length === 0 ? (
        <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
          {t("OrganizationList.NoOrganizationsFound")}
        </Typography>
      ) : (
        <List sx={{ mt: 2 }}>
          {data.map((organization, index) => (
            <React.Fragment key={organization.organizationId}>
              <ListItemButton
                component={Link}
                to={`/organizations/${organization.organizationGuid}`}
              >
                <ListItemIcon>
                  <BusinessIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={organization.name}
                  primaryTypographyProps={{ fontWeight: "bold" }}
                  secondary={organization.description}
                  secondaryTypographyProps={{ color: "text.secondary" }}
                />
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
              </ListItemButton>
              {index < data.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      )}
    </>
  );
};

export default OrganizationList;
