import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  ProductResponse,
  UpdateProductRequest,
} from "../../types/Product/Product";
import ProductService from "../../services/Product/ProductService";

const ProductEditForm = () => {
  const { productGuid } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t("Errors.FieldNameRequired"))
      .min(2, t("Errors.FieldNameMinimumLength", { minLength: 2 })),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
    },
  });

  // Enable or disable the query based on whether productGuid is defined.
  const { isLoading, error } = useQuery<ProductResponse, AxiosError>(
    ["product", productGuid],
    () => ProductService.getByGuid(productGuid as string),
    {
      enabled: !!productGuid,
      onSuccess: (data) => {
        setValue("name", data.name);
      },
    }
  );

  const updateProductMutation = useMutation(
    (productForm: UpdateProductRequest) => {
      if (!productGuid) throw new Error("Product Guid is undefined");
      return ProductService.update(productGuid, productForm);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["product", productGuid]);
        navigate(`/products/${productGuid}`);

        toast.success(t("ProductEditForm.SuccessMessage"), {
          position: "bottom-right",
          autoClose: 5000,
          closeOnClick: true,
          theme: "light",
        });
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as any)?.message || error.message;
        toast.error(`Error: ${errorMessage}`, {
          position: "bottom-right",
          autoClose: 5000,
          closeOnClick: true,
          theme: "light",
        });
      },
    }
  );

  const onSubmit = (formData: UpdateProductRequest) => {
    updateProductMutation.mutate(formData);
  };

  if (!productGuid) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography color="error">Product GUID is missing.</Typography>
      </Box>
    );
  }

  if (isLoading)
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography color="error">
          Error:{" "}
          {(error.response?.data as { message?: string }).message ||
            error.message}
        </Typography>
      </Box>
    );

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("ProductEditForm.Title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label={t("SharedTranslations.Name")}
                      placeholder={t(
                        "ProductEditForm.TextFieldNamePlaceholder"
                      )}
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  startIcon={<SaveIcon />}
                >
                  {t("SharedTranslations.SaveChanges")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={() => navigate(`/products/${productGuid}`)}
                  fullWidth
                  startIcon={<ArrowBackIcon />}
                >
                  {t("SharedTranslations.Cancel")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductEditForm;
