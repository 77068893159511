import { Grid } from "@mui/material";
import React from "react";
import ProductsList from "../components/Product/ProductsList";

const ProductListPage: React.FC = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProductsList />
        </Grid>
      </Grid>
    </>
  );
};

export default ProductListPage;
