import React, { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProductBrandService from "../../../services/Product/ProductBrandService";
import { ProductBrandResponse } from "../../../types/Product/ProductBrand";
import { toast } from "react-toastify";

interface ProductBrandAutocompleteProps {
  selectedProductBrandId: number | null;
  onProductBrandSelect: (id: number | null) => void;
  error?: boolean;
  helperText?: string;
}

// Custom hook to fetch product brands
const useFetchProductBrands = () => {
  return useQuery<ProductBrandResponse[], Error>(
    "ProductBrands",
    ProductBrandService.getAll
  );
};

const ProductBrandAutocomplete: React.FC<ProductBrandAutocompleteProps> = ({
  selectedProductBrandId,
  onProductBrandSelect,
  error = false,
  helperText = "",
}) => {
  const { t } = useTranslation();
  const { isLoading, error: queryError, data } = useFetchProductBrands();

  // Effect to handle errors
  useEffect(() => {
    if (queryError) {
      const errorMessage =
        queryError instanceof Error
          ? queryError.message
          : "An unexpected error occurred";

      toast.error(errorMessage, {
        position: "bottom-right",
      });
    }
  }, [queryError]);

  // Handle selection change
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: ProductBrandResponse | null
  ) => {
    onProductBrandSelect(newValue?.productBrandId || null);
  };

  const options = useMemo(() => data || [], [data]);
  const selectedValue = useMemo(
    () =>
      options.find(
        (brand) => brand.productBrandId === selectedProductBrandId
      ) || null,
    [options, selectedProductBrandId]
  );

  return (
    <Autocomplete
      value={selectedValue}
      noOptionsText={t("ProductBrandAutocomplete.NoOptionsText")}
      options={options}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) =>
        option.productBrandId === value.productBrandId
      }
      onChange={handleChange}
      renderOption={(props, option) => (
        <li {...props} key={option.productBrandId}>
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t("ProductBrandAutocomplete.TextFieldLabel")}
          placeholder={t("ProductBrandAutocomplete.TextFieldPlaceholder")}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ProductBrandAutocomplete;
