// React and hooks
import { useState } from "react";

// External libraries
import * as yup from "yup";
import { AxiosError } from "axios";

// React UI libraries
import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// React hooks and utilities from external libraries
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Contexts and services
import AuthService from "../../services/AuthService";

// Types and interfaces
import { RegisterRequest } from "../../types/AuthTypes";
import { toast } from "react-toastify";

// Validation schema
const schema = yup.object({
  username: yup.string().required("Username is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long")
    .matches(/[A-Z]/, "Password must have at least one uppercase letter")
    .matches(/[a-z]/, "Password must have at least one lowercase letter")
    .matches(/\d/, "Password must have at least one digit")
    .matches(/\W/, "Password must have at least one special character"),
  acceptTerms: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions")
    .required("You must accept the terms and conditions"),
});

const RegisterForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      acceptTerms: false, // default value for terms acceptance
    },
  });

  const { mutate } = useMutation<void, AxiosError, RegisterRequest>(
    (registerData) => AuthService.register(registerData),
    {
      onSuccess: () => {
        navigate("/");
        toast.success(t("RegisterForm.SuccessMessage"), {
          position: "bottom-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      },
      onError: (error) => {
        toast.error(error.message, {
          position: "bottom-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
    }
  );

  const onSubmit: SubmitHandler<RegisterRequest> = (data) => {
    setIsLoading(true);
    mutate(data);
  };

  return (
    <>
      <Typography variant="h5" align="center" sx={{ mb: 2, fontWeight: 600 }}>
        {t("RegisterForm.Title")}
      </Typography>
      <Typography variant="body2" align="center" sx={{ mb: 2 }}>
        {t("RegisterForm.Subtitle")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("RegisterForm.TextFieldUsernameLabelText")}
                  placeholder={t(
                    "RegisterForm.TextFieldUsernamePlaceholderText"
                  )}
                  error={!!errors.username}
                  helperText={errors.username?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("RegisterForm.TextFieldFirstNameLabelText")}
                  placeholder={t(
                    "RegisterForm.TextFieldFirstNamePlaceholderText"
                  )}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("RegisterForm.TextFieldLastNameLabelText")}
                  placeholder={t(
                    "RegisterForm.TextFieldLastNamePlaceholderText"
                  )}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="email"
                  label={t("RegisterForm.TextFieldEmailLabelText")}
                  placeholder={t("RegisterForm.TextFieldEmailPlaceholderText")}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={showPassword ? "text" : "password"}
                  label={t("RegisterForm.TextFieldPasswordLabelText")}
                  placeholder={t(
                    "RegisterForm.TextFieldPasswordPlaceholderText"
                  )}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="acceptTerms"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label={
                    <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: t("RegisterForm.AcceptTermsLabel"),
                      }}
                    />
                  }
                />
              )}
            />
            {errors.acceptTerms && (
              <Typography variant="body2" color="error">
                {t("RegisterForm.AcceptTermsError")}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
            >
              {isLoading
                ? t("RegisterForm.Registering")
                : t("RegisterForm.Register")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align="center">
              {t("RegisterForm.AlreadyHaveAccount")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="info"
              fullWidth
              disabled={isLoading}
              onClick={() => navigate("/login")}
            >
              {t("RegisterForm.GoToLogin")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default RegisterForm;
