// React and React Router
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

// Administration and Support Components
import AdministrationDashboard from "../components/Administration/AdministrationDashboard";
import SupportPage from "../components/SupportPage";

// Authentication Components
import ConfirmEmailForm from "../components/Authentication/ConfirmEmailForm";
import ForgotPasswordForm from "../components/Authentication/ForgotPasswordForm";
import LoginForm from "../components/Authentication/LoginForm";
import RegisterForm from "../components/Authentication/RegisterForm";
import ResetPasswordForm from "../components/Authentication/ResetPasswordForm";

// Components
import AccountDetailsPage from "../pages/AccountDetailsPage";
import ConfirmEmailChangeComponent from "../components/Account/ConfirmEmailChangeComponent";
import CreateProductAdvancedForm from "../modules/Product/components/CreateProductAdvancedForm";
import CreateProductBrandForm from "../components/Product/ProductBrand/CreateProductBrandForm";
import CreateProductCategoryForm from "../components/Product/ProductCategory/CreateProductCategoryForm";
import CreateProductForm from "../components/Product/CreateProductForm";
import CreateProductIdentifierForm from "../components/Product/ProductIdentifiers/CreateProductIdentifierForm";
import CreateProductIdentifierTypeForm from "../components/Product/ProductIdentifierType/CreateProductIdentifierTypeForm";
import CreateProductModelForm from "../components/Product/ProductModel/CreateProductModelForm";
import CreateProductReferenceTag from "../modules/ProductReferenceTag/components/CreateProductReferenceTag";
import CreateProductUserAssociationInviteForm from "../components/Product/ProductUserAssociationInvite/CreateProductUserAssociationInviteForm";
import OrganizationCreationFormPage from "../modules/Organization/pages/OrganizationCreationFormPage";
import OrganizationDetailsPage from "../modules/Organization/pages/OrganizationDetailsPage";
import OrganizationListPage from "../modules/Organization/pages/OrganizationListPage";
import OrganizationProductListPage from "../modules/Organization/pages/OrganizationProductListPage";
import OrganizationPublicPage from "../modules/Organization/pages/OrganizationPublicPage";
import OrganizationUserListPage from "../modules/Organization/pages/OrganizationUserListPage";
import ProductBrandDetails from "../components/Product/ProductBrand/ProductBrandDetails";
import ProductBrandEditForm from "../components/Product/ProductBrand/ProductBrandEditForm";
import ProductBrandsList from "../components/Product/ProductBrand/ProductBrandsList";
import ProductCategoryEditForm from "../components/Product/ProductCategory/ProductCategoryEditForm";
import ProductCategoryList from "../components/Product/ProductCategory/ProductCategoryList";
import ProductEditForm from "../components/Product/ProductEditForm";
import ProductFinder from "../components/Product/ProductFinder";
import ProductIdentifierTypeEditForm from "../components/Product/ProductIdentifierType/ProductIdentifierTypeEditForm";
import ProductIdentifierTypesList from "../components/Product/ProductIdentifierType/ProductIdentifierTypesList";
import ProductModelEditForm from "../components/Product/ProductModel/ProductModelEditForm";
import ProductPublicDetailsPage from "../components/Product/ProductPublicDetailsPage";
import ProductReferenceTagChecker from "../modules/ProductReferenceTag/components/ProductReferenceTagChecker";

// Layouts
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import MainLayout from "../layouts/MainLayout";

// Pages
import HomeDashboard from "../pages/Dashboards/HomeDashboard";
import PathNotFound from "../pages/Shared/PathNotFound";
import ProductDetailsPage from "../pages/Product/ProductDetailsPage";
import ProductListPage from "../pages/ProductsPage";
import UserProfilePublicPage from "../pages/User/UserProfilePublicPage";
import UserSearchPage from "../pages/User/UserSearchPage";

// Utilities and Helpers
import AdminOnlyRoutes from "./AdminOnlyRoutes";
import ProtectedRoutes from "./ProtectedRoutes";

function AuthWrapper() {
  return (
    <AuthenticationLayout>
      <Outlet />
    </AuthenticationLayout>
  );
}

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<AuthWrapper />}>
        <Route path="/confirm-email" element={<ConfirmEmailForm />} />
        <Route path="/forgot-password" element={<ForgotPasswordForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/reset-password" element={<ResetPasswordForm />} />
      </Route>

      {/* Admin Protected Routes */}
      <Route element={<AdminOnlyRoutes layout={MainLayout} />}>
        <Route path="/administration" element={<AdministrationDashboard />} />
        <Route path="/productcategories" element={<ProductCategoryList />} />
        <Route
          path="/productcategories/create"
          element={<CreateProductCategoryForm />}
        />
        <Route
          path="/productcategories/:productCategoryId/edit"
          element={<ProductCategoryEditForm />}
        />
        <Route
          path="/productidentifiertypes"
          element={<ProductIdentifierTypesList />}
        />
        <Route
          path="/productidentifiertypes/create"
          element={<CreateProductIdentifierTypeForm />}
        />
        <Route
          path="/productidentifiertypes/:productIdentifierTypeId/edit"
          element={<ProductIdentifierTypeEditForm />}
        />
        <Route
          path="productmodels/:productModelId/edit"
          element={<ProductModelEditForm />}
        />
      </Route>

      {/* Protected Routes */}
      <Route path="/*" element={<ProtectedRoutes layout={MainLayout} />}>
        {/* Dashboard */}
        <Route index element={<HomeDashboard />} />

        {/* Account */}
        <Route path="account" element={<AccountDetailsPage />} />
        <Route
          path="confirm-email-change"
          element={<ConfirmEmailChangeComponent />}
        />

        {/* Organization */}
        <Route path="Organizations" element={<OrganizationListPage />} />
        <Route
          path="Organizations/create"
          element={<OrganizationCreationFormPage />}
        />
        <Route
          path="Organizations/:organizationGuid"
          element={<OrganizationDetailsPage />}
        />
        <Route
          path="Organizations/:organizationGuid/public"
          element={<OrganizationPublicPage />}
        />
        <Route
          path="Organizations/:organizationGuid/Products"
          element={<OrganizationProductListPage />}
        />

        <Route
          path="Organizations/:organizationGuid/Products/create"
          element={<CreateProductAdvancedForm />}
        />

        <Route
          path="Organizations/:organizationGuid/users"
          element={<OrganizationUserListPage />}
        />

        {/* Product Brands */}
        <Route path="productbrands" element={<ProductBrandsList />} />
        <Route
          path="productbrands/create"
          element={<CreateProductBrandForm />}
        />
        <Route
          path="productbrands/:productBrandId"
          element={<ProductBrandDetails />}
        />
        <Route
          path="productbrands/:productBrandId/edit"
          element={<ProductBrandEditForm />}
        />
        <Route
          path="productbrands/:productBrandId/productmodels/create"
          element={<CreateProductModelForm />}
        />

        {/* Products */}
        <Route path="products" element={<ProductListPage />} />
        <Route path="products/create" element={<CreateProductForm />} />
        <Route path="products/search" element={<ProductFinder />} />
        <Route path="products/:productGuid" element={<ProductDetailsPage />} />
        <Route
          path="products/:productGuid/public"
          element={<ProductPublicDetailsPage />}
        />

        <Route
          path="products/:productGuid/edit"
          element={<ProductEditForm />}
        />
        <Route
          path="products/:productGuid/productidentifiers/create"
          element={<CreateProductIdentifierForm />}
        />

        {/* Product Reference Tags */}
        <Route
          path="productreferencetag/:productReferenceTagGuid/"
          element={<ProductReferenceTagChecker />}
        />
        <Route
          path="productreferencetag/:productReferenceTagGuid/create"
          element={<CreateProductReferenceTag />}
        />

        {/* Product User Association Invite */}
        <Route
          path="products/:productGuid/productuserassociations/:productUserAssociationGuid/productuserassociationinvites/create"
          element={<CreateProductUserAssociationInviteForm />}
        />

        {/* User Profile */}
        <Route
          path="Users/:userGuid/Profile"
          element={<UserProfilePublicPage />}
        />

        <Route path="Support" element={<SupportPage />} />

        {/* User */}
        <Route path="Users/Search" element={<UserSearchPage />} />

        {/* 404 Not Found */}
        <Route path="*" element={<PathNotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
