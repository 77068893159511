// React-related imports
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Third-party library imports
import { useQuery } from "react-query";
import {
  CircularProgress,
  Typography,
  Paper,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

// Icons
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import LabelIcon from "@mui/icons-material/Label";

// Utility, services, and other internal imports
import ProductIdentifierTypeService from "../../../services/Product/ProductIdentifierTypeService";
import { useLoading } from "../../../contexts/LoadingContext";
import { toast } from "react-toastify";

const ProductIdentifierTypesList: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoading, hideLoading } = useLoading();

  const [filter, setFilter] = useState("");

  const { isLoading, error, data } = useQuery(
    "ProductIdentifierTypes",
    async () => {
      showLoading();
      try {
        const data = await ProductIdentifierTypeService.getAll();
        return data;
      } catch (error) {
        throw error;
      } finally {
        hideLoading();
      }
    },
    {
      onError: (error: any) => {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An unexpected error occurred";
        toast.error(errorMessage, {
          position: "bottom-right",
        });
        navigate("/");
      },
    }
  );

  if (isLoading) return <CircularProgress />;

  if (error) return <Typography color="error">"An error occurred."</Typography>;

  const filteredData = data?.filter((item) =>
    item.name.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("ProductIdentifierTypesList.Title")}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate("/productidentifiertypes/create")}
          >
            {t("ProductIdentifierTypesList.ButtonCreateText")}
          </Button>
        </Grid>
      </Grid>

      <Typography variant="body1" sx={{ mt: 2 }}>
        {t("ProductIdentifierTypesList.Description")}
      </Typography>

      <TextField
        variant="outlined"
        name="filterProductIdentifierTypes"
        label={t("ProductIdentifierTypesList.TextFieldFilterLabel")}
        placeholder={t("ProductIdentifierTypesList.TextFieldFilterPlaceholder")}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        sx={{ mt: 2 }}
        autoComplete="off"
        fullWidth
      />

      {(!filteredData || filteredData.length === 0) && (
        <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
          {t("ProductIdentifierTypesList.NoProductIdentifierTypesFound")}
        </Typography>
      )}

      {filteredData && filteredData.length > 0 && (
        <List sx={{ mt: 2 }}>
          {filteredData.map((type, index) => (
            <React.Fragment key={type.productIdentifierTypeId}>
              <ListItemButton
                component={Link}
                to={`/productidentifiertypes/${type.productIdentifierTypeId}/edit`}
              >
                <ListItemIcon>
                  <LabelIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={type.name}
                  primaryTypographyProps={{ fontWeight: "bold" }}
                  secondary={type.description}
                />
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
              </ListItemButton>
              {index < filteredData.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      )}
    </Paper>
  );
};

export default ProductIdentifierTypesList;
