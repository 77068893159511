import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import OrganizationUserAssociationService from "../services/OrganizationUserAssociationService";
import {
  OrganizationUserAssociationResponse,
  OrganizationUserAssociationRoleEnum,
  UpdateOrganizationUserAssociationRequest,
} from "../types/OrganizationUserAssociation";

// Schema validation
const schema = yup.object().shape({
  role: yup.number().required("Role is required"),
});

interface OrganizationUserAssociationEditModalProps {
  organizationUserAssociation: OrganizationUserAssociationResponse;
  open: boolean;
  onClose: (guid?: string) => void;
}

const OrganizationUserAssociationEditModal: React.FC<
  OrganizationUserAssociationEditModalProps
> = ({ organizationUserAssociation, open, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  // Form setup
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateOrganizationUserAssociationRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      role: organizationUserAssociation.role,
    },
  });

  // Mutation setup
  const mutation = useMutation<
    OrganizationUserAssociationResponse,
    Error,
    UpdateOrganizationUserAssociationRequest
  >(
    (updateRequest) =>
      OrganizationUserAssociationService.update(
        organizationUserAssociation.organizationUserAssociationGuid,
        updateRequest
      ),
    {
      onSuccess: (data) => {
        toast.success(
          t("OrganizationUserAssociationEditModal.SuccessMessage"),
          {
            position: "bottom-right",
          }
        );
        setIsLoading(false);

        // Invalidate the cache to refetch the data
        queryClient.invalidateQueries([
          "OrganizationUserAssociations",
          organizationUserAssociation.organization.organizationGuid,
        ]);

        onClose(data.organizationUserAssociationGuid);
      },
      onError: (error: any) => {
        toast.error(error.message || "An error occurred.", {
          position: "bottom-right",
        });
        setIsLoading(false);
      },
    }
  );

  // Form submit handler
  const onSubmit = (data: UpdateOrganizationUserAssociationRequest) => {
    setIsLoading(true);
    mutation.mutate(data);
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("OrganizationUserAssociationEditModal.Title")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {t("OrganizationUserAssociationEditModal.Description", {
                user: organizationUserAssociation.user.fullName,
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label={t("SharedTranslations.Role") as string}
                        fullWidth
                        error={!!errors.role}
                        helperText={errors.role?.message}
                      >
                        {Object.entries(OrganizationUserAssociationRoleEnum)
                          .filter(([key, value]) => typeof value === "number")
                          .map(([key, value]) => (
                            <MenuItem key={value} value={value}>
                              {key}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                  >
                    {isLoading
                      ? t("SharedTranslations.Loading")
                      : t(
                          "OrganizationUserAssociationEditModal.ButtonUpdateText"
                        )}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    disabled={isLoading}
                    onClick={() => onClose()}
                  >
                    {t("SharedTranslations.Cancel")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default OrganizationUserAssociationEditModal;
