import { BrowserRouter } from "react-router-dom";

// Local imports
import "./App.css";
import "./i18n/config";
import AppRoutes from "./routes/AppRoutes";
import ProvidersWrapper from "./contexts/ProvidersWrapper";

function App() {
  return (
    <BrowserRouter>
      <ProvidersWrapper>
        <AppRoutes />
      </ProvidersWrapper>
    </BrowserRouter>
  );
}

export default App;
