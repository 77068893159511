import axiosInstance from "../../../services/AxiosConfig";
import { UserResponse } from "../../../types/User";
import {
  CreateOrganizationRequest,
  OrganizationResponse,
} from "../types/Organization";

const OrganizationService = {
  async createOrganization(
    organization: CreateOrganizationRequest
  ): Promise<OrganizationResponse> {
    const response = await axiosInstance.post<OrganizationResponse>(
      "api/organizations",
      organization
    );
    return response.data;
  },

  async getAll(): Promise<OrganizationResponse[]> {
    const response = await axiosInstance.get<OrganizationResponse[]>(
      "api/organizations"
    );
    return response.data;
  },

  async getUsersByOrganizationGuid(
    organizationGuid: string
  ): Promise<UserResponse[]> {
    const response = await axiosInstance.get<UserResponse[]>(
      `api/organizations/${organizationGuid}/users`
    );
    return response.data;
  },

  async getByGuid(organizationGuid: string): Promise<OrganizationResponse> {
    const response = await axiosInstance.get<OrganizationResponse>(
      `api/organizations/${organizationGuid}`
    );
    return response.data;
  },
};

export default OrganizationService;
