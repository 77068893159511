import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import CountryService from "../../services/CountryService";
import { CountryResponse } from "../../types/Country";
import { toast } from "react-toastify";

interface CountryAutocompleteProps {
  selectedCountryId: number | null;
  onCountrySelect: (id: number | null) => void;
  error?: boolean;
  helperText?: string;
}

// Custom hook to fetch countries
const useFetchCountries = () => {
  return useQuery<CountryResponse[], Error>("Countries", CountryService.GetAll);
};

const CountryAutocomplete: React.FC<CountryAutocompleteProps> = ({
  selectedCountryId,
  onCountrySelect,
  error = false,
  helperText = "",
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading, error: queryError, data } = useFetchCountries();

  // Effect to handle errors
  useEffect(() => {
    if (queryError) {
      const errorMessage =
        queryError instanceof Error
          ? queryError.message
          : "An unexpected error occurred";

      toast.error(errorMessage, {
        position: "bottom-right",
      });

      navigate("/");
    }
  }, [queryError, navigate]);

  // Handle selection change
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: CountryResponse | null
  ) => {
    onCountrySelect(newValue?.countryId || null);
  };

  // Ensure that the value is always defined
  const selectedCountry =
    data?.find((country) => country.countryId === selectedCountryId) || null;

  return (
    <Autocomplete
      value={selectedCountry}
      noOptionsText={t("CountryAutocomplete.NoOptionsText")}
      options={data || []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) =>
        option.countryId === value.countryId
      }
      onChange={handleChange}
      renderOption={(props, option) => (
        <li {...props} key={option.countryId}>
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t("CountryAutocomplete.TextFieldLabel")}
          placeholder={t("CountryAutocomplete.TextFieldPlaceholder")}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default CountryAutocomplete;
