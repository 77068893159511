import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import AccountService from "../../services/AccountService";
import { RequestEmailChangeRequest } from "../../types/Account";

interface Props {
  email?: string;
  isEmailConfirmed?: boolean;
}

const schema = yup.object({
  newEmail: yup
    .string()
    .required("Email is required")
    .email("Enter a valid email address"),
});

const UpdateEmailForm: React.FC<Props> = ({ email, isEmailConfirmed }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  // Initialize form with validation and default values
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
  } = useForm<RequestEmailChangeRequest>({
    resolver: yupResolver(schema),
    defaultValues: { newEmail: email },
  });

  // Effect to reset form on email prop change
  useEffect(() => {
    reset({ newEmail: email });
  }, [email, reset]);

  // Mutation setup for changing email with success and error handling
  const mutation = useMutation<void, Error, RequestEmailChangeRequest>(
    AccountService.requestEmailChange,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("AccountDetails");
        toast.success(t("UpdateEmailForm.SuccessMessage"), {
          position: "bottom-right",
        });
      },
      onError: (error) => {
        toast.error(error.message || t("UpdateEmailForm.ErrorMessage"), {
          position: "bottom-right",
        });
      },
      onSettled: () => setOpen(false),
    }
  );

  // Submit handler that opens the confirmation dialog
  const onSubmit = () => setOpen(true);

  // Confirm action handler
  const handleConfirm = () => {
    const data = watch();
    mutation.mutate(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={t("SharedTranslations.Email")}
              placeholder={t("UpdateEmailForm.TextFieldEmailPlaceholderText")}
              fullWidth
              {...register("newEmail")}
              error={!!errors.newEmail}
              helperText={errors.newEmail?.message}
            />
          </Grid>
          {!isEmailConfirmed && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                fullWidth
                disabled={mutation.isLoading}
              >
                {t("SharedTranslations.ResendConfirmationEmail")}
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="outlined"
              fullWidth
              disabled={!isDirty || mutation.isLoading}
            >
              {t("SharedTranslations.SaveChanges")}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">
          {t("UpdateEmailForm.ConfirmDialogTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("UpdateEmailForm.ConfirmDialogText")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {t("SharedTranslations.Cancel")}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {t("SharedTranslations.Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateEmailForm;
