// React-related imports
import React, { useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

// Third-party library imports
import { useQuery } from "react-query";
import {
  CircularProgress,
  Typography,
  Paper,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

// Icons
import AddIcon from "@mui/icons-material/Add";
import CategoryIcon from "@mui/icons-material/Category";
import EditIcon from "@mui/icons-material/Edit";

// Utility, services, and other internal imports
import ProductCategoryService from "../../../services/Product/ProductCategoryService";
import { toast } from "react-toastify";
import { useLoading } from "../../../contexts/LoadingContext";

const ProductCategoryList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoading, hideLoading } = useLoading();

  const [filter, setFilter] = useState("");

  const { isLoading, error, data } = useQuery(
    "ProductCategories",
    async () => {
      showLoading();
      try {
        const data = await ProductCategoryService.getAll();
        return data;
      } catch (error) {
        throw error;
      } finally {
        hideLoading();
      }
    },
    {
      onError: (error: any) => {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An unexpected error occurred";
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
        });
        navigate("/");
      },
    }
  );

  const filteredData = useMemo(
    () =>
      data?.filter((category) =>
        category.name.toLowerCase().includes(filter.toLowerCase())
      ) || [], // Ensure filteredData is never undefined
    [data, filter]
  );

  if (isLoading) return <CircularProgress />;

  if (error)
    return (
      <Typography color="error">Failed to load Product Categories</Typography>
    );

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("ProductCategoryList.Title")}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate("/productcategories/create")}
          >
            {t("ProductCategoryList.CreateButtonText")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {t("ProductCategoryList.Description")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label={t("ProductCategoryList.FilterTextFieldLabel")}
            placeholder={t("ProductCategoryList.FilterTextFieldPlaceholder")}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            fullWidth
            autoComplete="off"
          />
        </Grid>
      </Grid>

      {filteredData.length === 0 && (
        <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
          {t("ProductCategoryList.NoProductCategoriesFound")}
        </Typography>
      )}

      {filteredData.length > 0 && (
        <List sx={{ mt: 2 }}>
          {filteredData.map((category, index) => (
            <React.Fragment key={category.productCategoryId}>
              <ListItemButton
                component={Link}
                to={`/productcategories/${category.productCategoryId}/edit`}
              >
                <ListItemIcon>
                  <CategoryIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={category.name}
                  primaryTypographyProps={{ fontWeight: "bold" }}
                  secondary={category.name}
                  secondaryTypographyProps={{ color: "text.secondary" }}
                />
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
              </ListItemButton>
              {index < filteredData.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      )}
    </Paper>
  );
};

export default ProductCategoryList;
