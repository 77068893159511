import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface ConfirmationModalProps {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  title: string;
  description: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  title,
  description,
}) => {
  const { t } = useTranslation();

  const handleClose = (confirmed: boolean) => () => {
    onClose(confirmed);
  };

  return (
    <Dialog open={open} onClose={handleClose(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose(false)} color="primary">
          {t("SharedTranslations.Cancel")}
        </Button>
        <Button onClick={handleClose(true)} color="primary" autoFocus>
          {t("SharedTranslations.Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
