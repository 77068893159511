import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProductModelService from "../../../services/Product/ProductModelService";
import { ProductModelResponse } from "../../../types/Product/ProductModel";
import { toast } from "react-toastify";

interface Props {
  productBrandId: number | null;
  selectedProductModelId: number | null;
  onProductModelSelect: (id: number | null) => void;
  error?: boolean;
  helperText?: string;
}

// Custom hook to fetch product models by brand
const useFetchProductModels = (productBrandId: number | null) => {
  return useQuery<ProductModelResponse[], Error>(
    ["ProductModels", productBrandId],
    () => ProductModelService.getAllByProductBrandId(productBrandId!),
    {
      enabled: !!productBrandId,
    }
  );
};

const ProductModelAutocomplete: React.FC<Props> = ({
  productBrandId,
  selectedProductModelId,
  onProductModelSelect,
  error = false,
  helperText = "",
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    isLoading,
    error: queryError,
    data,
  } = useFetchProductModels(productBrandId);

  // Effect to handle errors
  useEffect(() => {
    if (queryError) {
      const errorMessage =
        queryError instanceof Error
          ? queryError.message
          : "An unexpected error occurred";
      toast.error(errorMessage, {
        position: "bottom-right",
      });
      navigate("/");
    }
  }, [queryError, navigate]);

  // Handle selection change
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: ProductModelResponse | null
  ) => {
    onProductModelSelect(newValue?.productModelId || null);
  };

  return (
    <Autocomplete
      value={
        data?.find(
          (model) => model.productModelId === selectedProductModelId
        ) || null
      }
      noOptionsText={t("ProductModelAutocomplete.NoOptionsText")}
      options={data || []}
      disabled={!productBrandId || isLoading}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) =>
        option.productModelId === value.productModelId
      }
      onChange={handleChange}
      renderOption={(props, option) => (
        <li {...props} key={option.productModelId}>
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t("ProductModelAutocomplete.TextFieldLabel")}
          placeholder={t("ProductModelAutocomplete.TextFieldPlaceholder")}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ProductModelAutocomplete;
