// React-related imports
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Third-party library imports
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import { Button, Grid, TextField, Typography, Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

// Type imports
import {
  CreateProductIdentifierRequest,
  ProductIdentifierResponse,
} from "../../../types/Product/ProductIdentifier";

// Utility, services, and other internal imports
import ProductIdentifierService from "../../../services/Product/ProductIdentifierService";
import ProductIdentifierTypeSelect from "../ProductIdentifierType/ProductIdentifierTypeSelect";
import { toast } from "react-toastify";

// Schema definition for form validation
const schema = yup.object().shape({
  identifier: yup
    .string()
    .required("Identifier is required")
    .min(5, "Identifier should have at least 5 characters"),
  productIdentifierTypeId: yup
    .number()
    .required("Product Identifier Type is required"),
});

const CreateProductIdentifierForm: React.FC = () => {
  const { productGuid } = useParams<{ productGuid: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateProductIdentifierRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      identifier: "",
    },
  });

  useEffect(() => {
    if (!productGuid) {
      toast.error("Product id was not provided correctly", {
        position: "bottom-right",
      });
      navigate("/products");
    }
  }, [productGuid, navigate]);

  const mutation = useMutation<
    ProductIdentifierResponse,
    AxiosError,
    CreateProductIdentifierRequest
  >(
    (identifier) =>
      ProductIdentifierService.createProductIdentifier(
        productGuid!,
        identifier
      ),
    {
      onSuccess: () => {
        setIsLoading(false);
        toast.success(t("CreateProductIdentifierForm.SuccessMessage"), {
          position: "bottom-right",
          autoClose: 5000,
          closeOnClick: true,
          theme: "light",
        });
        navigate(`/products/${productGuid}`);
      },
      onError: (error) => {
        setIsLoading(false);
        toast.error(error.message, {
          position: "bottom-right",
        });
      },
    }
  );

  const onSubmit = (data: CreateProductIdentifierRequest) => {
    setIsLoading(true);
    mutation.mutate(data);
  };

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("CreateProductIdentifierForm.Title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {t("CreateProductIdentifierForm.Description")}
          </Typography>
        </Grid>
        {/* Form */}
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {/* Identifier Field */}
              <Grid item xs={12}>
                <Controller
                  name="identifier"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "CreateProductIdentifierForm.TextFieldIdentifierLabel"
                      )}
                      placeholder={t(
                        "CreateProductIdentifierForm.TextFieldIdentifierPlaceholder"
                      )}
                      autoComplete="off"
                      error={!!errors.identifier}
                      helperText={errors.identifier?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              {/* Product Identifier Type Select */}
              <Grid item xs={12}>
                <Controller
                  name="productIdentifierTypeId"
                  control={control}
                  render={({ field }) => (
                    <ProductIdentifierTypeSelect
                      selectedId={field.value}
                      onSelectionChange={(value) => field.onChange(value)}
                      error={Boolean(errors.productIdentifierTypeId)}
                      helperText={errors.productIdentifierTypeId?.message}
                    />
                  )}
                />
              </Grid>
              {/* Action Buttons */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<CheckIcon />}
                  disabled={isLoading}
                >
                  {t("CreateProductIdentifierForm.ButtonCreateText")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  startIcon={<ArrowBackIcon />}
                  disabled={isLoading}
                  onClick={() => navigate(`/products/${productGuid}`)}
                >
                  {t("CreateProductIdentifierForm.ButtonCancelText")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CreateProductIdentifierForm;
