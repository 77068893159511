// External library imports
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { Button, Grid, TextField, Typography, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";

// Absolute imports from the project
import ProductModelService from "../../../services/Product/ProductModelService";
import {
  ProductModelResponse,
  UpdateProductModelRequest,
} from "../../../types/Product/ProductModel";

// Relative imports
import ProductCategoryAutocomplete from "../ProductCategory/ProductCategoryAutocomplete";

// Validation schema
const schema = yup.object({
  name: yup.string().required().min(2).max(255),
  description: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .max(2000),
  productCategoryId: yup.number().nullable(),
});

const ProductModelEditForm: React.FC = () => {
  const { productModelId } = useParams<{ productModelId: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Fetch existing data
  const { data: productModel, isLoading: isFetching } = useQuery(
    ["ProductModel", productModelId],
    () => ProductModelService.getById(Number(productModelId)),
    {
      enabled: !!productModelId,
    }
  );

  // Form setup
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateProductModelRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      productCategoryId: null,
    },
  });

  // Initialize form with data once fetched
  React.useEffect(() => {
    if (productModel) {
      reset({
        name: productModel.name,
        description: productModel.description,
        productCategoryId: productModel.productCategoryId,
      });
    }
  }, [productModel, reset]);

  // Mutation setup for updating
  const mutation = useMutation<
    ProductModelResponse,
    AxiosError,
    UpdateProductModelRequest
  >((data) => ProductModelService.update(Number(productModelId), data), {
    onSuccess: (responseData) => {
      toast.success(t("ProductModelEditForm.SuccessMessage"), {
        position: "bottom-right",
      });
      queryClient.invalidateQueries(["ProductModel", productModelId]);
      queryClient.invalidateQueries("ProductModels");

      // Redirect to the product brand details page
      navigate(`/productbrands/${responseData.productBrandId}`);
    },
    onError: (error) => {
      toast.error(error.message, {
        position: "bottom-right",
      });
    },
  });

  const onSubmit = (data: UpdateProductModelRequest) => {
    mutation.mutate(data);
  };

  if (isFetching) return <div>Loading...</div>;

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("ProductModelEditForm.Title")}
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {t("ProductModelEditForm.Description")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("ProductModelEditForm.TextFieldNameLabel")}
                      placeholder={t(
                        "ProductModelEditForm.TextFieldNamePlaceholder"
                      )}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "ProductModelEditForm.TextFieldDescriptionLabel"
                      )}
                      placeholder={t(
                        "ProductModelEditForm.TextFieldDescriptionPlaceholder"
                      )}
                      multiline
                      rows={4}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="productCategoryId"
                  control={control}
                  render={({ field }) => (
                    <ProductCategoryAutocomplete
                      selectedProductCategoryId={field.value || null}
                      onProductCategorySelect={field.onChange}
                      error={Boolean(errors.productCategoryId)}
                      helperText={errors.productCategoryId?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<AddIcon />}
                >
                  {t("ProductModelEditForm.ButtonSaveText")}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate(-1)}
                >
                  {t("ProductModelEditForm.ButtonCancelText")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductModelEditForm;
