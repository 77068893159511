import { CountryResponse } from "../types/Country";
import axiosInstance from "./AxiosConfig";

const CountryService = {
  async GetAll(): Promise<CountryResponse[]> {
    const response = await axiosInstance.get<CountryResponse[]>(
      "api/countries"
    );
    return response.data;
  },
};

export default CountryService;
