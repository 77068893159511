// React and React-Router imports
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// React-Query import
import { useMutation } from "react-query";

// MUI imports
import {
  Button,
  Grid,
  Typography,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBack from "@mui/icons-material/ArrowBack";

// i18next import
import { useTranslation } from "react-i18next";

// Type imports
import {
  CreateProductReferenceTagRequest,
  ProductReferenceTagResponse,
} from "../types/ProductReferenceTag";
import { AxiosErrorResponse } from "../../../types/AxiosTypes";

// Service import
import ProductReferenceTagService from "../services/ProductReferenceTagService";

// Component import
import ProductAutocomplete from "../../Product/components/ProductAutocomplete";

// Toast notification import
import { toast } from "react-toastify";

/**
 * CreateProductReferenceTag component
 *
 * This component is used to create a reference tag for a product. The user can select
 * whether the product is personal or organizational. Once the reference tag is created,
 * it is linked to the selected product for identification purposes.
 */
const CreateProductReferenceTag: React.FC = () => {
  const { productReferenceTagGuid } = useParams<{
    productReferenceTagGuid: string;
  }>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [productType, setProductType] = useState<"personal" | "organizational">(
    "personal"
  );
  const [selectedProductGuid, setSelectedProductGuid] = useState<string | null>(
    null
  );
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleProductTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newProductType: "personal" | "organizational"
  ) => {
    if (newProductType !== null) {
      setProductType(newProductType);
    }
  };

  const handleProductSelect = (productGuid: string | null) => {
    setSelectedProductGuid(productGuid);
  };

  const mutation = useMutation<
    ProductReferenceTagResponse,
    AxiosErrorResponse,
    CreateProductReferenceTagRequest
  >(ProductReferenceTagService.create, {
    onSuccess: (response) => {
      toast.success(t("CreateProductReferenceTag.SuccessMessage"), {
        position: "bottom-right",
      });
      navigate(`/products/${response.productGuid}`);
    },
    onError: (error: AxiosErrorResponse) => {
      toast.error(
        error.message || t("CreateProductReferenceTag.ErrorMessage"),
        { position: "bottom-right" }
      );
    },
  });

  const handleSubmit = () => {
    if (selectedProductGuid && productReferenceTagGuid) {
      setOpenConfirmDialog(true);
    } else {
      toast.error(t("CreateProductReferenceTag.ValidationErrorMessage"), {
        position: "bottom-right",
      });
    }
  };

  const handleConfirmClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmSubmit = () => {
    if (productReferenceTagGuid && selectedProductGuid) {
      mutation.mutate({
        referenceTag: productReferenceTagGuid,
        productGuid: selectedProductGuid,
      });
      setOpenConfirmDialog(false);
    } else {
      toast.error(t("CreateProductReferenceTag.ValidationErrorMessage"), {
        position: "bottom-right",
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                {t("CreateProductReferenceTag.Title")}
              </Typography>

              <Typography
                variant="body1"
                sx={{ mt: 1, color: "text.secondary" }}
              >
                {t("CreateProductReferenceTag.Description")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ToggleButtonGroup
                value={productType}
                exclusive
                onChange={handleProductTypeChange}
                fullWidth
              >
                <ToggleButton value="personal">
                  {t("CreateProductReferenceTag.Personal")}
                </ToggleButton>
                <ToggleButton value="organizational" disabled>
                  {t("CreateProductReferenceTag.Organizational")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              {productType === "personal" ? (
                <ProductAutocomplete
                  selectedProductGuid={selectedProductGuid}
                  onProductSelect={handleProductSelect}
                />
              ) : (
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  Not implemented yet.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                startIcon={<CheckIcon />}
                fullWidth
              >
                {t("CreateProductReferenceTag.ButtonCreateText")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => navigate("/")}
                startIcon={<ArrowBack />}
                fullWidth
              >
                {t("SharedTranslations.Cancel")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmClose}
        aria-labelledby="confirm-dialog-title"
      >
        <DialogTitle id="confirm-dialog-title">
          {t("CreateProductReferenceTag.ConfirmationTitle")}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {t("CreateProductReferenceTag.ConfirmationMessage")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            {t("SharedTranslations.Cancel")}
          </Button>
          <Button onClick={handleConfirmSubmit} color="primary" autoFocus>
            {t("SharedTranslations.Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CreateProductReferenceTag;
