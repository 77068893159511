// React-related imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Third-party library imports
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  FormControlLabel,
  Switch,
  FormControl,
  FormHelperText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

// Type imports
import {
  CreateProductIdentifierTypeRequest,
  ProductIdentifierTypeResponse,
} from "../../../types/Product/ProductIdentifierType";

// Utility, services, and other internal imports
import ProductIdentifierTypeService from "../../../services/Product/ProductIdentifierTypeService";
import { toast } from "react-toastify";

// Constants
const schema = yup.object({
  name: yup
    .string()
    .required("Name is required.")
    .min(2, "Name must be at least 2 characters long.")
    .max(255, "Name must not exceed 255 characters."),
  description: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .max(2000, "Description must not exceed 2000 characters.")
    .notRequired(),
  isGloballyUnique: yup.boolean().required(),
});

const CreateProductIdentifierTypeForm: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateProductIdentifierTypeRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      isGloballyUnique: false,
    },
  });

  const mutation = useMutation<
    ProductIdentifierTypeResponse,
    AxiosError,
    CreateProductIdentifierTypeRequest
  >(ProductIdentifierTypeService.create, {
    onSuccess: () => {
      setIsLoading(false);
      toast.success(t("CreateProductIdentifierTypeForm.SuccessMessage"), {
        position: "bottom-right",
      });

      queryClient.invalidateQueries("ProductIdentifierTypes");
      navigate("/productidentifiertypes");
    },
    onError: (error: AxiosError) => {
      setIsLoading(false);
      const errorMessage =
        error.message ||
        "An error occurred while creating the product identifier type.";
      toast.error(errorMessage, {
        position: "bottom-right",
      });
    },
  });

  const onSubmit = (data: CreateProductIdentifierTypeRequest) => {
    setIsLoading(true);
    mutation.mutate(data);
  };

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("CreateProductIdentifierTypeForm.Title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {t("CreateProductIdentifierTypeForm.Description")}
          </Typography>
        </Grid>
        {/* Form Fields */}
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {/* Name Field */}
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "CreateProductIdentifierTypeForm.TextFieldNameLabel"
                      )}
                      placeholder={t(
                        "CreateProductIdentifierTypeForm.TextFieldNamePlaceholder"
                      )}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* Description Field */}
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "CreateProductIdentifierTypeForm.TextFieldDescriptionLabel"
                      )}
                      placeholder={t(
                        "CreateProductIdentifierTypeForm.TextFieldDescriptionPlaceholder"
                      )}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                      fullWidth
                      multiline
                      rows={4}
                    />
                  )}
                />
              </Grid>

              {/* isGloballyUnique Switch */}
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Controller
                    name="isGloballyUnique"
                    control={control}
                    render={({ field }) => (
                      <>
                        <FormControlLabel
                          control={<Switch {...field} checked={field.value} />}
                          label={t(
                            "CreateProductIdentifierTypeForm.SwitchIsGloballyUniqueLabel"
                          )}
                        />
                        <FormHelperText>
                          {t(
                            "CreateProductIdentifierTypeForm.SwitchIsGloballyUniqueHelperText"
                          )}
                        </FormHelperText>
                      </>
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Action Buttons */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<AddIcon />}
                  disabled={isLoading}
                >
                  {t("CreateProductIdentifierTypeForm.ButtonCreateText")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate("/productidentifiertypes")}
                  disabled={isLoading}
                >
                  {t("CreateProductIdentifierTypeForm.ButtonCancelText")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CreateProductIdentifierTypeForm;
