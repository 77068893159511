import React, { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProductCategoryService from "../../../services/Product/ProductCategoryService";
import { ProductCategoryResponse } from "../../../types/Product/ProductCategory";
import { toast } from "react-toastify";

interface ProductCategoryAutocompleteProps {
  selectedProductCategoryId: number | null;
  onProductCategorySelect: (id: number | null) => void;
  error?: boolean;
  helperText?: string;
}

// Custom hook to fetch product categories
const useFetchProductCategories = () => {
  return useQuery<ProductCategoryResponse[], Error>(
    "ProductCategories",
    ProductCategoryService.getAll
  );
};

const ProductCategoryAutocomplete: React.FC<
  ProductCategoryAutocompleteProps
> = ({
  selectedProductCategoryId,
  onProductCategorySelect,
  error = false,
  helperText = "",
}) => {
  const { t } = useTranslation();
  const { isLoading, error: queryError, data } = useFetchProductCategories();

  // Effect to handle errors
  useEffect(() => {
    if (queryError) {
      const errorMessage =
        queryError.message || t("ProductCategoryAutocomplete.UnexpectedError");
      toast.error(errorMessage, {
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  }, [queryError, t]);

  const selectedCategory = useMemo(
    () =>
      data?.find(
        (category) => category.productCategoryId === selectedProductCategoryId
      ) || null,
    [data, selectedProductCategoryId]
  );

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: ProductCategoryResponse | null
  ) => {
    onProductCategorySelect(newValue?.productCategoryId || null);
  };

  return (
    <Autocomplete
      value={selectedCategory}
      noOptionsText={t("ProductCategoryAutocomplete.NoOptionsText")}
      options={data || []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) =>
        option.productCategoryId === value.productCategoryId
      }
      onChange={handleChange}
      renderOption={(props, option) => (
        <li {...props} key={option.productCategoryId}>
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t("ProductCategoryAutocomplete.TextFieldLabel")}
          placeholder={t("ProductCategoryAutocomplete.TextFieldPlaceholder")}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ProductCategoryAutocomplete;
