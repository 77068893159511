import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { da } from "date-fns/locale";
import { toast } from "react-toastify";
import ProductOrganizationAssociationService from "../services/ProductOrganizationAssociationService";
import { ProductOrganizationAssociationResponse } from "../../../types/Product/ProductOrganizationAssociation";
import ConfirmationModal from "../../../components/Shared/ConfirmationModal";

interface OrganizationProductListProps {
  organizationGuid: string;
}

const OrganizationProductList: React.FC<OrganizationProductListProps> = ({
  organizationGuid,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAssociation, setSelectedAssociation] =
    useState<ProductOrganizationAssociationResponse | null>(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  // Fetch product organization associations
  const { isLoading, isError, data } = useQuery<
    ProductOrganizationAssociationResponse[],
    Error
  >(
    ["ProductOrganizationAssociations", organizationGuid],
    () =>
      ProductOrganizationAssociationService.getAllByOrganizationGuid(
        organizationGuid
      ),
    {
      onError: (err) => {
        toast.error(`Error: ${err.message}`, {
          position: "bottom-right",
        });
      },
    }
  );

  // Mutation for removing a product organization association
  const terminateMutation = useMutation(
    (associationGuid: string) =>
      ProductOrganizationAssociationService.terminate(associationGuid),
    {
      onSuccess: () => {
        toast.success(t("OrganizationProductList.ProductRemovedSuccessfully"), {
          position: "bottom-right",
        });
        queryClient.invalidateQueries([
          "ProductOrganizationAssociations",
          organizationGuid,
        ]);
      },
      onError: (error: Error) => {
        toast.error(`${error.message}`, { position: "bottom-right" });
      },
    }
  );

  // Handlers for menu actions
  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    association: ProductOrganizationAssociationResponse
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedAssociation(association);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const handleTerminate = () => {
    handleMenuClose();
    openConfirmationModal();
  };

  const handleConfirmationModalClose = (confirmed: boolean) => {
    setConfirmationModalOpen(false);
    if (confirmed && selectedAssociation !== null) {
      terminateMutation.mutate(
        selectedAssociation.productOrganizationAssociationGuid
      );
    }
    setSelectedAssociation(null);
  };

  // Render loading state
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  // Render error state
  if (isError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body1" color="error">
          {t("SharedTranslations.ErrorLoadingData")}
        </Typography>
      </Box>
    );
  }

  // Render empty state
  if (!data || data.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body1" color="textSecondary">
          {t("SharedTranslations.NoDataFound")}
        </Typography>
      </Box>
    );
  }

  // Render table with data
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("SharedTranslations.Product")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("SharedTranslations.Name")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("SharedTranslations.ValidFrom")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("SharedTranslations.Actions")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((association) => (
            <TableRow key={association.productOrganizationAssociationId}>
              <TableCell>{`${association.product.productModel.productBrand.name} ${association.product.productModel.name}`}</TableCell>
              <TableCell>{association.product.name}</TableCell>
              <TableCell>
                {format(new Date(association.validFrom), "P", { locale: da })}
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={(event) => handleMenuOpen(event, association)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={
                    Boolean(anchorEl) &&
                    selectedAssociation?.productOrganizationAssociationGuid ===
                      association.productOrganizationAssociationGuid
                  }
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleTerminate}>
                    {t("SharedTranslations.Remove")}
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {selectedAssociation && (
        <ConfirmationModal
          open={confirmationModalOpen}
          onClose={handleConfirmationModalClose}
          title={t("OrganizationProductList.ConfirmationModalTitle")}
          description={t(
            "OrganizationProductList.ConfirmationModalDescription"
          )}
        />
      )}
    </>
  );
};

export default OrganizationProductList;
