import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  CircularProgress,
  Typography,
  Paper,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { LuPackageCheck } from "react-icons/lu";
import ProductService from "../../services/Product/ProductService";
import { toast } from "react-toastify";
import { useLoading } from "../../contexts/LoadingContext";

const ProductsList: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const [filter, setFilter] = useState("");

  const { isLoading, error, data } = useQuery(
    "products",
    async () => {
      showLoading();
      try {
        const data = await ProductService.getAll();
        return data;
      } catch (error) {
        throw error;
      } finally {
        hideLoading();
      }
    },
    {
      onError: (error: any) => {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An unexpected error occurred";
        toast.error(errorMessage, {
          position: "bottom-right",
        });
        navigate("/");
      },
    }
  );

  if (isLoading) return <CircularProgress />;

  if (error)
    return <Typography color="error">An unexpected error occurred</Typography>;

  const filteredData = data?.filter((product) =>
    (
      product.name.toLowerCase() +
      product.productModel.productBrand.name.toLowerCase() +
      product.productModel.name.toLowerCase()
    ).includes(filter.toLowerCase())
  );

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("ProductsList.Title")}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate("/products/create")}
          >
            {t("ProductsList.ButtonCreateText")}
          </Button>
        </Grid>
      </Grid>

      <Typography variant="body1" sx={{ mt: 2 }}>
        {t("ProductsList.Description")}
      </Typography>

      <TextField
        variant="outlined"
        label={t("ProductsList.TextFieldFilterLabel")}
        placeholder={t("ProductsList.TextFieldFilterPlaceholder")}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        fullWidth
        autoComplete="off"
        sx={{ mt: 2 }}
      />

      {(!filteredData || filteredData.length === 0) && (
        <Typography sx={{ mt: 2, textAlign: "center" }}>
          {t("ProductsList.NoProductsFound")}
        </Typography>
      )}

      {filteredData && filteredData.length > 0 && (
        <List sx={{ mt: 2 }}>
          {filteredData.map((product, index) => (
            <React.Fragment key={product.productId}>
              <ListItemButton
                onClick={() => navigate(`/products/${product.productGuid}`)}
              >
                <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ fontSize: "28px" }}>
                    <LuPackageCheck color="black" />
                  </Box>
                </ListItemIcon>

                <ListItemText
                  primary={product.name}
                  secondary={`${product.productModel.productBrand.name} ${product.productModel.name}`}
                  primaryTypographyProps={{ fontWeight: "bold" }}
                />
                <ListItemIcon>
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItemIcon>
              </ListItemButton>
              {index < filteredData.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      )}
    </Paper>
  );
};

export default ProductsList;
