import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import daTranslations from "./da/translation.json";
import deTranslations from "./de/translation.json";
import enTranslations from "./en/translation.json";
import esTranslations from "./es/translation.json";
import frTranslations from "./fr/translation.json";
import itTranslations from "./it/translation.json";
import noTranslations from "./no/translation.json";
import plTranslations from "./pl/translation.json";
import svTranslations from "./sv/translation.json";

if (!i18n.isInitialized) {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        da: {
          translation: daTranslations,
        },
        de: {
          translation: deTranslations,
        },
        en: {
          translation: enTranslations,
        },
        es: {
          translation: esTranslations,
        },
        fr: {
          translation: frTranslations,
        },
        it: {
          translation: itTranslations,
        },
        no: {
          translation: noTranslations,
        },
        pl: {
          translation: plTranslations,
        },
        sv: {
          translation: svTranslations,
        },
      },
      fallbackLng: "en",
      debug: process.env.NODE_ENV === "development",
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
      },
    });
}

export default i18n;
