// React and hooks
import { useEffect, useState } from "react";

// External libraries
import * as yup from "yup";
import { AxiosError } from "axios";

// React UI libraries
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// React hooks and utilities from external libraries
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Contexts and services
import AuthService from "../../services/AuthService";
import { ResetPasswordRequest } from "../../types/AuthTypes";
import { toast } from "react-toastify";

// Validation schema
const schema = yup.object({
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long")
    .matches(/[A-Z]/, "Password must have at least one uppercase letter")
    .matches(/[a-z]/, "Password must have at least one lowercase letter")
    .matches(/\d/, "Password must have at least one digit")
    .matches(/\W/, "Password must have at least one special character"),
});

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const token = searchParams.get("token");

  useEffect(() => {
    if (!userId || !token) {
      toast.error("Information was missing. Please try again.", {
        position: "bottom-right",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
      navigate("/login");
    }
  }, [userId, token, navigate]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ password: string }>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
    },
  });

  const { mutate } = useMutation<void, AxiosError, ResetPasswordRequest>(
    (resetData) => AuthService.resetPassword(resetData),
    {
      onSuccess: () => {
        toast.success(t("ResetPasswordForm.SuccessMessage"), {
          position: "bottom-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
        navigate("/login");
      },
      onError: (error) => {
        toast.error(error.message, {
          position: "bottom-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
    }
  );

  const onSubmit: SubmitHandler<{ password: string }> = ({ password }) => {
    setIsLoading(true);
    const resetData = {
      userId: userId ?? "",
      token: token ?? "",
      password,
    };
    mutate(resetData);
  };

  return (
    <>
      <Typography variant="h5" align="center" sx={{ mb: 2, fontWeight: 600 }}>
        {t("ResetPasswordForm.Title")}
      </Typography>
      <Typography variant="body2" align="center" sx={{ mb: 2 }}>
        {t("ResetPasswordForm.Description")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={showPassword ? "text" : "password"}
                  label={t("ResetPasswordForm.NewPassword")}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
            >
              {isLoading
                ? t("ResetPasswordForm.ResettingPassword")
                : t("ResetPasswordForm.ResetPassword")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="info"
              fullWidth
              disabled={isLoading}
              onClick={() => navigate("/login")}
            >
              {t("ResetPasswordForm.BackToLogin")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ResetPassword;
