// React imports
import React, { useEffect } from "react";

// Material UI imports
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";

// React Query imports
import { useQuery } from "react-query";

// Service imports
import ProductIdentifierTypeService from "../../../services/Product/ProductIdentifierTypeService";

// Type imports
import { ProductIdentifierTypeResponse } from "../../../types/Product/ProductIdentifierType";

// Internationalization imports
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface Props {
  selectedId: number | null;
  onSelectionChange: (selectedId: number | null) => void;
  error?: boolean;
  helperText?: string;
}

// Custom hook to fetch product models by brand
const useFetchProductIdentifierTypes = () => {
  return useQuery<ProductIdentifierTypeResponse[], Error>(
    "ProductIdentifierTypes",
    () => ProductIdentifierTypeService.getAll()
  );
};

const ProductIdentifierTypeSelect: React.FC<Props> = ({
  selectedId,
  onSelectionChange,
  error = false,
  helperText = "",
}) => {
  const { t } = useTranslation();

  const {
    isLoading,
    error: queryError,
    data,
  } = useFetchProductIdentifierTypes();

  // Effect to handle errors
  useEffect(() => {
    if (queryError) {
      const errorMessage =
        queryError instanceof Error
          ? queryError.message
          : "An unexpected error occurred";
      toast.error(errorMessage, {
        position: "bottom-right",
      });
    }
  }, [queryError]);

  // Effect to handle selected id change
  useEffect(() => {
    if (selectedId !== null && data) {
      const found = data.find(
        ({ productIdentifierTypeId }) => productIdentifierTypeId === selectedId
      );
      if (!found) {
        onSelectionChange(null);
      }
    }
  }, [data, selectedId, onSelectionChange]);

  const handleSelectionChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number;
    const found = data?.find(
      ({ productIdentifierTypeId }) => productIdentifierTypeId === value
    );
    onSelectionChange(found ? value : null);
  };

  return (
    <FormControl fullWidth variant="outlined" error={error}>
      <InputLabel id="product-identifier-type-select-label">
        {t("ProductIdentifierTypeSelect.Label")}
      </InputLabel>
      <Select
        labelId="product-identifier-type-select-label"
        id="product-identifier-type-select"
        value={
          selectedId !== null &&
          data?.some((item) => item.productIdentifierTypeId === selectedId)
            ? selectedId
            : ""
        }
        label={t("ProductIdentifierTypeSelect.Label")}
        onChange={handleSelectionChange}
        disabled={isLoading}
      >
        <MenuItem value="" disabled>
          {t("ProductIdentifierTypeSelect.Placeholder")}
        </MenuItem>
        {data?.map(({ productIdentifierTypeId, name }) => (
          <MenuItem
            key={productIdentifierTypeId}
            value={productIdentifierTypeId}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ProductIdentifierTypeSelect;
