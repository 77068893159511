// React and external libraries
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { toast } from "react-toastify";

// Material-UI components
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

// Material-UI icons
import {
  AccessTime as AccessTimeIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  CorporateFare as CorporateFareIcon,
  Description as DescriptionIcon,
  Groups as GroupsIcon,
  HowToReg as HowToRegIcon,
  Label as LabelIcon,
  Lock as LockIcon,
  Person as PersonIcon,
  VpnKey as VpnKeyIcon,
} from "@mui/icons-material";

// Internal/application-specific modules
import { useUser } from "../../contexts/UserContext";
import ProductService from "../../services/Product/ProductService";
import ProductUserAssociationService from "../../services/Product/ProductUserAssociationService";

// Internal types
import { AxiosError } from "axios";
import { AxiosErrorResponse } from "../../types/AxiosTypes";
import { ProductPublicResponse } from "../../types/Product/Product";
import {
  CreateProductUserAssociationRequest,
  ProductUserAssociationResponse,
} from "../../types/Product/ProductUserAssociation";

// Internal components
import ConfirmationModal from "../Shared/ConfirmationModal";

const ProductPublicDetailsPage: React.FC = () => {
  const { productGuid } = useParams<{ productGuid: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, isLoading, isError, error } = useQuery<
    ProductPublicResponse,
    AxiosError<AxiosErrorResponse>
  >(
    ["ProductPublic", productGuid],
    () => ProductService.getPublicByGuid(productGuid as string),
    {
      enabled: !!productGuid,
      retry: false,
      onError: (error) => {
        toast.error(
          error.message ||
            t("ProductPublicDetailsPage.FetchError", {
              defaultMessage:
                "An error occurred while retrieving product data. Please try again later.",
            }),
          { position: "bottom-right" }
        );
      },
    }
  );

  const createProductUserAssociationRequest = useMutation<
    ProductUserAssociationResponse,
    AxiosError<AxiosErrorResponse>,
    CreateProductUserAssociationRequest
  >((request) => ProductUserAssociationService.create(request), {
    onSuccess: (data) => {
      toast.success(
        t("ProductPublicDetailsPage.AssociationSuccess", {
          defaultMessage: "You have successfully associated with the product.",
        }),
        { position: "bottom-right" }
      );
      navigate(`/Products/${productGuid}`);

      queryClient.invalidateQueries(["ProductPublic", productGuid]);
      queryClient.invalidateQueries("Products");
    },
    onError: (error) => {
      toast.error(
        error.message ||
          t("ProductPublicDetailsPage.AssociationError", {
            defaultMessage:
              "An error occurred while creating the association. Please try again.",
          }),
        { position: "bottom-right" }
      );
    },
  });

  if (!productGuid) {
    return null;
  }

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Typography variant="h6" color="error">
          {t("ProductPublicDetailsPage.ErrorOccurred", {
            message: error?.message,
          })}
        </Typography>
      </Box>
    );
  }

  if (!data) {
    return (
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Typography variant="h6">
          {t("ProductPublicDetailsPage.NoDataFound")}
        </Typography>
      </Box>
    );
  }

  const handleModalClose = (confirmed: boolean) => {
    setIsModalOpen(false);
    if (confirmed) {
      createProductUserAssociationRequest.mutate({
        productGuid: data.productGuid,
        userGuid: user?.userGuid as string,
      });
    }
  };

  const renderProductAssociation = (association: any, index: number) => (
    <Grid item xs={12} lg={6} xl={4} display="flex" key={index}>
      <Paper
        elevation={0}
        sx={{
          padding: 3,
          textAlign: "center",
          flex: 1,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <PersonIcon sx={{ fontSize: 40, color: "primary.main" }} />
        <Tooltip title={association.user.username} arrow>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              color: "secondary.main",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              wordWrap: "break-word",
            }}
          >
            {association.user.username}
          </Typography>
        </Tooltip>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {t("ProductPublicDetailsPage.PersonalAssociation")}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {t("ProductPublicDetailsPage.AssociationDuration", {
            username: association.user.username,
            days: association.daysValid,
          })}
        </Typography>
        <IconButton
          sx={{ position: "absolute", right: 16, bottom: 16 }}
          onClick={() =>
            navigate(`/Users/${association.user.userGuid}/Profile`)
          }
        >
          <ArrowForwardIosIcon sx={{ color: "primary.main" }} />
        </IconButton>
      </Paper>
    </Grid>
  );

  const renderOrganizationAssociation = (association: any, index: number) => (
    <Grid item xs={12} lg={6} xl={4} display="flex" key={index}>
      <Paper
        elevation={0}
        sx={{
          padding: 3,
          textAlign: "center",
          flex: 1,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <PersonIcon sx={{ fontSize: 40, color: "primary.main" }} />
        <Tooltip title={association.organization.name} arrow>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              color: "secondary.main",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
              wordWrap: "break-word",
            }}
          >
            {association.organization.name}
          </Typography>
        </Tooltip>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {t("ProductPublicDetailsPage.OrganizationalAssociation")}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {t("ProductPublicDetailsPage.OrganizationAssociationDuration", {
            organization: association.organization.name,
            days: association.daysValid,
          })}
        </Typography>
        <IconButton
          sx={{ position: "absolute", right: 16, bottom: 16 }}
          onClick={() =>
            navigate(
              `/Organizations/${association.organization.organizationGuid}/Public`
            )
          }
        >
          <ArrowForwardIosIcon sx={{ color: "primary.main" }} />
        </IconButton>
      </Paper>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <DescriptionIcon color="primary" />
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      {t("ProductPublicDetailsPage.PublicProductOverview")}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="body1"
                  sx={{ color: "text.secondary", mt: 1 }}
                >
                  {t("ProductPublicDetailsPage.Description")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {t("ProductPublicDetailsPage.Model")}
                </Typography>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  {data.productModel.productBrand.name} {data.productModel.name}
                </Typography>
              </Grid>
              {data.productModel.productCategory && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {t("ProductPublicDetailsPage.Category")}
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    {data.productModel.productCategory?.name}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {t("ProductPublicDetailsPage.DateRegistered")}
                </Typography>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  {format(new Date(data.createdAt), "dd/MM/yyyy")} (
                  {data.productPublicStats.daysRegisteredOnPlatform}{" "}
                  {t("ProductPublicDetailsPage.DaysOnPlatform")})
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {data.productUserAssociations.some(
                (association) =>
                  association.userId === user?.userId && association.isValid
              ) && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<LockIcon />}
                    fullWidth
                    onClick={() => navigate(`/Products/${productGuid}`)}
                  >
                    {t("ProductPublicDetailsPage.GoToPrivateProductDetails")}
                  </Button>
                </Grid>
              )}

              {data.productUserAssociations.length === 0 && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<HowToRegIcon />}
                    fullWidth
                    onClick={() => setIsModalOpen(true)}
                  >
                    {t("ProductPublicDetailsPage.ClaimProductOwnership")}
                  </Button>
                </Grid>
              )}

              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate("/Products")}
                >
                  {t("ProductPublicDetailsPage.BackToProducts")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="stretch"
          >
            {data.productUserAssociations &&
              data.productUserAssociations.map(renderProductAssociation)}

            {data.productOrganizationAssociations &&
              data.productOrganizationAssociations.map(
                renderOrganizationAssociation
              )}

            <Grid item xs={12} lg={6} xl={4} display="flex">
              <Paper
                elevation={0}
                sx={{ padding: 3, textAlign: "center", flex: 1 }}
              >
                <AccessTimeIcon sx={{ fontSize: 40, color: "primary.main" }} />
                <Typography
                  variant="h2"
                  sx={{ fontWeight: 600, color: "secondary.main" }}
                >
                  {data.productPublicStats.daysRegisteredOnPlatform}
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {t("ProductPublicDetailsPage.TotalRegisteredDays")}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {t(
                    "ProductPublicDetailsPage.TotalRegisteredDaysDescription",
                    {
                      days: data.productPublicStats.daysRegisteredOnPlatform,
                      date: format(new Date(data.createdAt), "dd-MM-yyyy"),
                    }
                  )}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={6} xl={4} display="flex">
              <Paper
                elevation={0}
                sx={{ padding: 3, textAlign: "center", flex: 1 }}
              >
                <VpnKeyIcon sx={{ fontSize: 40, color: "primary.main" }} />
                <Typography
                  variant="h2"
                  sx={{ fontWeight: 600, color: "secondary.main" }}
                >
                  {data.productPublicStats.totalProductIdentifierCount}
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {t("ProductPublicDetailsPage.TotalProductIdentifiers")}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {t(
                    "ProductPublicDetailsPage.TotalProductIdentifiersDescription",
                    {
                      count:
                        data.productPublicStats.totalProductIdentifierCount,
                    }
                  )}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={6} xl={4} display="flex">
              <Paper
                elevation={0}
                sx={{ padding: 3, textAlign: "center", flex: 1 }}
              >
                <GroupsIcon sx={{ fontSize: 40, color: "primary.main" }} />
                <Typography
                  variant="h2"
                  sx={{ fontWeight: 600, color: "secondary.main" }}
                >
                  {data.productPublicStats.totalProductUserAssociationCount}
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {t("ProductPublicDetailsPage.TotalUserAssociations")}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {t(
                    "ProductPublicDetailsPage.TotalUserAssociationsDescription",
                    {
                      count:
                        data.productPublicStats
                          .totalProductUserAssociationCount,
                    }
                  )}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={6} xl={4} display="flex">
              <Paper
                elevation={0}
                sx={{ padding: 3, textAlign: "center", flex: 1 }}
              >
                <CorporateFareIcon
                  sx={{ fontSize: 40, color: "primary.main" }}
                />
                <Typography
                  variant="h2"
                  sx={{ fontWeight: 600, color: "secondary.main" }}
                >
                  {
                    data.productPublicStats
                      .totalProductOrganizationAssociationCount
                  }
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {t("ProductPublicDetailsPage.TotalOrganizationAssociations")}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {t(
                    "ProductPublicDetailsPage.TotalOrganizationAssociationsDescription",
                    {
                      count:
                        data.productPublicStats
                          .totalProductOrganizationAssociationCount,
                    }
                  )}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Paper elevation={0} sx={{ p: 3 }}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <LabelIcon color="primary" />
                  {t("ProductPublicDetailsPage.Identifiers")}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "text.secondary", mt: 1 }}
                >
                  {t("ProductPublicDetailsPage.IdentifiersDescription")}
                </Typography>
                <Divider sx={{ mt: 2 }} />
              </Grid>
            </Grid>

            {!data?.productIdentifiers?.length ? (
              <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
                {t("ProductPublicDetailsPage.NoIdentifiersFound")}
              </Typography>
            ) : (
              <List sx={{ mt: 1 }}>
                {data.productIdentifiers
                  .sort((a, b) => a.identifier.localeCompare(b.identifier))
                  .map(
                    (
                      {
                        productIdentifierGuid,
                        identifier,
                        productIdentifierType,
                      },
                      index
                    ) => (
                      <ListItem
                        key={productIdentifierGuid}
                        sx={{ "&:hover": { backgroundColor: "action.hover" } }}
                      >
                        <ListItemIcon>
                          <LabelIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={identifier}
                          secondary={productIdentifierType.name}
                        />
                      </ListItem>
                    )
                  )}
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleModalClose}
        title={t("ProductPublicDetailsPage.ClaimProductOwnershipTitle")}
        description={t(
          "ProductPublicDetailsPage.ClaimProductOwnershipDescription"
        )}
      />
    </>
  );
};

export default ProductPublicDetailsPage;
