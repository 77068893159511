import axiosInstance from "../AxiosConfig";
import {
  CreateProductModelRequest,
  ProductModelResponse,
  UpdateProductModelRequest,
} from "../../types/Product/ProductModel";

const ProductModelService = {
  async create(
    productModelToCreate: CreateProductModelRequest
  ): Promise<ProductModelResponse> {
    const response = await axiosInstance.post<ProductModelResponse>(
      "api/productmodels",
      productModelToCreate
    );
    return response.data;
  },

  async getAllByProductBrandId(
    productBrandId: number
  ): Promise<ProductModelResponse[]> {
    const response = await axiosInstance.get<ProductModelResponse[]>(
      `api/productbrands/${productBrandId}/productmodels`
    );
    return response.data;
  },

  async getById(id: number): Promise<ProductModelResponse> {
    const response = await axiosInstance.get<ProductModelResponse>(
      `api/productmodels/${id}`
    );
    return response.data;
  },

  async update(
    id: number,
    request: UpdateProductModelRequest
  ): Promise<ProductModelResponse> {
    const response = await axiosInstance.patch<ProductModelResponse>(
      `api/productmodels/${id}`,
      request
    );
    return response.data;
  },
};

export default ProductModelService;
