import axiosInstance from "../../../services/AxiosConfig";
import {
  CreateProductReferenceTagRequest,
  ProductReferenceTagResponse,
} from "../types/ProductReferenceTag";

const ProductReferenceTagService = {
  async create(
    request: CreateProductReferenceTagRequest
  ): Promise<ProductReferenceTagResponse> {
    const response = await axiosInstance.post<ProductReferenceTagResponse>(
      "api/productreferencetags",
      request
    );
    return response.data;
  },

  async getByGuid(
    productReferenceTagGuid: string
  ): Promise<ProductReferenceTagResponse> {
    const response = await axiosInstance.get<ProductReferenceTagResponse>(
      `api/ProductReferenceTags/${productReferenceTagGuid}`
    );
    return response.data;
  },
};

export default ProductReferenceTagService;
