import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Paper, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ArrowBack } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import OrganizationProductList from "../components/OrganizationProductList";

const OrganizationProductListPage: React.FC = () => {
  const { organizationGuid } = useParams<{ organizationGuid: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => navigate(`/Organizations/${organizationGuid}`)}
            startIcon={<ArrowBack />}
          >
            {t("SharedTranslations.Back")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {t("OrganizationProductListPage.Title")}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{ mt: 1, color: "text.secondary" }}
                >
                  {t("OrganizationProductListPage.Description")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(
                      `/Organizations/${organizationGuid}/Products/create`,
                      { state: { OrganizationGuid: organizationGuid } }
                    )
                  }
                  startIcon={<AddIcon />}
                  fullWidth
                >
                  {t("OrganizationProductListPage.ButtonAddProductText")}
                </Button>
              </Grid>
              {organizationGuid && (
                <Grid item xs={12}>
                  <OrganizationProductList
                    organizationGuid={organizationGuid}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default OrganizationProductListPage;
