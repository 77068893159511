// React Router hooks
import { useNavigate, useParams } from "react-router-dom";

// React Query hooks
import { useMutation, useQuery, useQueryClient } from "react-query";

// i18n translation hook
import { useTranslation } from "react-i18next";

// Material-UI components
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Business as BusinessIcon,
  Description as DescriptionIcon,
  Edit as EditIcon,
  Label as LabelIcon,
  LinkOff as LinkOffIcon,
  People as PeopleIcon,
  Person as PersonIcon,
  Public as PublicIcon,
  SwapHoriz as SwapHorizIcon,
} from "@mui/icons-material";

// Custom services
import ProductService from "../../services/Product/ProductService";
import ProductUserAssociationService from "../../services/Product/ProductUserAssociationService";

// Custom components
import PendingProductUserAssociationInviteListByProduct from "../../components/Product/ProductUserAssociationInvite/PendingProductUserAssociationInviteListByProduct";

// Custom types
import { AxiosErrorResponse } from "../../types/AxiosTypes";
import { ProductResponse } from "../../types/Product/Product";

// Third-party utilities
import { format } from "date-fns";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

const ProductDetailsPage = () => {
  const { productGuid } = useParams<{ productGuid: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const { data, isLoading, isError, error } = useQuery<
    ProductResponse,
    AxiosError<AxiosErrorResponse>
  >(
    ["product", productGuid],
    () => ProductService.getByGuid(productGuid as string),
    {
      enabled: !!productGuid,
      retry: false,
      onError: (error) => {
        if (error.response?.status === 403) {
          toast.error(t("ProductDetailsPage.NotAuthorized"), {
            position: "bottom-right",
          });
          navigate(`/products/${productGuid}/public`);
        } else {
          toast.error(
            error.message || t("ProductDetailsPage.FetchingProductData"),
            { position: "bottom-right" }
          );
        }
      },
    }
  );

  const terminateProductUserAssociation = useMutation(
    (productUserAssociationGuid: string) =>
      ProductUserAssociationService.terminateByGuid(productUserAssociationGuid),
    {
      onSuccess: () => {
        toast.info(t("ProductDetailsPage.TerminateOwnershipSuccess"), {
          position: "bottom-right",
        });
        queryClient.invalidateQueries(["product", productGuid]);
        queryClient.invalidateQueries("products");
        navigate("/Products");
      },
      onError: (error: AxiosErrorResponse) => {
        const errorMessage =
          error.message || t("ProductDetailsPage.TerminateOwnershipError");
        toast.error(errorMessage, { position: "bottom-right" });
      },
    }
  );

  if (!productGuid) {
    return null;
  }

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Typography variant="h6" color="error">
          {t("ProductDetailsPage.ErrorOccurred", { message: error.message })}
        </Typography>
      </Box>
    );
  }

  if (!data) {
    return (
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Typography variant="h6">
          {t("ProductDetailsPage.NoDataFound")}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      {data.productStats.totalPendingProductUserAssociationInviteCount > 0 && (
        <Grid item xs={12}>
          <PendingProductUserAssociationInviteListByProduct
            productGuid={data.productGuid}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "space-between" },
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                  mb: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <DescriptionIcon color="primary" />
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    {t("ProductDetailsPage.Title")}
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => navigate(`/Products/${productGuid}/Edit`)}
                  sx={{ mt: { xs: 1, sm: 0 } }}
                >
                  {t("ProductDetailsPage.EditProduct")}
                </Button>
              </Box>
              <Typography
                variant="body1"
                sx={{ color: "text.secondary", mt: 1 }}
              >
                {t("ProductDetailsPage.Description")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {t("ProductDetailsPage.PersonalName")}
              </Typography>
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                {data.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {t("ProductDetailsPage.ProductModel")}
              </Typography>
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                {`${data.productModel.productBrand.name} ${data.productModel.name}`}
              </Typography>
            </Grid>
            {data.productModel.productCategory && (
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {t("ProductDetailsPage.ProductCategory")}
                </Typography>
                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  {data.productModel.productCategory.name}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {t("ProductDetailsPage.RegisteredOn")}
              </Typography>
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                {`${format(new Date(data.createdAt), "dd/MM/yyyy")} (${
                  data.productStats.daysRegisteredOnPlatform
                } ${t("ProductDetailsPage.Days")})`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PublicIcon />}
                fullWidth
                onClick={() => navigate(`/Products/${productGuid}/Public`)}
              >
                {t("ProductDetailsPage.PublicProductPage")}
              </Button>
            </Grid>

            {data.productUserAssociations
              .filter((association) => association.isValid)
              .map((association) => (
                <Grid item xs={12} key={association.productUserAssociationGuid}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SwapHorizIcon />}
                    fullWidth
                    onClick={() =>
                      navigate(
                        `/products/${productGuid}/productuserassociations/${association.productUserAssociationGuid}/productuserassociationinvites/create`
                      )
                    }
                  >
                    {t("ProductDetailsPage.TransferOwnership")}
                  </Button>
                </Grid>
              ))}
            {data.productUserAssociations
              .filter((association) => association.isValid)
              .map((association) => (
                <Grid item xs={12} key={association.productUserAssociationGuid}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<LinkOffIcon />}
                    onClick={() => {
                      const confirmTermination = window.confirm(
                        t("ProductDetailsPage.ConfirmTermination")
                      );
                      if (confirmTermination) {
                        terminateProductUserAssociation.mutate(
                          association.productUserAssociationGuid
                        );
                      }
                    }}
                  >
                    {t("ProductDetailsPage.RemoveOwnership", {
                      name:
                        association.user.firstName || association.user.username,
                    })}
                  </Button>
                </Grid>
              ))}

            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/products")}
              >
                {t("ProductDetailsPage.BackToProducts")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} lg={6} xl={4}>
        <Paper elevation={0} sx={{ p: 3 }}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <LabelIcon color="primary" />
                {t("ProductDetailsPage.AssociatedIdentifiers")}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "text.secondary", mt: 1 }}
              >
                {t("ProductDetailsPage.IdentifiersList")}
              </Typography>
              <Divider sx={{ mt: 2 }} />
            </Grid>
          </Grid>

          {!data.productIdentifiers.length ? (
            <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
              {t("ProductDetailsPage.NoIdentifiersFound")}
            </Typography>
          ) : (
            <List sx={{ mt: 1 }}>
              {data.productIdentifiers
                .sort((a, b) => a.identifier.localeCompare(b.identifier))
                .map(
                  ({
                    productIdentifierGuid,
                    identifier,
                    productIdentifierType,
                  }) => (
                    <ListItem
                      key={productIdentifierGuid}
                      sx={{ "&:hover": { backgroundColor: "action.hover" } }}
                    >
                      <ListItemIcon>
                        <LabelIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={identifier}
                        secondary={productIdentifierType.name}
                      />
                    </ListItem>
                  )
                )}
            </List>
          )}
          <Grid item xs={12}>
            <Divider sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              fullWidth
              sx={{ mt: 2 }}
              onClick={() =>
                navigate(`/Products/${productGuid}/ProductIdentifiers/Create`)
              }
            >
              {t("ProductDetailsPage.AddIdentifier")}
            </Button>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} lg={6} xl={4}>
        <Paper elevation={0} sx={{ p: 3 }}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <PeopleIcon color="primary" />
                {t("ProductDetailsPage.OwnershipDetails")}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "text.secondary", mt: 1 }}
              >
                {t("ProductDetailsPage.OwnershipList")}
              </Typography>
              <Divider sx={{ mt: 2 }} />
            </Grid>
          </Grid>

          {!data?.productUserAssociations?.length ? (
            <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
              {t("ProductDetailsPage.NoOwnershipHistoryFound")}
            </Typography>
          ) : (
            <List sx={{ mt: 2 }}>
              {data.productUserAssociations
                .sort(
                  (a, b) =>
                    new Date(b.validFrom).getTime() -
                    new Date(a.validFrom).getTime()
                )
                .map(
                  ({
                    productUserAssociationGuid,
                    user,
                    validFrom,
                    validTo,
                    isValid,
                    daysValid,
                  }) => (
                    <ListItem
                      key={productUserAssociationGuid}
                      sx={{
                        "&:hover": { backgroundColor: "action.hover" },
                        backgroundColor: isValid
                          ? "success.light"
                          : "background.paper",
                        borderRadius: 1,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate(`/Users/${user.userGuid}/Profile`)
                      }
                    >
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={user.name || user.username}
                        secondary={
                          <>
                            {t("ProductDetailsPage.AssociatedFrom", {
                              fromDate: new Date(
                                validFrom
                              ).toLocaleDateString(),
                              toDate: validTo
                                ? new Date(validTo).toLocaleDateString()
                                : t("ProductDetailsPage.Current"),
                            })}
                            <br />
                            {t("ProductDetailsPage.Duration", { daysValid })}
                          </>
                        }
                      />
                    </ListItem>
                  )
                )}
            </List>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12} lg={6} xl={4}>
        <Paper elevation={0} sx={{ p: 3 }}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <BusinessIcon color="primary" />
                {t("ProductDetailsPage.OrganizationalHistory")}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "text.secondary", mt: 1 }}
              >
                {t("ProductDetailsPage.OrganizationList")}
              </Typography>
              <Divider sx={{ mt: 2 }} />
            </Grid>
          </Grid>

          {!data?.productOrganizationAssociations?.length ? (
            <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
              {t("ProductDetailsPage.NoOrganizationalHistoryFound")}
            </Typography>
          ) : (
            <List sx={{ mt: 2 }}>
              {data.productOrganizationAssociations
                .sort(
                  (a, b) =>
                    new Date(b.validFrom).getTime() -
                    new Date(a.validFrom).getTime()
                )
                .map(
                  ({
                    productOrganizationAssociationGuid,
                    organization,
                    validFrom,
                    validTo,
                    daysValid,
                  }) => (
                    <ListItem
                      key={productOrganizationAssociationGuid}
                      sx={{
                        "&:hover": { backgroundColor: "action.hover" },
                        backgroundColor: !validTo
                          ? "success.light"
                          : "background.paper",
                        borderRadius: 1,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate(
                          `/Organizations/${organization.organizationGuid}/Public`
                        )
                      }
                    >
                      <ListItemIcon>
                        <BusinessIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={organization.name}
                        secondary={
                          <>
                            {t("ProductDetailsPage.AssociatedFrom", {
                              fromDate: new Date(
                                validFrom
                              ).toLocaleDateString(),
                              toDate: validTo
                                ? new Date(validTo).toLocaleDateString()
                                : t("ProductDetailsPage.Current"),
                            })}
                            <br />
                            {t("ProductDetailsPage.Duration", { daysValid })}
                          </>
                        }
                      />
                    </ListItem>
                  )
                )}
            </List>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProductDetailsPage;
