import axiosInstance from "../AxiosConfig";
import {
  CreateProductBrandRequest,
  ProductBrandResponse,
  UpdateProductBrandRequest,
} from "../../types/Product/ProductBrand";

const ProductBrandService = {
  async create(
    request: CreateProductBrandRequest
  ): Promise<ProductBrandResponse> {
    const response = await axiosInstance.post<ProductBrandResponse>(
      "api/productbrands/",
      request
    );
    return response.data;
  },

  async getAll(): Promise<ProductBrandResponse[]> {
    const response = await axiosInstance.get<ProductBrandResponse[]>(
      "api/productbrands/"
    );
    return response.data;
  },

  async getById(id: number): Promise<ProductBrandResponse> {
    const response = await axiosInstance.get<ProductBrandResponse>(
      `api/productbrands/${id}`
    );
    return response.data;
  },

  async update(
    id: number,
    request: UpdateProductBrandRequest
  ): Promise<ProductBrandResponse> {
    const response = await axiosInstance.patch<ProductBrandResponse>(
      `api/productbrands/${id}`,
      request
    );
    return response.data;
  },
};

export default ProductBrandService;
