// React and hooks
import { useState } from "react";

// External libraries
import * as yup from "yup";
import { AxiosError } from "axios";

// React UI libraries
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// React hooks and utilities from external libraries
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Contexts and services
import { useAuth } from "../../contexts/AuthContext";

// Types and interfaces
import { LoginRequest } from "../../types/AuthTypes";
import { toast } from "react-toastify";

// Components
import LanguageSelector from "../Shared/LanguageSelector";

// Validation schema
const schema = yup.object({
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { login } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const { mutate } = useMutation<void, AxiosError, LoginRequest>(
    (loginData) => login(loginData),
    {
      onSuccess: (data) => {
        const returnUrl = location.state?.returnUrl?.pathname || "/";
        navigate(returnUrl, { replace: true });
      },
      onError: (error) => {
        toast.error(error.message, {
          position: "bottom-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
    }
  );

  const onSubmit: SubmitHandler<LoginRequest> = (data) => {
    setIsLoading(true);
    mutate(data);
  };

  return (
    <>
      <Typography variant="h5" align="center" sx={{ mb: 2, fontWeight: 600 }}>
        {t("LoginForm.Title")}
      </Typography>
      <Typography variant="body2" align="center" sx={{ mb: 2 }}>
        {t("LoginForm.Subtitle")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("LoginForm.TextFieldUsernameLabelText")}
                  placeholder={t("LoginForm.TextFieldUsernamePlaceholderText")}
                  error={!!errors.username}
                  helperText={errors.username?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type={showPassword ? "text" : "password"}
                  label={t("LoginForm.TextFieldPasswordLabelText")}
                  placeholder={t("LoginForm.TextFieldPasswordPlaceholderText")}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
            >
              {isLoading ? t("LoginForm.LoggingIn") : t("LoginForm.Login")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disabled={isLoading}
              onClick={() => navigate("/forgot-password")}
            >
              {t("LoginForm.ForgotPassword")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align="center">
              {t("LoginForm.NoAccount")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              disabled={isLoading}
              onClick={() => navigate("/register")}
            >
              {t("LoginForm.CreateAccount")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item xs={12}>
            <LanguageSelector />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default LoginForm;
