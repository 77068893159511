// React-related imports
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Third-party library imports
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import { Button, Grid, TextField, Typography, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

// Type imports
import {
  CreateProductBrandRequest,
  ProductBrandResponse,
} from "../../../types/Product/ProductBrand";

// Utility, services, and other internal imports
import ProductBrandService from "../../../services/Product/ProductBrandService";
import { toast } from "react-toastify";

// Constants
const schema = yup.object({
  name: yup
    .string()
    .required("Name is required.")
    .min(2, "Name must be at least 2 characters long.")
    .max(255, "Name must not exceed 255 characters."),
  description: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .max(2000, "Description must not exceed 2000 characters.")
    .notRequired(),
});

const CreateProductBrandForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateProductBrandRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
  });

  const mutation = useMutation<
    ProductBrandResponse,
    AxiosError,
    CreateProductBrandRequest
  >(ProductBrandService.create, {
    onSuccess: (response) => {
      setIsLoading(false);

      toast.success(t("CreateProductBrandForm.SuccessMessage"), {
        position: "bottom-right",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });

      queryClient.invalidateQueries("ProductBrands");
      const returnUrl = location.state?.returnUrl;
      if (returnUrl) {
        navigate(returnUrl, {
          state: { productBrandId: response.productBrandId },
        });
      } else {
        navigate(`/productbrands/${response.productBrandId}`);
      }
    },
    onError: (error: AxiosError) => {
      setIsLoading(false);
      const errorMessage =
        error.message || "An error occurred while creating the product brand";

      toast.error(errorMessage, {
        position: "bottom-right",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },
  });

  const onSubmit = (data: CreateProductBrandRequest) => {
    setIsLoading(true);
    mutation.mutate(data);
  };

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("CreateProductBrandForm.Title")}
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {t("CreateProductBrandForm.Description")}
          </Typography>
        </Grid>

        {/* Form Fields */}
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {/* Name Field */}
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("CreateProductBrandForm.TextFieldNameLabel")}
                      placeholder={t(
                        "CreateProductBrandForm.TextFieldNamePlaceholder"
                      )}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* Description Field */}
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "CreateProductBrandForm.TextFieldDescriptionLabel"
                      )}
                      placeholder={t(
                        "CreateProductBrandForm.TextFieldDescriptionPlaceholder"
                      )}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                      fullWidth
                      multiline
                      rows={4}
                    />
                  )}
                />
              </Grid>
              {/* Action Buttons */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<AddIcon />}
                  disabled={isLoading}
                >
                  {t("CreateProductBrandForm.ButtonCreateText")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  disabled={isLoading}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    const returnUrl = location.state?.returnUrl;
                    if (returnUrl) {
                      navigate(returnUrl);
                    } else {
                      navigate("/productbrands");
                    }
                  }}
                >
                  {t("CreateProductBrandForm.ButtonCancelText")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CreateProductBrandForm;
