import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import AccountService from "../../services/AccountService";
import { UpdateUsernameRequest } from "../../types/Account";

interface Props {
  username?: string;
}

const schema = yup.object({
  username: yup
    .string()
    .required("Username is required")
    .min(3, "Username must be at least 3 characters")
    .max(255, "Username must be at most 255 characters"),
});

const UpdateUsernameForm: React.FC<Props> = ({ username }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  // Initialize form with validation and default values
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
  } = useForm<UpdateUsernameRequest>({
    resolver: yupResolver(schema),
    defaultValues: { username },
  });

  // Effect to reset form on username prop
  useEffect(() => {
    reset({ username });
  }, [username, reset]);

  // Mutation setup for changing username with success and error handling
  const mutation = useMutation<void, Error, UpdateUsernameRequest>(
    AccountService.updateUsername,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("AccountDetails");
        toast.success(t("UpdateUsernameForm.SuccessMessage"), {
          position: "bottom-right",
        });
      },
      onError: (error) => {
        toast.error(error.message || t("UpdateUsernameForm.ErrorMessage"), {
          position: "bottom-right",
        });
      },
      onSettled: () => setOpen(false),
    }
  );

  // Submit handler that opens the confirmation dialog
  const onSubmit = () => setOpen(true);

  // Confirm action handler
  const handleConfirm = () => {
    const data = watch();
    mutation.mutate(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={t("SharedTranslations.Username")}
              placeholder={t(
                "UpdateUsernameForm.TextFieldUsernamePlaceholderText"
              )}
              fullWidth
              {...register("username")}
              error={!!errors.username}
              helperText={errors.username?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="outlined"
              fullWidth
              disabled={!isDirty || mutation.isLoading}
            >
              {t("SharedTranslations.SaveChanges")}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">
          {t("UpdateUsernameForm.ConfirmDialogTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("UpdateUsernameForm.ConfirmDialogText")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {t("SharedTranslations.Cancel")}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {t("SharedTranslations.Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateUsernameForm;
