// React and Hooks imports
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

// Material UI imports for UI elements
import {
  Grid,
  Typography,
  TextField,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Paper,
} from "@mui/material";
import { FaBox } from "react-icons/fa6";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Validation and utilities
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Contexts, services, and other components
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ProductService from "../../services/Product/ProductService";
import ProductBrandAutocomplete from "../../components/Product/ProductBrand/ProductBrandAutocomplete";

// Types for form inputs
type SearchFormInput = {
  productBrandId: number;
  identifier: string;
};

const ProductFinder: React.FC = () => {
  // State hooks for managing search terms
  const [searchTerm, setSearchTerm] = useState<SearchFormInput | null>(null);

  const navigate = useNavigate();
  const { t } = useTranslation();

  // Form validation schema
  const schema = yup.object().shape({
    productBrandId: yup
      .number()
      .required(t("Errors.FieldProductBrandRequired")),
    identifier: yup
      .string()
      .required(t("Errors.FieldProductIdentifierRequired"))
      .min(3, t("Errors.FieldProductIdentifierMinimumLength")),
  });

  // React Hook Form setup
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<SearchFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      productBrandId: undefined,
      identifier: "",
    },
  });

  // React Query hook for fetching products based on search term
  const { data: products } = useQuery(
    ["searchProducts", searchTerm],
    () =>
      ProductService.getAllByProductBrandIdAndIdentifier(
        searchTerm!.productBrandId,
        searchTerm!.identifier
      ),
    {
      enabled: !!searchTerm,
      onSuccess: (fetchedProducts) => {
        if (fetchedProducts.length === 0) {
          toast.info(t("ProductFinder.NoProductsFound"), {
            position: "bottom-right",
          });
        }
      },
      onError: () => {
        toast.error(t("Errors.GenericError"), {
          position: "bottom-right",
        });
      },
    }
  );

  // Handles form submission, sets search term for query
  const onSubmit = async (data: SearchFormInput) => {
    setSearchTerm(data);
  };

  // Navigates to product detail page on item click
  const handleProductClick = (productGuid: string) => {
    navigate(`/Products/${productGuid}/Public`);
  };

  // Component render
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ padding: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                {/* Component Title */}
                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    {t("ProductFinder.Title")}
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    {t("ProductFinder.Description")}
                  </Typography>
                </Grid>

                {/* Product Brand Selection */}
                <Grid item xs={12}>
                  <Controller
                    name="productBrandId"
                    control={control}
                    render={({ field }) => (
                      <ProductBrandAutocomplete
                        selectedProductBrandId={field.value}
                        onProductBrandSelect={field.onChange}
                        error={Boolean(errors.productBrandId)}
                        helperText={errors.productBrandId?.message}
                      />
                    )}
                  />
                </Grid>

                {/* Product Identifier Input */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    label={t("ProductFinder.TextFieldIdentifierLabel")}
                    placeholder={t(
                      "ProductFinder.TextFieldIdentifierPlaceholder"
                    )}
                    {...register("identifier")}
                    error={Boolean(errors.identifier)}
                    helperText={errors.identifier?.message}
                    variant="outlined"
                  />
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {t("ProductFinder.ButtonFindText")}
                  </Button>
                </Grid>

                {/* Products List */}
                {products && products.length > 0 && (
                  <Grid item xs={12}>
                    <List>
                      {products.map((product, index) => (
                        <React.Fragment key={product.productId}>
                          <ListItemButton
                            onClick={() =>
                              handleProductClick(product.productGuid)
                            }
                            sx={{
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                              },
                              py: 2,
                            }}
                          >
                            <ListItemIcon>
                              <FaBox fontSize={28} color="black" />
                            </ListItemIcon>
                            <ListItemText
                              primary={product.name}
                              primaryTypographyProps={{ fontWeight: "bold" }}
                              secondary={`${product.productModel.productBrand.name} ${product.productModel.name}`}
                            />
                            <ListItemIcon
                              sx={{
                                justifyContent: "flex-end",
                                minWidth: "auto",
                              }}
                            >
                              <ArrowForwardIosIcon fontSize="small" />
                            </ListItemIcon>
                          </ListItemButton>
                          {index < products.length - 1 && <Divider />}
                        </React.Fragment>
                      ))}
                    </List>
                  </Grid>
                )}
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductFinder;
