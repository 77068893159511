// React and hooks
import { useState } from "react";

// External libraries
import * as yup from "yup";
import { AxiosError } from "axios";

// React UI libraries
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";

// React hooks and utilities from external libraries
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Contexts and services
import AuthService from "../../services/AuthService";

// Types and interfaces
import { ForgotPasswordRequest } from "../../types/AuthTypes";
import { toast } from "react-toastify";

// Validation schema
const schema = yup.object({
  email: yup
    .string()
    .email("Provide a valid email address")
    .required("Email is required"),
});

const ForgotPasswordForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const { mutate } = useMutation<void, AxiosError, ForgotPasswordRequest>(
    (loginData) => AuthService.forgotPassword(loginData),
    {
      onSuccess: (data) => {
        toast.success(t("ForgotPasswordForm.SuccessMessage"), {
          position: "bottom-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
        navigate("/");
      },
      onError: (error) => {
        toast.success(error.message, {
          position: "bottom-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
    }
  );

  const onSubmit: SubmitHandler<ForgotPasswordRequest> = (data) => {
    setIsLoading(true);
    mutate(data);
  };

  return (
    <>
      <Typography variant="h5" align="center" sx={{ mb: 2, fontWeight: 600 }}>
        {t("ForgotPasswordForm.Title")}
      </Typography>
      <Typography variant="body2" align="center" sx={{ mb: 2 }}>
        {t("ForgotPasswordForm.Description")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("ForgotPasswordForm.TextFieldEmailLabelText")}
                  placeholder={t(
                    "ForgotPasswordForm.TextFieldEmailPlaceholderText"
                  )}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
            >
              {isLoading
                ? t("ForgotPasswordForm.SendingResetLink")
                : t("ForgotPasswordForm.SendResetLink")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="info"
              fullWidth
              onClick={() => navigate("/login")}
            >
              {t("ForgotPasswordForm.BackToLogin")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
