// React-related imports
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Third-party library imports
import { useQuery } from "react-query";
import {
  CircularProgress,
  Typography,
  Paper,
  Grid,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

// Utility, services, and other internal imports
import ProductBrandService from "../../../services/Product/ProductBrandService";
import { toast } from "react-toastify";
import { useLoading } from "../../../contexts/LoadingContext";

// Custom components
import ProductModelsList from "../ProductModel/ProductModelsList";

const ProductBrandDetails: React.FC = () => {
  const { productBrandId } = useParams<{ productBrandId: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    if (!productBrandId) {
      toast.error("Product Brand ID is required.", {
        position: "bottom-right",
      });
      navigate("/productbrands");
    }
  }, [productBrandId, navigate]);

  const { isLoading, error, data } = useQuery(
    ["ProductBrand", productBrandId],
    async () => {
      showLoading();
      try {
        const data = await ProductBrandService.getById(Number(productBrandId));
        return data;
      } catch (error) {
        throw error;
      } finally {
        hideLoading();
      }
    },
    {
      onError: (error: any) => {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An unexpected error occurred";
        toast.error(errorMessage, { position: "bottom-right" });
        navigate("/productbrands");
      },
      enabled: !!productBrandId,
    }
  );

  if (isLoading) return <CircularProgress />;

  if (error) return <Typography color="error">"An error occurred."</Typography>;

  if (!data) return <Typography>"Product Brand not found."</Typography>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                {data.name}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/productbrands")}
              >
                {t("ProductBrandDetails.ButtonBackText")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                sx={{ marginLeft: 1 }}
                onClick={() =>
                  navigate(`/productbrands/${productBrandId}/edit`)
                }
              >
                {t("ProductBrandDetails.ButtonEditText")}
              </Button>
            </Grid>
            {data.description && (
              <Grid item xs={12}>
                <Typography variant="body1">{data.description}</Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <ProductModelsList productBrandId={Number(productBrandId)} />
      </Grid>
    </Grid>
  );
};

export default ProductBrandDetails;
