import React from "react";
import { Grid, Paper, Box } from "@mui/material";
import LoadingComponent from "../components/Shared/LoadingComponent";

// Assets
import Logo from "../assets/images/thefty-logo.png";
import backgroundImage from "../assets/images/login-background.jpg";

interface AuthenticationLayoutProps {
  children: React.ReactNode;
}

const AuthenticationLayout: React.FC<AuthenticationLayoutProps> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "100vh" }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 5,
            maxWidth: 400,
            width: "100%",
            margin: "auto",
            backdropFilter: "blur(4px)",
            bgcolor: "rgba(255, 255, 255, 0.7)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <img
              src={Logo}
              alt="logo"
              style={{ maxWidth: 100, maxHeight: 100 }}
            />
          </Box>

          {children}
        </Paper>
      </Grid>
      <LoadingComponent />
    </Box>
  );
};

export default AuthenticationLayout;
