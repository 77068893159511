import axiosInstance from "./AxiosConfig";
import {
  ConfirmEmailChangeRequest,
  RequestEmailChangeRequest,
  UpdateUsernameRequest,
} from "../types/Account";
import { User } from "../types/AuthTypes";

const AccountService = {
  async getAccountDetails(): Promise<User> {
    const response = await axiosInstance.get<User>("/api/Account");
    return response.data;
  },

  async confirmEmailChange(request: ConfirmEmailChangeRequest): Promise<void> {
    await axiosInstance.post("/api/account/confirm-email-change", request);
  },

  async requestEmailChange(request: RequestEmailChangeRequest): Promise<void> {
    await axiosInstance.post("/api/account/request-email-change", request);
  },

  async updateUsername(request: UpdateUsernameRequest): Promise<void> {
    await axiosInstance.patch("/api/account/update-username", request);
  },
};

export default AccountService;
