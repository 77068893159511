import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";

import AccountService from "../../services/AccountService";
import { ConfirmEmailChangeRequest } from "../../types/Account";

const ConfirmEmailChangeComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Memoize the parameters to stabilize them and ensure they are non-null
  const params = useMemo(() => {
    const userId = searchParams.get("userId");
    const newEmail = searchParams.get("newEmail");
    const token = searchParams.get("token");
    // Return null if any parameter is missing to avoid partial data mutation
    if (!userId || !newEmail || !token) {
      return null;
    }
    return { userId, newEmail, token };
  }, [searchParams]);

  const confirmEmailMutation = useMutation<
    void,
    Error,
    ConfirmEmailChangeRequest
  >(
    (params: ConfirmEmailChangeRequest) =>
      AccountService.confirmEmailChange(params),
    {
      onSuccess: () => {
        toast.success("Email successfully confirmed!");
        navigate("/account");
      },
      onError: (error) => {
        toast.error(`Failed to confirm email: ${error.message}`);
        navigate("/account");
      },
    }
  );

  useEffect(() => {
    if (params && !confirmEmailMutation.isLoading) {
      confirmEmailMutation.mutate(params);
    }
  }, [params, confirmEmailMutation]);

  if (!params) {
    toast.error("Missing parameters. Please check the URL and try again.");
    navigate("/account");
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default ConfirmEmailChangeComponent;
