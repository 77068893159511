// React-related imports
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Third-party library imports
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import { Box, Button, Grid, TextField, Typography, Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

// Type imports
import {
  CreateProductUserAssociationInviteRequest,
  ProductUserAssociationInviteBaseResponse,
} from "../../../types/Product/ProductUserAssociationInvite";

// Utility, services, and other internal imports
import ProductUserAssociationInviteService from "../../../services/Product/ProductUserAssociationInviteService";
import { toast } from "react-toastify";
import UserSearch from "../../User/UserSearch";

// Validation schema
const schema = yup.object({
  productUserAssociationGuid: yup
    .string()
    .required("Association GUID is required."),
  recipientUserGuid: yup.string().required("Recipient is Required"),
  message: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .max(2000, "Message must not exceed 2000 characters.")
    .notRequired(),
});

const CreateProductUserAssociationInviteForm: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const { productGuid, productUserAssociationGuid } = useParams();

  useEffect(() => {
    if (!productGuid) {
      toast.error(
        "Product GUID is required to create a product user association invite.",
        {
          position: "bottom-right",
        }
      );
      navigate("/products");
    }
  }, [productGuid, navigate]);

  useEffect(() => {
    if (!productUserAssociationGuid) {
      toast.error(
        "Product User Association GUID is required to create a product user association invite.",
        {
          position: "bottom-right",
        }
      );
      navigate(`/products/${productGuid}`);
    }
  }, [productUserAssociationGuid, productGuid, navigate]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateProductUserAssociationInviteRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      productUserAssociationGuid: productUserAssociationGuid,
      recipientUserGuid: "",
      message: "",
    },
  });

  const mutation = useMutation<
    ProductUserAssociationInviteBaseResponse,
    AxiosError,
    CreateProductUserAssociationInviteRequest
  >(ProductUserAssociationInviteService.Create, {
    onSuccess: (response) => {
      setIsLoading(false);
      toast.success(
        t("CreateProductUserAssociationInviteForm.SuccessMessage"),
        {
          position: "bottom-right",
        }
      );
      queryClient.invalidateQueries([
        "productUserAssociationInvites",
        productGuid,
      ]);
      queryClient.invalidateQueries(["product", productGuid]);
      navigate(`/products/${productGuid}`);
    },
    onError: (error: AxiosError) => {
      setIsLoading(false);
      toast.error(
        error.message || "An error occurred while sending the invite",
        {
          position: "bottom-right",
        }
      );
    },
  });

  const onSubmit = (data: CreateProductUserAssociationInviteRequest) => {
    setIsLoading(true);
    mutation.mutate(data);
  };

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <SendIcon color="primary" />
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("CreateProductUserAssociationInviteForm.Title")}
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ color: "text.secondary", mt: 1 }}>
            {t("CreateProductUserAssociationInviteForm.Description")}
          </Typography>
        </Grid>

        {/* Form Fields */}
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {/* RecipientUserGuid Field */}

              <Grid item xs={12}>
                <Controller
                  name="recipientUserGuid"
                  control={control}
                  render={({ field }) => (
                    <UserSearch
                      selectedUserGuid={field.value}
                      onSelect={(value) => field.onChange(value)}
                      error={!!errors.recipientUserGuid}
                      helperText={errors.recipientUserGuid?.message}
                    />
                  )}
                />
              </Grid>

              {/* Message Field */}
              <Grid item xs={12}>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t(
                        "CreateProductUserAssociationInviteForm.TextFieldMessageLabel"
                      )}
                      placeholder={t(
                        "CreateProductUserAssociationInviteForm.TextFieldMessagePlaceholder"
                      )}
                      error={!!errors.message}
                      helperText={errors.message?.message}
                      fullWidth
                      multiline
                      rows={4}
                    />
                  )}
                />
              </Grid>

              {/* Action Buttons */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<SendIcon />}
                  disabled={isLoading}
                >
                  {t("CreateProductUserAssociationInviteForm.ButtonSendText")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled={isLoading}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate(-1)}
                >
                  {t("CreateProductUserAssociationInviteForm.ButtonCancelText")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CreateProductUserAssociationInviteForm;
