import { CountByDateModel } from "../types/AnalyticsModels/CountByDateModel";
import axiosInstance from "./AxiosConfig";

const AnalyticsService = {
  async GetDailyProductCreationCount(): Promise<CountByDateModel[]> {
    const response = await axiosInstance.get<CountByDateModel[]>(
      "api/analytics/GetDailyProductCreationCount"
    );
    return response.data;
  },

  async GetDailyProductUserAssociationCount(): Promise<CountByDateModel[]> {
    const response = await axiosInstance.get<CountByDateModel[]>(
      "api/analytics/GetDailyProductUserAssociationCount"
    );
    return response.data;
  },

  async GetDailyUserCreationCount(): Promise<CountByDateModel[]> {
    const response = await axiosInstance.get<CountByDateModel[]>(
      "api/analytics/GetDailyUserCreationCount"
    );
    return response.data;
  },
};

export default AnalyticsService;
