// External library imports
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Button, Grid, TextField, Typography, Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import { toast } from "react-toastify";

// Absolute imports from the project
import ProductCategoryService from "../../../services/Product/ProductCategoryService";
import {
  UpdateProductCategoryRequest,
  ProductCategoryResponse,
} from "../../../types/Product/ProductCategory";

const schema = yup.object({
  name: yup.string().required("Name is required"),
});

const ProductCategoryEditForm: React.FC = () => {
  const { productCategoryId } = useParams<{ productCategoryId: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data: category, isLoading } = useQuery(
    ["productCategory", productCategoryId],
    () => ProductCategoryService.getById(Number(productCategoryId)),
    {
      enabled: !!productCategoryId,
    }
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateProductCategoryRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
  });

  // Initialize form with fetched data
  React.useEffect(() => {
    if (category) {
      reset({ name: category.name });
    }
  }, [category, reset]);

  const updateMutation = useMutation<
    ProductCategoryResponse,
    Error,
    UpdateProductCategoryRequest
  >((data) => ProductCategoryService.update(Number(productCategoryId), data), {
    onSuccess: () => {
      toast.success(t("ProductCategoryEditForm.SuccessMessage"), {
        position: "bottom-right",
      });
      queryClient.invalidateQueries(["productCategory", productCategoryId]);
      queryClient.invalidateQueries("productCategories");
      navigate(`/productcategories`);
    },
    onError: (error) => {
      toast.error(t("ProductCategoryEditForm.ErrorMessage") + error.message, {
        position: "bottom-right",
      });
    },
  });

  const onSubmit = (data: UpdateProductCategoryRequest) => {
    updateMutation.mutate(data);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <Paper elevation={3} sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("ProductCategoryEditForm.Title")}
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {t("ProductCategoryEditForm.Description")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("ProductCategoryEditForm.TextFieldNameLabel")}
                      placeholder={t(
                        "ProductCategoryEditForm.TextFieldNamePlaceholder"
                      )}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  fullWidth
                >
                  {t("ProductCategoryEditForm.ButtonSaveText")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  color="secondary"
                  fullWidth
                  onClick={() => navigate(-1)}
                >
                  {t("ProductCategoryEditForm.ButtonCancelText")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductCategoryEditForm;
