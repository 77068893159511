import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import InventoryIcon from "@mui/icons-material/Inventory";
import UsersIcon from "@mui/icons-material/People";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import OrganizationService from "../services/OrganizationService";

const OrganizationDetailsPage: React.FC = () => {
  const { organizationGuid } = useParams<{ organizationGuid: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLoading, error, data } = useQuery(
    ["organization", organizationGuid],
    async () => {
      if (!organizationGuid) throw new Error("No organization GUID provided");
      return await OrganizationService.getByGuid(organizationGuid);
    },
    {
      onError: (error: any) => {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "An unexpected error occurred";
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
        });
        navigate("/");
      },
    }
  );

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 4 }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (error || !data) {
    return (
      <Typography color="error" sx={{ textAlign: "center", mt: 4 }}>
        {t("OrganizationDetailsPage.FailedToLoad")}
      </Typography>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  {data.name}
                </Typography>
              </Grid>
              {data.websiteUrl && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => window.open(data.websiteUrl ?? "", "_blank")}
                    fullWidth
                  >
                    {t("SharedTranslations.PublicWebsite")}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                {data.description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} lg={6}>
            <Paper elevation={0} sx={{ padding: 3, textAlign: "center" }}>
              <InventoryIcon
                sx={{ fontSize: 40, color: "primary.main", mb: 0 }}
              />
              <Typography
                variant="h2"
                sx={{ fontWeight: 600, color: "secondary.main" }}
              >
                {data.productCount}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                Associated Products
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate(`/Organizations/${organizationGuid}/Products`)
                }
                fullWidth
              >
                Manage Products
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper elevation={0} sx={{ padding: 3, textAlign: "center" }}>
              <UsersIcon sx={{ fontSize: 40, color: "primary.main", mb: 1 }} />
              <Typography
                variant="h2"
                sx={{ fontWeight: 600, color: "secondary.main" }}
              >
                {data.userCount}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                Associated Users
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  navigate(`/organizations/${organizationGuid}/users`)
                }
                fullWidth
              >
                Manage Users
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Name
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {data.name} ({data.organizationGuid})
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Registration Number
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {data.registrationNumber}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  VAT Number
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {data.valueAddedTaxNumber}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Adresse
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {data.address}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Postal Code
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {data.postalCode}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Country
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {data.countryName}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Contact Email
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {data.contactEmail}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  Contact Phone Number
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {data.contactPhoneNumber}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OrganizationDetailsPage;
