import axiosInstance from "../AxiosConfig";
import {
  ProductUserAssociationResponse,
  CreateProductUserAssociationRequest,
} from "../../types/Product/ProductUserAssociation";

const ProductUserAssociationService = {
  async create(
    request: CreateProductUserAssociationRequest
  ): Promise<ProductUserAssociationResponse> {
    const response = await axiosInstance.post<ProductUserAssociationResponse>(
      "api/ProductUserAssociations",
      request
    );
    return response.data;
  },

  async getByGuid(
    productUserAssociationGuid: string
  ): Promise<ProductUserAssociationResponse> {
    const response = await axiosInstance.get<ProductUserAssociationResponse>(
      `api/ProductUserAssociations/${productUserAssociationGuid}`
    );
    return response.data;
  },

  async getAllByProductGuid(
    productGuid: string
  ): Promise<ProductUserAssociationResponse[]> {
    const response = await axiosInstance.get<ProductUserAssociationResponse[]>(
      `api/Products/${productGuid}/ProductUserAssociations`
    );
    return response.data;
  },

  async terminateByGuid(productUserAssociationGuid: string): Promise<void> {
    await axiosInstance.delete<void>(
      `api/ProductUserAssociations/${productUserAssociationGuid}`
    );
  },
};

export default ProductUserAssociationService;
