import { useQuery } from "react-query";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import AnalyticsService from "../../services/AnalyticsService";
import { CountByDateModel } from "../../types/AnalyticsModels/CountByDateModel";
import { format } from "date-fns";
import "./ChartStyles.css";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "",
    },
  },
};

const DailyUserCreationCount: React.FC = () => {
  const { data, isLoading, error } = useQuery<CountByDateModel[], Error>(
    "GetDailyUserCreationCount",
    AnalyticsService.GetDailyUserCreationCount
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  const chartData = {
    labels: data?.map((d) => format(new Date(d.date), "yyyy-MM-dd")),
    datasets: [
      {
        label: "Daily User Creation Count",
        data: data?.map((d) => d.count),
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
    ],
  };

  return (
    <Line
      data={chartData}
      options={{
        ...chartOptions,
        plugins: {
          ...chartOptions.plugins,
          title: {
            ...chartOptions.plugins.title,
            text: "Daily User Creation Trends",
          },
        },
      }}
    />
  );
};

const DailyProductCreationCount: React.FC = () => {
  const { data, isLoading, error } = useQuery<CountByDateModel[], Error>(
    "GetDailyProductCreationCount",
    AnalyticsService.GetDailyProductCreationCount
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  const chartData = {
    labels: data?.map((d) => format(new Date(d.date), "yyyy-MM-dd")),
    datasets: [
      {
        label: "Daily Product Creation Count",
        data: data?.map((d) => d.count),
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
    ],
  };

  return (
    <Line
      data={chartData}
      options={{
        ...chartOptions,
        plugins: {
          ...chartOptions.plugins,
          title: {
            ...chartOptions.plugins.title,
            text: "Daily Product Creation Trends",
          },
        },
      }}
    />
  );
};

const AnalyticsDashboard: React.FC = () => {
  return (
    <div className="charts-container">
      <div className="chart-wrapper">
        <DailyUserCreationCount />
      </div>
      <div className="chart-wrapper">
        <DailyProductCreationCount />
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
