import {
  CreateProductCategoryRequest,
  ProductCategoryResponse,
  UpdateProductCategoryRequest,
} from "../../types/Product/ProductCategory";
import axiosInstance from "../AxiosConfig";

const ProductCategoryService = {
  async create(
    request: CreateProductCategoryRequest
  ): Promise<ProductCategoryResponse> {
    const response = await axiosInstance.post<ProductCategoryResponse>(
      "api/productcategories/",
      request
    );
    return response.data;
  },

  async getAll(): Promise<ProductCategoryResponse[]> {
    const response = await axiosInstance.get<ProductCategoryResponse[]>(
      "api/productcategories/"
    );
    return response.data;
  },

  async getById(id: number): Promise<ProductCategoryResponse> {
    const response = await axiosInstance.get<ProductCategoryResponse>(
      `api/productcategories/${id}`
    );
    return response.data;
  },

  async update(
    id: number,
    request: UpdateProductCategoryRequest
  ): Promise<ProductCategoryResponse> {
    const response = await axiosInstance.patch<ProductCategoryResponse>(
      `api/productcategories/${id}`,
      request
    );
    return response.data;
  },
};

export default ProductCategoryService;
