import {
  ProductIdentifierResponse,
  CreateProductIdentifierRequest,
} from "../../types/Product/ProductIdentifier";
import axiosInstance from "../AxiosConfig";

const ProductIdentifierService = {
  async getProductIdentifiersByProductGuid(
    productGuid: string
  ): Promise<ProductIdentifierResponse[]> {
    const response = await axiosInstance.get<ProductIdentifierResponse[]>(
      `api/products/${productGuid}/productidentifiers`
    );
    return response.data;
  },

  async createProductIdentifier(
    productGuid: string,
    productIdentifier: CreateProductIdentifierRequest
  ): Promise<ProductIdentifierResponse> {
    const response = await axiosInstance.post<ProductIdentifierResponse>(
      `api/products/${productGuid}/productidentifiers`,
      productIdentifier
    );
    return response.data;
  },

  async GetProductIdentifier(
    productIdentifierGuid: string
  ): Promise<ProductIdentifierResponse> {
    const response = await axiosInstance.get<ProductIdentifierResponse>(
      `api/productidentifiers/${productIdentifierGuid}`
    );
    return response.data;
  },
};

export default ProductIdentifierService;
