import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import OrganizationService from "../services/OrganizationService";
import { UserResponse } from "../../../types/User";
import { toast } from "react-toastify";

interface OrganizationUserAutocompleteProps {
  organizationGuid: string;
  selectedUserGuid: string | null;
  onUserSelect: (userGuid: string | null) => void;
  error?: boolean;
  helperText?: string;
}

// Custom hook to fetch users by organization GUID
const useFetchUsersByOrganization = (organizationGuid: string) => {
  return useQuery<UserResponse[], Error>(
    ["OrganizationUsers", organizationGuid],
    () => OrganizationService.getUsersByOrganizationGuid(organizationGuid),
    {
      enabled: !!organizationGuid, // Fetch only if organizationGuid is provided
    }
  );
};

const OrganizationUserAutocomplete: React.FC<
  OrganizationUserAutocompleteProps
> = ({
  organizationGuid,
  selectedUserGuid,
  onUserSelect,
  error = false,
  helperText = "",
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    isLoading,
    error: queryError,
    data,
  } = useFetchUsersByOrganization(organizationGuid);

  // Effect to handle errors
  useEffect(() => {
    if (queryError) {
      const errorMessage =
        queryError instanceof Error
          ? queryError.message
          : "An unexpected error occurred";

      toast.error(errorMessage, {
        position: "bottom-right",
      });
    }
  }, [queryError]);

  // Handle selection change
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: UserResponse | null
  ) => {
    onUserSelect(newValue?.userGuid || null);
  };

  // Ensure that the value is always defined
  const selectedUser =
    data?.find((user) => user.userGuid === selectedUserGuid) || null;

  return (
    <Autocomplete
      value={selectedUser}
      noOptionsText={t("OrganizationUserAutocomplete.NoOptionsText")}
      options={data || []}
      getOptionLabel={(option) => option.firstName + " " + option.lastName}
      isOptionEqualToValue={(option, value) =>
        option.userGuid === value.userGuid
      }
      onChange={handleChange}
      renderOption={(props, option) => (
        <li {...props} key={option.userGuid}>
          {option.firstName} {option.lastName}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t("OrganizationUserAutocomplete.TextFieldLabel")}
          placeholder={t("OrganizationUserAutocomplete.TextFieldPlaceholder")}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default OrganizationUserAutocomplete;
