import React from "react";
import OrganizationCreationForm from "../components/OrganizationCreationForm";
import { Button, Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const OrganizationCreationFormPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/organizations")}
        >
          {t("SharedTranslations.Back")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <OrganizationCreationForm />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OrganizationCreationFormPage;
