import axiosInstance from "../../../services/AxiosConfig";
import {
  CreateOrganizationUserAssociationRequest,
  OrganizationUserAssociationResponse,
  UpdateOrganizationUserAssociationRequest,
} from "../types/OrganizationUserAssociation";

const OrganizationUserAssociationService = {
  async create(
    organizationUserAssociation: CreateOrganizationUserAssociationRequest
  ): Promise<OrganizationUserAssociationResponse> {
    const response =
      await axiosInstance.post<OrganizationUserAssociationResponse>(
        "api/organizationuserassociations",
        organizationUserAssociation
      );
    return response.data;
  },

  async getByOrganizationGuidDetailed(
    organizationGuid: string
  ): Promise<OrganizationUserAssociationResponse[]> {
    const response = await axiosInstance.get<
      OrganizationUserAssociationResponse[]
    >(`api/organizations/${organizationGuid}/organizationuserassociations`);
    return response.data;
  },

  async getByGuid(
    organizationUserAssociationGuid: string
  ): Promise<OrganizationUserAssociationResponse> {
    const response =
      await axiosInstance.get<OrganizationUserAssociationResponse>(
        `api/organizationuserassociations/${organizationUserAssociationGuid}`
      );
    return response.data;
  },

  async revoke(
    organizationUserAssociationGuid: string
  ): Promise<OrganizationUserAssociationResponse> {
    const response =
      await axiosInstance.delete<OrganizationUserAssociationResponse>(
        `api/organizationuserassociations/${organizationUserAssociationGuid}`
      );
    return response.data;
  },

  async update(
    organizationUserAssociationGuid: string,
    request: UpdateOrganizationUserAssociationRequest
  ): Promise<OrganizationUserAssociationResponse> {
    const response =
      await axiosInstance.put<OrganizationUserAssociationResponse>(
        `api/organizationuserassociations/${organizationUserAssociationGuid}`,
        request
      );
    return response.data;
  },
};

export default OrganizationUserAssociationService;
