import {
  CreateProductIdentifierRequest,
  ProductIdentifierPublicResponse,
  ProductIdentifierResponse,
} from "./ProductIdentifier";
import { ProductModelResponse } from "./ProductModel";
import { ProductOrganizationAssociationPublicResponse } from "./ProductOrganizationAssociation";
import { ProductStatusDetailedResponse } from "./ProductStatus";
import {
  ProductUserAssociationResponse,
  ProductUserAssociationPublicResponse,
} from "./ProductUserAssociation";
import { ProductUserAssociationInviteBaseResponse } from "./ProductUserAssociationInvite";
import { ProductVariantResponse } from "./ProductVariant";

export type CreateProductRequest = {
  name: string;
  productModelId: number;
  productIdentifiers: CreateProductIdentifierRequest[];
  productAssociationOption?: CreateProductAssociationOptionsEnum;
  organisationGuid?: string | null;
  userGuid?: string | null;
};

export type ProductBaseResponse = {
  productModel: ProductModelResponse;
  productId: number;
  productGuid: string;
  name: string;
  productModelId: number;
  createdAt: Date;
};

export type ProductDetailedResponse = ProductBaseResponse & {
  productIdentifiers: ProductIdentifierResponse[];
  productUserAssociations: ProductUserAssociationResponse[];
  productVariant: ProductVariantResponse;
  productStatus: ProductStatusDetailedResponse[];
  productUserAssociationInvites: ProductUserAssociationInviteBaseResponse[];
  productExtendedDetails: ProductExtendedDetails;
};

export type ProductExtendedDetails = {
  relatedProductUserAssociationGuid: string;
  canUserAdministerProduct: boolean;
  isProductAssociatedWithRequestingUser: boolean;
  isProductAvailableForClaim: boolean;
  isProductClaimed: boolean;
  hasPendingProductUserAssociationInvite: boolean;
  currentAssociationValidSince: Date;
};

export type ProductPublicResponse = {
  productModelId: number;
  productGuid: string;
  createdAt: string;
  productModel: ProductModelResponse;
  productPublicStats: ProductPublicStatsResponse;
  productIdentifiers: ProductIdentifierPublicResponse[];
  productOrganizationAssociations: ProductOrganizationAssociationPublicResponse[];
  productUserAssociations: ProductUserAssociationPublicResponse[];
};

export type ProductStatsResponse = {
  daysRegisteredOnPlatform: number;
  totalPendingProductUserAssociationInviteCount: number;
  totalProductIdentifierCount: number;
  totalProductOrganizationAssociationCount: number;
  totalProductUserAssociationCount: number;
};

export type ProductPublicStatsResponse = {
  daysRegisteredOnPlatform: number;
  totalProductIdentifierCount: number;
  totalProductOrganizationAssociationCount: number;
  totalProductUserAssociationCount: number;
};

export type UpdateProductRequest = {
  name: string;
};

export enum CreateProductAssociationOptionsEnum {
  None = 0, // No ownership at all
  Personal = 1, // Ownership linked to the requester
  PersonalVerifiedByOrganization = 2, // Ownership linked to the UserGuid and verified by an OrganizationGuid
  Organisational = 3, // Ownership linked to an organisation
  OrganisationalLinkedToMember = 4, // Ownership linked to organization and an individual within the organization
}

export type ProductResponse = {
  productModelId: number;
  productGuid: string;
  name: string;
  createdAt: string;
  productModel: ProductModelResponse;
  productStats: ProductStatsResponse;
  productIdentifiers: ProductIdentifierPublicResponse[];
  productOrganizationAssociations: ProductOrganizationAssociationPublicResponse[];
  productUserAssociations: ProductUserAssociationPublicResponse[];
};
