import axiosInstance from "../../../services/AxiosConfig";
import {
  CreateProductOrganizationAssociationRequest,
  ProductOrganizationAssociationResponse,
} from "../../../types/Product/ProductOrganizationAssociation";

const ProductOrganizationAssociationService = {
  async create(
    request: CreateProductOrganizationAssociationRequest
  ): Promise<ProductOrganizationAssociationResponse> {
    const response =
      await axiosInstance.post<ProductOrganizationAssociationResponse>(
        "api/productorganizationassociations",
        request
      );
    return response.data;
  },

  async getAllByOrganizationGuid(
    organizationGuid: string
  ): Promise<ProductOrganizationAssociationResponse[]> {
    const response = await axiosInstance.get<
      ProductOrganizationAssociationResponse[]
    >(`api/organizations/${organizationGuid}/productorganizationassociations`);
    return response.data;
  },

  async getByGuid(
    productOrganizationAssociationGuid: string
  ): Promise<ProductOrganizationAssociationResponse> {
    const response =
      await axiosInstance.get<ProductOrganizationAssociationResponse>(
        `api/productorganizationassociations/${productOrganizationAssociationGuid}`
      );
    return response.data;
  },

  async terminate(
    productOrganizationAssociationGuid: string
  ): Promise<ProductOrganizationAssociationResponse> {
    const response =
      await axiosInstance.delete<ProductOrganizationAssociationResponse>(
        `api/productorganizationassociations/${productOrganizationAssociationGuid}`
      );
    return response.data;
  },
};

export default ProductOrganizationAssociationService;
