import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  TextField,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
} from "@mui/material";
import { UserResponse } from "../../types/User";
import UserService from "../../services/UserService";
import { useTranslation } from "react-i18next";

interface Props {
  selectedUserGuid: string | null;
  onSelect: (userGuid: string | null) => void;
  error?: boolean;
  helperText?: string;
}

export default function UserSearch({ onSelect, error, helperText }: Props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUserGuid, setSelectedUserGuid] = useState<string | null>(null);
  const [searchInitiated, setSearchInitiated] = useState(false); // New state to track if a search has been initiated

  const { t } = useTranslation();

  const { data, isLoading, refetch } = useQuery<UserResponse[]>(
    ["users", searchTerm],
    () => UserService.search(searchTerm.trim()),
    {
      enabled: false, // Disable automatic queries on render
    }
  );

  const handleSearch = () => {
    if (searchTerm.trim()) {
      refetch();
      setSearchInitiated(true); // Update state to indicate search has been initiated
    }
  };

  const handleSelectUser = (userGuid: string) => {
    setSelectedUserGuid(userGuid);
    onSelect(userGuid);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setSelectedUserGuid(null);
    onSelect(null);
    setSearchInitiated(false); // Reset search initiated state when user starts typing
  };

  const getListItemStyle = (userGuid: string) => ({
    cursor: "pointer",
    bgcolor: userGuid === selectedUserGuid ? "action.selected" : "inherit",
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label={t("UserSearch.TextFieldSearchLabel")}
          variant="outlined"
          fullWidth
          error={error}
          helperText={helperText}
          placeholder={t("UserSearch.TextFieldSearchPlaceholder")}
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevent form submission
              handleSearch();
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleSearch}
          disabled={!searchTerm.trim()}
        >
          {t("UserSearch.ButtonSearchText")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <CircularProgress />
        ) : searchInitiated && (!data || data.length === 0) ? ( // Only show "No Users Found" if search has been initiated
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            {t("UserSearch.NoUsersFound")}
          </Typography>
        ) : (
          <List sx={{ bgcolor: "background.paper" }}>
            {data?.map((user) => (
              <ListItem
                key={user.userId}
                onClick={() => handleSelectUser(user.userGuid)}
                sx={getListItemStyle(user.userGuid)}
              >
                <ListItemAvatar>
                  <Avatar>{user.firstName?.[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${user.username} (${user.firstName})`}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Grid>
    </Grid>
  );
}
