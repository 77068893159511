import axiosInstance from "../AxiosConfig";
import {
  CreateProductRequest,
  ProductBaseResponse,
  ProductDetailedResponse,
  ProductPublicResponse,
  ProductResponse,
  UpdateProductRequest,
} from "../../types/Product/Product";

const ProductService = {
  async create(
    productToCreate: CreateProductRequest
  ): Promise<ProductDetailedResponse> {
    const response = await axiosInstance.post<ProductDetailedResponse>(
      "api/products",
      productToCreate
    );
    return response.data;
  },

  async getAll(): Promise<ProductDetailedResponse[]> {
    const response = await axiosInstance.get<ProductDetailedResponse[]>(
      "api/products"
    );
    return response.data;
  },

  async getByGuid(guid: string): Promise<ProductResponse> {
    const response = await axiosInstance.get<ProductResponse>(
      `api/products/${guid}`
    );
    return response.data;
  },

  async getAllByProductBrandIdAndIdentifier(
    productBrandId: number,
    productIdentifier: string
  ): Promise<ProductDetailedResponse[]> {
    const response = await axiosInstance.get<ProductDetailedResponse[]>(
      `api/products/search?productBrandId=${productBrandId}&identifier=${productIdentifier}`
    );
    return response.data;
  },

  async getPublicByGuid(guid: string): Promise<ProductPublicResponse> {
    const response = await axiosInstance.get<ProductPublicResponse>(
      `api/products/${guid}/public`
    );
    return response.data;
  },

  async update(
    productGuid: string,
    updateProductRequest: UpdateProductRequest
  ): Promise<ProductBaseResponse> {
    try {
      const response = await axiosInstance.patch<ProductBaseResponse>(
        `api/products/${productGuid}`,
        updateProductRequest
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ProductService;
