// React-related imports
import { useNavigate } from "react-router-dom";

// Third-party library imports
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

// Type imports
import {
  CreateProductCategoryRequest,
  ProductCategoryResponse,
} from "../../../types/Product/ProductCategory";

// Utility, services, and other internal imports
import ProductCategoryService from "../../../services/Product/ProductCategoryService";
import { toast } from "react-toastify";

// Define the form validation schema
const schema = yup.object({
  name: yup
    .string()
    .required("Name is required.")
    .min(2, "Name must be at least 2 characters long.")
    .max(255, "Name must not exceed 255 characters."),
});

const CreateProductCategoryForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Setup form with validation
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateProductCategoryRequest>({
    resolver: yupResolver(schema),
    defaultValues: { name: "" },
  });

  // Mutation for creating a product category
  const mutation = useMutation<
    ProductCategoryResponse,
    AxiosError,
    CreateProductCategoryRequest
  >(ProductCategoryService.create, {
    onSuccess: (response) => {
      toast.success(t("CreateProductCategoryForm.SuccessMessage"), {
        position: "bottom-right",
      });
      queryClient.invalidateQueries("ProductCategories");
      navigate("/productcategories/");
    },
    onError: (error: AxiosError) => {
      const message =
        error.message || t("CreateProductCategoryForm.ErrorMessage");
      toast.error(message, { position: "bottom-right" });
    },
  });

  // Form submission handling
  const onSubmit = (data: CreateProductCategoryRequest) => {
    mutation.mutate(data);
  };

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {t("CreateProductCategoryForm.Title")}
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {t("CreateProductCategoryForm.Description")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {/* Name Field */}
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("CreateProductCategoryForm.TextFieldNameLabel")}
                      placeholder={t(
                        "CreateProductCategoryForm.TextFieldNamePlaceholder"
                      )}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* Action Buttons */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<AddIcon />}
                  disabled={mutation.isLoading}
                >
                  {t("CreateProductCategoryForm.ButtonCreateText")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate(-1)}
                  disabled={mutation.isLoading}
                >
                  {t("CreateProductCategoryForm.ButtonCancelText")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CreateProductCategoryForm;
