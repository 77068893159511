import React from "react";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import da from "../../assets/images/flags/da.png";
import de from "../../assets/images/flags/de.png";
import en from "../../assets/images/flags/en.png";
import es from "../../assets/images/flags/es.png";
import fr from "../../assets/images/flags/fr.png";
import it from "../../assets/images/flags/it.png";
import no from "../../assets/images/flags/no.png";
import pl from "../../assets/images/flags/pl.png";
import sv from "../../assets/images/flags/sv.png";

const languages = [
  { code: "da", name: "Dansk", flag: da },
  { code: "de", name: "Deutsch", flag: de },
  { code: "en", name: "English", flag: en },
  { code: "es", name: "Español", flag: es },
  { code: "fr", name: "Français", flag: fr },
  { code: "it", name: "Italiano", flag: it },
  { code: "no", name: "Norsk", flag: no },
  { code: "pl", name: "Polski", flag: pl },
  { code: "sv", name: "Svenska", flag: sv },
];

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value);
  };

  // Function to get a matching language code or a default value
  const getValidLanguageCode = (languageCode: string) => {
    // Check if the languageCode matches any of the available language codes
    const isLanguageAvailable = languages.some(
      (lang) => lang.code === languageCode
    );
    // If it matches, return the languageCode, otherwise return a default value ("en")
    return isLanguageAvailable ? languageCode : "en";
  };

  const renderValue = (value: any) => {
    const language = languages.find((lang) => lang.code === value);
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <img src={language?.flag} alt={language?.name} width="20" />
        {language?.name}
      </Box>
    );
  };

  return (
    <Select
      value={getValidLanguageCode(i18n.language)}
      onChange={handleChange}
      IconComponent={ArrowDropDownIcon}
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        height: "40px",
        ".MuiOutlinedInput-input": { padding: "10px" },
        gap: 1,
      }}
      renderValue={renderValue}
    >
      {languages.map((lang) => (
        <MenuItem key={lang.code} value={lang.code}>
          <img
            src={lang.flag}
            alt={lang.name}
            width="20"
            style={{ marginRight: 8 }}
          />
          {lang.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelector;
